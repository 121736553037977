@charset "UTF-8";
/* COMMON */
#gb-loader, .set-gb-loader {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: all 400ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

#gb-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  background: black;
  z-index: 99; }

.set-gb-loader {
  width: 315px;
  height: 55px;
  position: absolute; }

#gb-loader.start .set-gb-loader {
  opacity: 0;
  transform: scale(0.5);
  transition-delay: 4.5s; }

.title-gb-loader {
  width: 100%;
  text-align: center; }

.one-word-gb-l {
  font: 30px 'ACBoucle', sans-serif;
  color: white;
  letter-spacing: .05em; }

.one-word-gb-l1 {
  margin-right: 10px; }

.one-word-gb-l2 {
  margin-left: 10px; }

.process-loading-gb {
  width: calc(100% - 4px);
  height: 4px;
  position: relative;
  margin: 10px 0 0 1px;
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition-delay: 1.7s; }

.process-loading-gb:before {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  background: #FD1821; }

@keyframes processLoadingGb {
  15% {
    width: 15%; }
  30% {
    width: 30%; }
  45% {
    width: 35%; }
  60% {
    width: 60%; }
  80% {
    width: 70%; }
  100% {
    width: 100%; } }

.one-word-gb-l, .one-word-gb-l span {
  display: inline-block; }

.one-word-gb-l span {
  opacity: 0;
  transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.one-word-gb-l1 span:nth-child(even) {
  transform: translateY(40px) rotateX(45deg) scale(1.5); }

.one-word-gb-l1 span:nth-child(odd) {
  transform: translateY(-40px) rotateX(-45deg) scale(1.5); }

.one-word-gb-l2 span:nth-child(even) {
  transform: translateY(-40px) rotateX(-45deg) scale(1.5); }

.one-word-gb-l2 span:nth-child(odd) {
  transform: translateY(40px) rotateX(45deg) scale(1.5); }

.one-word-gb-l1 span.letter-gb-l2 {
  transition-delay: 100ms; }

.one-word-gb-l1 span.letter-gb-l3 {
  transition-delay: 200ms; }

.one-word-gb-l1 span.letter-gb-l4 {
  transition-delay: 300ms; }

.one-word-gb-l1 span.letter-gb-l5 {
  transition-delay: 400ms; }

.one-word-gb-l2 span.letter-gb-l6 {
  transition-delay: 500ms; }

.one-word-gb-l2 span.letter-gb-l7 {
  transition-delay: 600ms; }

.one-word-gb-l2 span.letter-gb-l8 {
  transition-delay: 700ms; }

.one-word-gb-l2 span.letter-gb-l9 {
  transition-delay: 800ms; }

.one-word-gb-l2 span.letter-gb-l10 {
  transition-delay: 900ms; }

.one-word-gb-l2 span.letter-gb-l11 {
  transition-delay: 1000ms; }

.one-word-gb-l2 span.letter-gb-l12 {
  transition-delay: 1100ms; }

.one-word-gb-l2 span.letter-gb-l13 {
  transition-delay: 1200ms; }

.one-word-gb-l2 span.letter-gb-l14 {
  transition-delay: 1300ms; }

#gb-loader.start .one-word-gb-l span {
  opacity: 1;
  transform: none; }

#gb-loader.start .process-loading-gb {
  opacity: 1; }

#gb-loader.start .process-loading-gb:before {
  animation: processLoadingGb 2.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) normal forwards;
  animation-delay: 1.6s; }

#dp-case,
#updatable-cnt {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1; }

#dp-case {
  opacity: 0; }

#updatable-cnt {
  min-height: 100vh;
  transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

#updatable-cnt.routing {
  opacity: 0; }

html:not(.mobile-platform) #updatable-cnt.routing {
  transform: scale(1.1); }

html.mobile-platform #updatable-cnt.routing {
  transform: scale(1.05); }

#header {
  width: 100%;
  height: 120px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  padding: 0 50px;
  z-index: 9; }

#header.routing {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
  text-size-adjust: none; }

.dp-head {
  width: 100%;
  height: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center; }

.mp-head {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  display: none;
  transition: all 200ms ease-out 200ms;
  background: linear-gradient(to bottom, black 0%, black 10%, transparent 100%); }

body[data-page=main] .mp-head {
  background: none; }

html.mobile-platform body[data-page=main] #header.opened .mp-head {
  background: black; }

.dp-logo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  font: 30px 'ACBoucle', sans-serif;
  line-height: 37px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.14em;
  text-decoration: none;
  transition: text-shadow 200ms ease-out; }

.dp-lg-sp {
  position: relative;
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: baseline;
  align-items: baseline;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.ic-lg-sp {
  display: block;
  width: 56px;
  height: 4px;
  margin-right: 4px;
  position: relative;
  overflow: hidden; }

.ic-lg-sp:before,
.ic-lg-sp:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background: #FD1821; }

.ic-lg-sp:after {
  width: 0;
  left: 0; }

.ic-lg-sp:before {
  width: 100%;
  right: 0; }

html:not(.mobile-platform) .dp-logo:hover .ic-lg-sp:before,
html:not(.mobile-platform) .dp-logo:hover .ic-lg-sp:after {
  transition: width 300ms cubic-bezier(0.4, 0, 0, 1); }

html:not(.mobile-platform) .dp-logo:hover .ic-lg-sp:before {
  width: 0; }

html:not(.mobile-platform) .dp-logo:hover .ic-lg-sp:after {
  width: 100%;
  transition-delay: 200ms; }

#dp-mn-menu {
  width: auto;
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center; }

#dp-mn-menu li {
  list-style: none;
  text-transform: uppercase;
  margin: 0 32px; }

#dp-mn-menu li:first-child {
  margin-left: 0; }

#dp-mn-menu li:last-child {
  margin-right: 0; }

#dp-mn-menu li a {
  z-index: 0;
  line-height: 1;
  letter-spacing: .3em;
  margin-left: -.15em;
  position: relative;
  padding: 0 5px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  font: 18px 'ACBoucle', sans-serif;
  transition: color 0.4s cubic-bezier(0.22, 0.61, 0.36, 1) 0.2s; }

#dp-mn-menu li a:after {
  content: '';
  position: absolute;
  width: 100%;
  max-width: 100%;
  z-index: -1;
  height: 10px;
  margin-left: -.15em;
  background: #FD1821;
  left: 0;
  bottom: 0;
  overflow: hidden;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); }

#dp-mn-menu li.active a:after,
html:not(.mobile-platform) #dp-mn-menu li a:hover:after {
  transform: none;
  transform-origin: left center; }

#dp-mn-menu li.active a,
html:not(.mobile-platform) #dp-mn-menu li a:hover {
  color: white;
  transition-delay: 0s; }

#dp-mn-menu li.active a {
  cursor: default; }

#global-music {
  display: none; }

.ms-hp-common {
  padding: 0;
  border: none;
  display: block;
  background: none; }

.ms-hp-common:disabled {
  cursor: default !important;
  opacity: .5 !important; }

#ms-hd-mp {
  width: 32px;
  height: 26px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0; }

#ms-hd {
  width: 22px;
  height: 14px;
  cursor: pointer;
  position: relative; }

#ms-hd .ln-ms-hd {
  width: 2px;
  height: 6px; }

#ms-hd-mp .ln-ms-hd {
  width: 4px;
  height: 11px; }

.ln-ms-hd {
  display: block;
  background: white;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  transition: all 300ms cubic-bezier(0.4, 0, 0, 1); }

#ms-hd .ln-ms-hd:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #FD1821;
  transform: translateY(100%);
  transition: transform 600ms cubic-bezier(0.4, 0, 0, 1); }

html:not(.mobile-platform) #ms-hd:hover:not(:disabled) .ln-ms-hd2:before {
  transition-delay: 25ms; }

html:not(.mobile-platform) #ms-hd:hover:not(:disabled) .ln-ms-hd3:before {
  transition-delay: 50ms; }

html:not(.mobile-platform) #ms-hd:hover:not(:disabled) .ln-ms-hd4:before {
  transition-delay: 75ms; }

html:not(.mobile-platform) #ms-hd:hover:not(:disabled) .ln-ms-hd5:before {
  transition-delay: 100ms; }

html:not(.mobile-platform) #ms-hd:hover:not(:disabled) .ln-ms-hd6:before {
  transition-delay: 125ms; }

html:not(.mobile-platform) #ms-hd:hover:not(:disabled) .ln-ms-hd:before {
  transform: none; }

#ms-hd .ln-ms-hd1,
#ms-hd-mp .ln-ms-hd1 {
  left: 0; }

#ms-hd .ln-ms-hd2 {
  left: 4px; }

#ms-hd .ln-ms-hd3 {
  left: 8px; }

#ms-hd .ln-ms-hd4 {
  left: 12px; }

#ms-hd .ln-ms-hd5 {
  left: 16px; }

#ms-hd .ln-ms-hd6,
#ms-hd-mp .ln-ms-hd6 {
  right: 0; }

#ms-hd.is-playing .ln-ms-hd1 {
  animation: audioHmA .3s alternate infinite; }

#ms-hd.is-playing .ln-ms-hd2 {
  animation: audioHmA .4s alternate infinite; }

#ms-hd.is-playing .ln-ms-hd3 {
  animation: audioHmA .38s alternate infinite; }

#ms-hd.is-playing .ln-ms-hd4 {
  animation: audioHmA .34s alternate infinite; }

#ms-hd.is-playing .ln-ms-hd5 {
  animation: audioHmA .44s alternate infinite; }

#ms-hd.is-playing .ln-ms-hd6 {
  animation: audioHmA .48s alternate infinite; }

#ms-hd-mp .ln-ms-hd2 {
  left: 7px; }

#ms-hd-mp .ln-ms-hd3 {
  left: 14px; }

#ms-hd-mp .ln-ms-hd4 {
  left: 21px; }

#ms-hd-mp .ln-ms-hd5 {
  left: 28px; }

#ms-hd-mp.is-playing .ln-ms-hd1 {
  animation: audioMpA .3s alternate infinite; }

#ms-hd-mp.is-playing .ln-ms-hd2 {
  animation: audioMpA .4s alternate infinite; }

#ms-hd-mp.is-playing .ln-ms-hd3 {
  animation: audioMpA .38s alternate infinite; }

#ms-hd-mp.is-playing .ln-ms-hd4 {
  animation: audioMpA .34s alternate infinite; }

#ms-hd-mp.is-playing .ln-ms-hd5 {
  animation: audioMpA .44s alternate infinite; }

#ms-hd-mp.is-playing .ln-ms-hd6 {
  animation: audioMpA .48s alternate infinite; }

@keyframes audioHmA {
  0% {
    height: 6px; }
  100% {
    height: 14px; } }

@keyframes audioMpA {
  0% {
    height: 11px; }
  100% {
    height: 26px; } }

#nav-loader {
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  padding: 120px 0 85px 0;
  z-index: 10;
  display: none; }

#nav-loader, .process-loading-shell {
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; }

.inner-nav-loader {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black; }

.process-loading-shell {
  width: 150px;
  height: 6px;
  top: -3px;
  position: absolute;
  overflow: hidden; }

.process-loading {
  width: 100%;
  height: 100%;
  position: relative;
  background: #FD1821;
  animation: loaderPages 800ms ease-out infinite; }

.process-loading:before,
.process-loading:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 100%;
  top: 0; }

.process-loading:before {
  left: 0;
  background: linear-gradient(to right, black 0%, #fd1821 100%); }

.process-loading:after {
  right: 0;
  background: linear-gradient(to right, #fd1821 0%, black 100%); }

@keyframes loaderPages {
  0% {
    transform: translateX(-120%); }
  100% {
    transform: translateX(100%); } }

#footer {
  width: 100%;
  height: 85px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 50px;
  z-index: 9; }

.dp-footer {
  width: 100%;
  height: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center; }

.copyright-dp-dt {
  max-width: 50%;
  margin-top: 2px;
  text-align: center;
  letter-spacing: .1em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.3);
  font: 14px 'ACBoucle', sans-serif; }

.copyright-dp-dt p {
  display: inline; }

.copyright-dp-dt, .copyright-dp-dt *:not(a) {
  cursor: default; }

.copyright-dp-dt b, .copyright-dp-dt strong {
  font-weight: normal; }

.copyright-dp-dt a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.3);
  transition: all .2s ease-out; }

html:not(.mobile-platform) .copyright-dp-dt a:hover {
  color: #FD1821; }

.share-ft-mn,
.listen-ft-mn {
  width: auto;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative; }

.listen-ft-mn {
  -ms-flex-align: end;
  align-items: flex-end; }

.hv-share-ft {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  border: none;
  padding: 0;
  background: none;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  transition: all .2s ease-out;
  transform: translateY(38px); }

.ic-share-ft,
.ic-listen-ft {
  position: absolute;
  top: 0;
  transition: all 200ms cubic-bezier(0.4, 1, 1, 1); }

.ic-share-ft {
  width: 12px;
  height: 12px;
  left: 0;
  background: url(imgs/svg/share-mn.svg) no-repeat center;
  background-size: 100% 100%; }

.ic-listen-ft {
  width: 13px;
  height: 13px;
  right: 0;
  background: url(imgs/svg/listen-mn.svg) no-repeat center;
  background-size: 100% 100%; }

.tx-share-ft,
.tx-listen-ft {
  color: white;
  font: 12px 'ACBoucle', sans-serif;
  line-height: 12px;
  letter-spacing: .3em;
  text-transform: uppercase;
  transition: all 200ms ease-out; }

.tx-share-ft {
  transform: translateX(29px); }

.tx-listen-ft {
  transform: translateX(-29px); }

html:not(.mobile-platform) .share-ft-mn:hover .hv-share-ft,
html:not(.mobile-platform) .share-ft-mn:hover .tx-share-ft {
  transform: none; }

html:not(.mobile-platform) .share-ft-mn:hover .ic-share-ft {
  transform: scale(0); }

html:not(.mobile-platform) .listen-ft-mn:hover .hv-share-ft,
html:not(.mobile-platform) .listen-ft-mn:hover .tx-listen-ft {
  transform: none; }

html:not(.mobile-platform) .listen-ft-mn:hover .ic-listen-ft {
  transform: scale(0); }

.sc-ic-ft,
.vd-ic-ft {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: baseline;
  align-items: baseline;
  margin-top: 22px; }

.vd-ic-ft {
  -ms-flex-pack: end;
  justify-content: flex-end; }

.sc-ic-ft a,
.vd-ic-ft a {
  position: relative;
  visibility: hidden;
  opacity: 0;
  transform: translateY(15px);
  transition: all 300ms cubic-bezier(0.4, 1, 1, 1); }

.sc-ic-ft a {
  margin-right: 22px; }

.sc-ic-ft a:last-child {
  margin-right: 0; }

.vd-ic-ft a {
  margin-left: 22px; }

.vd-ic-ft a:first-child {
  margin-left: 0; }

html:not(.mobile-platform) .share-ft-mn:hover .sc-ic-ft a,
html:not(.mobile-platform) .listen-ft-mn:hover .vd-ic-ft a {
  opacity: 1;
  visibility: visible;
  transform: none; }

.sc-ic-ft a:before,
.sc-ic-ft a:after,
.vd-ic-ft a:before,
.vd-ic-ft a:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: all 300ms cubic-bezier(0.4, 1, 1, 1); }

.sc-ic-ft a:after {
  opacity: 0; }

.vd-ic-ft a:after {
  opacity: 0; }

.sc-ic-ft a.vk-sc {
  width: 18px;
  height: 10px; }

.sc-ic-ft a.tw-sc {
  width: 17px;
  height: 12px; }

.sc-ic-ft a.fb-sc {
  width: 8px;
  height: 15px; }

.sc-ic-ft a.in-sc {
  width: 13px;
  height: 13px; }

.vd-ic-ft a.al-vd {
  width: 13px;
  height: 15px; }

.vd-ic-ft a.sn-vd {
  width: 22px;
  height: 12px; }

.vd-ic-ft a.yt-vd {
  width: 17px;
  height: 12px; }

.vd-ic-ft a.sf-vd {
  width: 14px;
  height: 14px; }

html:not(.mobile-platform) .share-ft-mn:hover .sc-ic-ft a.vk-sc {
  transition-delay: 50ms; }

html:not(.mobile-platform) .share-ft-mn:hover .sc-ic-ft a.tw-sc {
  transition-delay: 100ms; }

html:not(.mobile-platform) .share-ft-mn:hover .sc-ic-ft a.fb-sc {
  transition-delay: 150ms; }

html:not(.mobile-platform) .share-ft-mn:hover .sc-ic-ft a.in-sc {
  transition-delay: 200ms; }

html:not(.mobile-platform) .listen-ft-mn:hover .vd-ic-ft a.al-vd {
  transition-delay: 200ms; }

html:not(.mobile-platform) .listen-ft-mn:hover .vd-ic-ft a.sn-vd {
  transition-delay: 150ms; }

html:not(.mobile-platform) .listen-ft-mn:hover .vd-ic-ft a.yt-vd {
  transition-delay: 100ms; }

html:not(.mobile-platform) .listen-ft-mn:hover .vd-ic-ft a.sf-vd {
  transition-delay: 50ms; }

.sc-ic-ft a.vk-sc:before {
  background: url("imgs/svg/vk-sc.svg") no-repeat center;
  background-size: 100% 100%; }

.sc-ic-ft a.tw-sc:before {
  background: url("imgs/svg/tw-sc.svg") no-repeat center;
  background-size: 100% 100%; }

.sc-ic-ft a.fb-sc:before {
  background: url("imgs/svg/fb-sc.svg") no-repeat center;
  background-size: 100% 100%; }

.sc-ic-ft a.in-sc:before {
  background: url("imgs/svg/in-sc.svg") no-repeat center;
  background-size: 100% 100%; }

.sc-ic-ft a.vk-sc:after {
  background: url("imgs/svg/vk-sc-hv.svg") no-repeat center;
  background-size: 100% 100%; }

.sc-ic-ft a.tw-sc:after {
  background: url("imgs/svg/tw-sc-hv.svg") no-repeat center;
  background-size: 100% 100%; }

.sc-ic-ft a.fb-sc:after {
  background: url("imgs/svg/fb-sc-hv.svg") no-repeat center;
  background-size: 100% 100%; }

.sc-ic-ft a.in-sc:after {
  background: url("imgs/svg/in-sc-hv.svg") no-repeat center;
  background-size: 100% 100%; }

.vd-ic-ft a.al-vd:before {
  background: url("imgs/svg/apple-music.svg") no-repeat center;
  background-size: 100% 100%; }

.vd-ic-ft a.sn-vd:before {
  background: url("imgs/svg/soundcloud.svg") no-repeat center;
  background-size: 100% 100%; }

.vd-ic-ft a.yt-vd:before {
  background: url("imgs/svg/youtube.svg") no-repeat center;
  background-size: 100% 100%; }

.vd-ic-ft a.sf-vd:before {
  background: url("imgs/svg/spotify.svg") no-repeat center;
  background-size: 100% 100%; }

.vd-ic-ft a.al-vd:after {
  background: url("imgs/svg/apple-music-hv.svg") no-repeat center;
  background-size: 100% 100%; }

.vd-ic-ft a.sn-vd:after {
  background: url("imgs/svg/soundcloud-hv.svg") no-repeat center;
  background-size: 100% 100%; }

.vd-ic-ft a.yt-vd:after {
  background: url("imgs/svg/youtube-hv.svg") no-repeat center;
  background-size: 100% 100%; }

.vd-ic-ft a.sf-vd:after {
  background: url("imgs/svg/spotify-hv.svg") no-repeat center;
  background-size: 100% 100%; }

html:not(.mobile-platform) .sc-ic-ft a:hover:before {
  opacity: 0; }

html:not(.mobile-platform) .sc-ic-ft a:hover:after {
  opacity: 1; }

html:not(.mobile-platform) .vd-ic-ft a:hover:before {
  opacity: 0; }

html:not(.mobile-platform) .vd-ic-ft a:hover:after {
  opacity: 1; }

/* COMMON: TABLET & MOBILE */
.open-mp-menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: none; }

.open-mp-menu,
.close-mp-menu {
  width: 27px;
  height: 27px;
  padding: 0;
  border: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0; }

.line-mp-menu {
  display: block;
  height: 4px;
  position: relative; }

.line-mp-menu1 {
  width: 100%; }

.line-mp-menu2 {
  width: 21px; }

.line-mp-menu3 {
  width: 25px; }

.line-mp-menu:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: white; }

.close-mp-menu {
  opacity: 0;
  visibility: hidden;
  background: url("imgs/svg/close-gv.svg") no-repeat center;
  background-size: 100% 100%;
  transform: translateY(-100%); }

.opened .close-mp-menu {
  animation: closeBtnToOpen 200ms ease-out normal forwards;
  animation-delay: 700ms; }

.opened .open-mp-menu {
  animation: openBtnToOpen 200ms ease-out normal forwards; }

@keyframes closeBtnToOpen {
  to {
    opacity: 1;
    visibility: visible;
    transform: none; } }

@keyframes openBtnToOpen {
  to {
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%); } }

.closed .close-mp-menu {
  opacity: 1;
  transform: none;
  visibility: visible;
  animation: closeBtnToClose 200ms ease-out normal forwards; }

.closed .open-mp-menu {
  opacity: 0;
  transform: translateY(100%);
  visibility: hidden;
  animation: openBtnToClose 200ms ease-out normal forwards;
  animation-delay: 700ms; }

@keyframes closeBtnToClose {
  to {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%); } }

@keyframes openBtnToClose {
  to {
    opacity: 1;
    visibility: visible;
    transform: none; } }

.mp-logo {
  width: 179px;
  height: 92px;
  background: url("imgs/svg/title-hm.svg") no-repeat center;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

body[data-page=main] .mp-logo {
  opacity: 0;
  visibility: hidden;
  transform: scale(0); }

body[data-page=main] .opened .mp-logo {
  opacity: 1;
  visibility: visible;
  animation: logoMainToOpen 400ms ease-out normal forwards 1000ms; }

body[data-page=main] .closed .mp-logo {
  animation: logoMainToClose 500ms ease-out normal forwards; }

@keyframes logoMainToOpen {
  33% {
    transform: scale(1.1); }
  66% {
    transform: scale(0.9); }
  to {
    transform: none; } }

@keyframes logoMainToClose {
  33% {
    transform: scale(1.2); }
  66% {
    transform: scale(0.9); }
  from {
    opacity: 1;
    transform: none;
    visibility: visible; }
  to {
    opacity: 0;
    visibility: hidden;
    transform: scale(0); } }

.mp-menu-b {
  width: 100%;
  height: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  background: black;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1; }

.opened .mp-menu-b {
  animation: openMenu 700ms cubic-bezier(0.4, 0, 0, 1) normal forwards; }

.closed .mp-menu-b {
  height: 100%;
  animation: closeMenu 700ms cubic-bezier(0.4, 0, 0, 1) normal forwards;
  animation-delay: 400ms; }

@keyframes openMenu {
  to {
    height: 100%; } }

@keyframes closeMenu {
  to {
    height: 0; } }

.inner-mp-menu-b {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 116px 30px 50px 30px; }

.ctn-mp-menu {
  width: 100%;
  position: relative;
  min-height: calc(100% - 129px); }

.list-mp-menu {
  width: 100%;
  margin: 0;
  padding: 64px 0 0 0;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.list-mp-menu li {
  width: 100%;
  display: block;
  text-transform: uppercase;
  font: 35px 'ACBoucle', sans-serif;
  margin-bottom: 68px;
  padding: 0 0 0 .25em;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  opacity: 0;
  transform: translateY(-50px);
  transition: all 200ms ease-out; }

.list-mp-menu li:after {
  content: '';
  position: absolute;
  width: 30px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.9) 0%, transparent 100%); }

.opened .list-mp-menu li {
  opacity: 1;
  transform: none; }

.closed .list-mp-menu li {
  opacity: 0;
  transform: translateY(-50px); }

.opened .list-mp-menu li:first-child {
  transition-delay: 100ms; }

.opened .list-mp-menu li:nth-child(2) {
  transition-delay: 200ms; }

.opened .list-mp-menu li:nth-child(3) {
  transition-delay: 300ms; }

.opened .list-mp-menu li:nth-child(4) {
  transition-delay: 400ms; }

.opened .list-mp-menu li:nth-child(5) {
  transition-delay: 500ms; }

.opened .list-mp-menu li:nth-child(6) {
  transition-delay: 600ms; }

.closed .list-mp-menu li:first-child {
  transition-delay: 100ms; }

.closed .list-mp-menu li:nth-child(2) {
  transition-delay: 200ms; }

.closed .list-mp-menu li:nth-child(3) {
  transition-delay: 300ms; }

.closed .list-mp-menu li:nth-child(4) {
  transition-delay: 400ms; }

.closed .list-mp-menu li:nth-child(5) {
  transition-delay: 500ms; }

.closed .list-mp-menu li:nth-child(6) {
  transition-delay: 600ms; }

.list-mp-menu li a {
  max-width: 100%;
  display: inline-block;
  letter-spacing: .25em;
  margin-left: -.125em;
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.75); }

.list-mp-menu li a:after {
  content: '';
  position: absolute;
  width: 0;
  max-width: 100%;
  z-index: -1;
  height: 10px;
  margin-left: -.125em;
  background: #FD1821;
  left: 0;
  bottom: 5px;
  overflow: hidden; }

.list-mp-menu li.active a {
  color: white; }

.list-mp-menu li.active a:after {
  width: 100%; }

.ft-mp-menu {
  width: 100%;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  padding: 0 0 50px 0;
  background: black; }

.inner-ft-mp-menu {
  width: 100%;
  position: relative;
  padding: 63px 0 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.inner-ft-mp-menu:before,
.inner-ft-mp-menu:after {
  content: '';
  position: absolute;
  width: 0;
  top: 0;
  height: 1px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.2); }

.inner-ft-mp-menu:before {
  right: 50%; }

.inner-ft-mp-menu:after {
  left: 50%; }

.opened .inner-ft-mp-menu:before,
.opened .inner-ft-mp-menu:after {
  animation: linesOpenMenu 300ms ease-out normal forwards;
  animation-delay: 600ms; }

.closed .inner-ft-mp-menu:before,
.closed .inner-ft-mp-menu:after {
  width: 50%;
  animation: linesCloseMenu 300ms ease-out normal forwards; }

@keyframes linesOpenMenu {
  to {
    width: 50%; } }

@keyframes linesCloseMenu {
  to {
    width: 0; } }

.ft-mp-links {
  width: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.right-ft-mp-links {
  -ms-flex-align: end;
  align-items: flex-end; }

.opened .ft-mp-links.left-ft-mp-links {
  transform: translateX(50px); }

.opened .ft-mp-links.right-ft-mp-links {
  transform: translateX(-50px); }

.opened .ft-mp-links.left-ft-mp-links,
.opened .ft-mp-links.right-ft-mp-links {
  opacity: 0; }

.opened .ft-mp-links.left-ft-mp-links,
.opened .ft-mp-links.right-ft-mp-links {
  animation: linksOpenMenu 200ms ease-out normal forwards;
  animation-delay: 700ms; }

.closed .ft-mp-links.left-ft-mp-links,
.closed .ft-mp-links.right-ft-mp-links {
  transform: none; }

.closed .ft-mp-links.left-ft-mp-links,
.closed .ft-mp-links.right-ft-mp-links {
  opacity: 1; }

.closed .ft-mp-links.left-ft-mp-links {
  animation: linksLeftCloseMenu 200ms ease-out normal forwards; }

.closed .ft-mp-links.right-ft-mp-links {
  animation: linksRightCloseMenu 200ms ease-out normal forwards; }

@keyframes linksOpenMenu {
  to {
    opacity: 1;
    transform: none; } }

@keyframes linksLeftCloseMenu {
  to {
    opacity: 0;
    transform: translateX(50px); } }

@keyframes linksRightCloseMenu {
  to {
    opacity: 0;
    transform: translateX(-50px); } }

.title-ft-mp-links {
  width: auto;
  font: 20px 'ACBoucle', sans-serif;
  text-transform: uppercase;
  letter-spacing: .3em;
  color: rgba(255, 255, 255, 0.26); }

.right-ft-mp-links .title-ft-mp-links {
  margin-right: -4px; }

.icons-ft-mp-links {
  width: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 15px; }

.title-ft-mp-links p {
  margin: 0; }

.icons-ft-mp-links a {
  display: block;
  position: relative;
  opacity: .2; }

.social-pg-ft-links a {
  margin-right: 34px; }

.social-pg-ft-links a:last-child {
  margin-right: 0; }

.music-pg-ft-links a {
  margin-left: 34px; }

.music-pg-ft-links a:first-child {
  margin-left: 0; }

.social-pg-ft-links a.vk-sc-ft {
  width: 33px;
  height: 19px; }

.social-pg-ft-links a.tw-sc-ft {
  width: 31px;
  height: 22px; }

.social-pg-ft-links a.fb-sc-ft {
  width: 14px;
  height: 27px; }

.social-pg-ft-links a.in-sc-ft {
  width: 25px;
  height: 25px; }

.music-pg-ft-links a.al-vd-ft {
  width: 23px;
  height: 26px; }

.music-pg-ft-links a.sn-vd-ft {
  width: 35px;
  height: 19px; }

.music-pg-ft-links a.yt-vd-ft {
  width: 32px;
  height: 22px; }

.music-pg-ft-links a.sf-vd-ft {
  width: 26px;
  height: 26px; }

.social-pg-ft-links a.vk-sc-ft {
  background: url("imgs/svg/vk-sc.svg") no-repeat center;
  background-size: 100% 100%; }

.social-pg-ft-links a.tw-sc-ft {
  background: url("imgs/svg/tw-sc.svg") no-repeat center;
  background-size: 100% 100%; }

.social-pg-ft-links a.fb-sc-ft {
  background: url("imgs/svg/fb-sc.svg") no-repeat center;
  background-size: 100% 100%; }

.social-pg-ft-links a.in-sc-ft {
  background: url("imgs/svg/in-sc.svg") no-repeat center;
  background-size: 100% 100%; }

.music-pg-ft-links a.al-vd-ft {
  background: url("imgs/svg/apple-music.svg") no-repeat center;
  background-size: 100% 100%; }

.music-pg-ft-links a.sn-vd-ft {
  background: url("imgs/svg/soundcloud.svg") no-repeat center;
  background-size: 100% 100%; }

.music-pg-ft-links a.yt-vd-ft {
  background: url("imgs/svg/youtube.svg") no-repeat center;
  background-size: 100% 100%; }

.music-pg-ft-links a.sf-vd-ft {
  background: url("imgs/svg/spotify.svg") no-repeat center;
  background-size: 100% 100%; }

.upload-videos {
  width: 100%;
  position: relative;
  padding: 23px 0 94px 0;
  display: none; }

.upload-vs-btn {
  width: 100%;
  border: none;
  font: 19px 'ACBoucle', sans-serif;
  color: rgba(255, 255, 255, 0.5);
  background: #10110F;
  padding: 31px 0 29px 0;
  text-transform: uppercase;
  letter-spacing: .35em; }

.title-videos-mp {
  width: 100%;
  margin: 23px 0 -4.5vw 0;
  white-space: nowrap;
  display: none; }

.title-videos-mp h1 {
  width: 100%;
  margin: 0 -.25em 0 0;
  letter-spacing: .25em;
  color: rgba(255, 255, 255, 0.1);
  text-transform: uppercase;
  font-weight: normal;
  text-align: center;
  font: 11.71vw 'ACBoucle', sans-serif; }

.ll-ms-mp {
  width: 100%;
  display: none; }

.ll-ms-mp a {
  width: 100%;
  height: 60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  background: #23272C;
  font-size: 16px;
  position: relative;
  letter-spacing: .04em;
  margin: 0 0 15px 0; }

.ll-ms-mp a:last-child {
  margin-bottom: 0; }

.ll-ms-mp a span {
  position: relative;
  padding-left: 46px; }

.ll-ms-mp a span:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0; }

.ll-ms-mp a.apple-music span:before {
  width: 20px;
  height: 25px;
  left: 0;
  transform: translateY(-2px);
  background: url(imgs/svg/apple-music.svg) no-repeat center;
  background-size: 100% 100%; }

.ll-ms-mp a.google-play span:before {
  width: 20px;
  height: 23px;
  left: 0;
  background: url(imgs/svg/google-play.svg) no-repeat center;
  background-size: 100% 100%; }

.ll-ms-mp a.yandex-music span:before {
  width: 20px;
  height: 28px;
  left: 0;
  background: url(imgs/svg/yandex-music.svg) no-repeat center;
  background-size: 100% 100%; }

.circle-mp {
  width: 100vw;
  height: 100vw;
  z-index: 1;
  position: absolute;
  bottom: -15%;
  left: 7%;
  right: 0;
  margin: 0 auto;
  border-radius: 100%;
  background: url(imgs/circle-hm.png);
  display: none; }

.pg-list-grid:before {
  content: 'Фотогалерея';
  position: fixed;
  top: auto;
  left: 0;
  right: 0;
  width: 100%;
  font: 11.71vw 'ACBoucle', sans-serif;
  line-height: 1;
  color: rgba(255, 255, 255, 0.1);
  letter-spacing: .25em;
  margin: -.71em -.125em 0 auto;
  text-transform: uppercase;
  text-align: center;
  display: none; }

.mCSB_inside > .mCSB_container {
  margin-right: 0; }

.mCSB_scrollTools {
  width: 6px;
  opacity: 1 !important; }

.mCSB_scrollTools .mCSB_draggerRail {
  background: none; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.locked-space {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
  text-size-adjust: none; }

/* ANIMATION COMMON */
.dp-logo {
  transform: translateY(-50%) rotateX(-45deg); }

#ms-hd, #dp-mn-menu li {
  transform: translateY(-100%) rotateX(-45deg); }

.share-ft-mn, .listen-ft-mn {
  transform: translateY(25%) rotateX(45deg); }

.copyright-dp-dt {
  transform: translateY(150%) rotateX(45deg); }

.dp-logo, #dp-mn-menu li, #ms-hd, .share-ft-mn, .listen-ft-mn, .copyright-dp-dt {
  opacity: 0; }

.dp-logo {
  transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

#dp-mn-menu li, #ms-hd, .share-ft-mn, .listen-ft-mn, .copyright-dp-dt {
  transition: transform 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

#dp-mn-menu li:first-child {
  transition-delay: 200ms; }

#dp-mn-menu li:nth-child(2) {
  transition-delay: 300ms; }

#dp-mn-menu li:nth-child(3) {
  transition-delay: 400ms; }

#dp-mn-menu li:nth-child(4) {
  transition-delay: 500ms; }

#dp-mn-menu li:nth-child(5) {
  transition-delay: 600ms; }

#dp-mn-menu li:nth-child(6) {
  transition-delay: 700ms; }

#ms-hd {
  transition-delay: 1s; }

.share-ft-mn {
  transition-delay: 200ms; }

.copyright-dp-dt {
  transition-delay: 400ms; }

.listen-ft-mn {
  transition-delay: 700ms; }

#header.start-animation .dp-logo,
#header.start-animation #dp-mn-menu li,
#header.start-animation #ms-hd,
#footer.start-animation .share-ft-mn,
#footer.start-animation .listen-ft-mn,
#footer.start-animation .copyright-dp-dt {
  opacity: 1;
  transform: none; }

/* RESPONSIVE COMMON: DESCTOP */
@media only screen and (max-width: 1680px) {
  .dp-logo {
    font-size: 26px;
    line-height: 31px; }
  .ic-lg-sp {
    width: 44px;
    height: 3px; }
  #dp-mn-menu li {
    margin: 0 26px; }
  #dp-mn-menu li a {
    font-size: 16px; }
  #dp-mn-menu li a:after {
    height: 8px;
    bottom: 1px; }
  .tx-share-ft, .tx-listen-ft {
    font-size: 11px;
    line-height: 13px; }
  .ic-share-ft {
    top: 1px;
    width: 10px;
    height: 10px; }
  .ic-listen-ft {
    width: 11px;
    height: 11px; }
  .sc-ic-ft a {
    margin-right: 18px; }
  .vd-ic-ft a {
    margin-left: 18px; }
  .copyright-dp-dt {
    font-size: 13px; } }

@media only screen and (max-width: 1440px) {
  #header {
    height: 90px;
    padding: 0 30px; }
  .dp-logo {
    font-size: 21px;
    line-height: 25px;
    margin-top: 8px; }
  .ic-lg-sp {
    width: 34px;
    margin-right: 3px; }
  #dp-mn-menu li {
    margin: 0 20px; }
  #dp-mn-menu li a {
    font-size: 14px;
    padding: 0 4px; }
  #dp-mn-menu li a:before {
    margin-left: 4px; }
  #nav-loader {
    padding: 90px 0 70px 0; }
  .process-loading-shell {
    width: 120px; }
  .one-word-gb-l {
    font-size: 26px; }
  .set-gb-loader {
    width: 277px;
    height: 46px; }
  .process-loading-gb {
    margin-top: 6px; }
  #footer {
    height: 70px;
    padding: 0 30px; }
  .copyright-dp-dt {
    font-size: 11px; }
  .tx-share-ft, .tx-listen-ft {
    font-size: 10px;
    line-height: 12px; }
  .hv-share-ft {
    transform: translateY(31px); }
  .sc-ic-ft, .vd-ic-ft {
    margin-top: 16px; }
  .sc-ic-ft a {
    margin-right: 14px; }
  .vd-ic-ft a {
    margin-left: 14px; }
  .sc-ic-ft a.vk-sc {
    width: 14px;
    height: 8px; }
  .sc-ic-ft a.tw-sc {
    width: 14px;
    height: 10px; }
  .sc-ic-ft a.fb-sc {
    width: 6px;
    height: 13px; }
  .sc-ic-ft a.in-sc {
    width: 11px;
    height: 11px; }
  .vd-ic-ft a.sf-vd {
    width: 13px;
    height: 13px; }
  .vd-ic-ft a.yt-vd {
    width: 14px;
    height: 10px; }
  .vd-ic-ft a.sn-vd {
    width: 18px;
    height: 10px; }
  .vd-ic-ft a.al-vd {
    width: 10px;
    height: 12px; } }

/* RESPONSIVE COMMON: TABLET */
@media only screen and (max-device-width: 1366px) {
  html.mobile-platform #header {
    height: 116px;
    z-index: 10;
    background: linear-gradient(to bottom, black 0%, black 10%, transparent 100%); }
  html.mobile-platform body[data-page=main] #header {
    background: none; }
  html.mobile-platform body[data-page=main] #footer {
    display: none; }
  html.mobile-platform .dp-head {
    display: none; }
  html.mobile-platform .mp-head {
    display: block; }
  html.mobile-platform .open-mp-menu {
    display: block; }
  html.mobile-platform .line-mp-menu2 {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0; }
  html.mobile-platform .line-mp-menu3 {
    position: absolute;
    bottom: 0; }
  html.mobile-platform #nav-loader {
    padding: 116px 0 0 0; }
  html.mobile-platform .process-loading-shell {
    top: -61px; }
  html.mobile-platform #footer {
    height: auto;
    background: black;
    position: relative;
    left: inherit;
    right: inherit;
    bottom: inherit; }
  html.mobile-platform .dp-footer {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 54px 0 42px 0; }
  html.mobile-platform .copyright-dp-dt {
    font-size: 20px;
    line-height: 30px;
    width: 440px;
    max-width: 100%;
    margin: 0 auto; }
  html.mobile-platform .copyright-dp-dt,
  html.mobile-platform .copyright-dp-dt a {
    color: rgba(255, 255, 255, 0.2); }
  html.mobile-platform .share-ft-mn, html.mobile-platform .listen-ft-mn {
    width: calc(50% - 220px); }
  html.mobile-platform .ic-share-ft, html.mobile-platform .ic-listen-ft {
    display: none; }
  html.mobile-platform .listen-ft-mn .hv-share-ft {
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-right: -4px;
    text-align: right;
    display: block; }
  html.mobile-platform .hv-share-ft, html.mobile-platform .tx-share-ft, html.mobile-platform .tx-listen-ft {
    transform: none; }
  html.mobile-platform .tx-share-ft, html.mobile-platform .tx-listen-ft {
    font-size: 20px;
    line-height: inherit;
    opacity: .5; }
  html.mobile-platform .sc-ic-ft, html.mobile-platform .vd-ic-ft {
    margin-top: 26px; }
  html.mobile-platform .sc-ic-ft a, html.mobile-platform .vd-ic-ft a {
    opacity: .5;
    transform: none;
    visibility: visible; }
  html.mobile-platform .sc-ic-ft a {
    margin-right: 32px; }
  html.mobile-platform .vd-ic-ft a {
    margin-left: 32px; }
  html.mobile-platform .sc-ic-ft a.vk-sc {
    width: 31px;
    height: 18px; }
  html.mobile-platform .sc-ic-ft a.tw-sc {
    width: 31px;
    height: 22px; }
  html.mobile-platform .sc-ic-ft a.fb-sc {
    width: 13px;
    height: 27px; }
  html.mobile-platform .sc-ic-ft a.in-sc {
    width: 25px;
    height: 25px; }
  html.mobile-platform .vd-ic-ft a.sf-vd {
    width: 27px;
    height: 27px; }
  html.mobile-platform .vd-ic-ft a.yt-vd {
    width: 30px;
    height: 21px; }
  html.mobile-platform .vd-ic-ft a.sn-vd {
    width: 36px;
    height: 20px; }
  html.mobile-platform .vd-ic-ft a.al-vd {
    width: 20px;
    height: 24px; }
  html.mobile-platform .sc-ic-ft a:after, html.mobile-platform .vd-ic-ft a:after {
    display: none; } }

@media only screen and (max-device-width: 1024px) {
  html.mobile-platform .copyright-dp-dt {
    -ms-flex-order: 3;
    order: 3;
    margin: 46px auto 0 auto; }
  html.mobile-platform .share-ft-mn, html.mobile-platform .listen-ft-mn {
    width: 50%; } }

@media only screen and (max-device-width: 800px) {
  html.mobile-platform .copyright-dp-dt {
    margin: 56px auto 0 auto; } }

@media only screen and (max-device-width: 640px) {
  html.mobile-platform .mp-logo {
    width: 159px;
    height: 82px; }
  html.mobile-platform #ms-hd-mp .ln-ms-hd {
    width: 3px; }
  html.mobile-platform .list-mp-menu {
    padding: 54px 0 0 0; }
  html.mobile-platform .list-mp-menu li {
    font-size: 30px;
    margin-bottom: 54px; }
  html.mobile-platform .inner-ft-mp-menu {
    padding: 54px 0 0 0; }
  html.mobile-platform .ctn-mp-menu {
    min-height: calc(100% - 116px); }
  html.mobile-platform .title-ft-mp-links {
    font-size: 18px; }
  html.mobile-platform .ft-mp-menu, html.mobile-platform .inner-mp-menu-b {
    padding-bottom: 40px; }
  html.mobile-platform .social-pg-ft-links a, html.mobile-platform .sc-ic-ft a {
    margin-right: 24px; }
  html.mobile-platform .music-pg-ft-links a, html.mobile-platform .vd-ic-ft a {
    margin-left: 24px; }
  html.mobile-platform .social-pg-ft-links a.vk-sc-ft,
  html.mobile-platform .sc-ic-ft a.vk-sc {
    width: 30px;
    height: 18px; }
  html.mobile-platform .social-pg-ft-links a.tw-sc-ft,
  html.mobile-platform .sc-ic-ft a.tw-sc {
    width: 28px;
    height: 20px; }
  html.mobile-platform .social-pg-ft-links a.fb-sc-ft,
  html.mobile-platform .sc-ic-ft a.fb-sc {
    width: 12px;
    height: 26px; }
  html.mobile-platform .social-pg-ft-links a.in-sc-ft,
  html.mobile-platform .sc-ic-ft a.in-sc {
    width: 21px;
    height: 21px; }
  html.mobile-platform .music-pg-ft-links a.sf-vd-ft,
  html.mobile-platform .vd-ic-ft a.sf-vd {
    width: 22px;
    height: 22px; }
  html.mobile-platform .music-pg-ft-links a.yt-vd-ft,
  html.mobile-platform .vd-ic-ft a.yt-vd {
    width: 28px;
    height: 21px; }
  html.mobile-platform .music-pg-ft-links a.sn-vd-ft,
  html.mobile-platform .vd-ic-ft a.sn-vd {
    width: 30px;
    height: 18px; }
  html.mobile-platform .music-pg-ft-links a.al-vd-ft,
  html.mobile-platform .vd-ic-ft a.al-vd {
    width: 20px;
    height: 25px; }
  html.mobile-platform .dp-footer {
    padding: 44px 0 32px 0; }
  html.mobile-platform .tx-share-ft, html.mobile-platform .tx-listen-ft {
    font-size: 18px; }
  html.mobile-platform .sc-ic-ft, html.mobile-platform .vd-ic-ft {
    margin-top: 20px; }
  html.mobile-platform .copyright-dp-dt {
    width: 400px;
    font-size: 18px;
    line-height: 28px;
    margin: 58px auto 0 auto; } }

/* RESPONSIVE COMMON: MOBILE */
@media only screen and (max-width: 740px) and (max-height: 425px) and (orientation: landscape) {
  html.mobile-platform #header {
    height: 75px;
    padding: 0 20px; }
  html.mobile-platform body:not([data-page=main]) #header {
    position: relative; }
  html.mobile-platform .mp-logo {
    width: 106px;
    height: 54px; }
  html.mobile-platform .open-mp-menu, html.mobile-platform .close-mp-menu {
    width: 21px;
    height: 21px; }
  html.mobile-platform .line-mp-menu {
    height: 3px; }
  html.mobile-platform .line-mp-menu2 {
    width: 16px; }
  html.mobile-platform .line-mp-menu3 {
    width: 19px; }
  html.mobile-platform #nav-loader {
    padding: 75px 0 0 0; }
  html.mobile-platform .process-loading-shell {
    top: -40px; }
  html.mobile-platform .one-word-gb-l {
    font-size: 21px; }
  html.mobile-platform .set-gb-loader {
    width: 229px;
    height: 39px; }
  html.mobile-platform #ms-hd-mp {
    width: 23px;
    height: 18px; }
  html.mobile-platform #ms-hd-mp .ln-ms-hd {
    width: 3px;
    height: 8px; }
  html.mobile-platform #ms-hd-mp .ln-ms-hd2 {
    left: 5px; }
  html.mobile-platform #ms-hd-mp .ln-ms-hd3 {
    left: 10px; }
  html.mobile-platform #ms-hd-mp .ln-ms-hd4 {
    left: 15px; }
  html.mobile-platform #ms-hd-mp .ln-ms-hd5 {
    left: 20px; }
  @keyframes audioMpA {
    0% {
      height: 8px; }
    100% {
      height: 18px; } }
  html.mobile-platform .inner-mp-menu-b {
    padding: 60px 20px 30px 20px; }
  html.mobile-platform .list-mp-menu {
    padding: 51px 0 0 0; }
  html.mobile-platform .list-mp-menu li {
    font-size: 20px;
    margin-bottom: 35px; }
  html.mobile-platform .list-mp-menu li a:after {
    height: 6px;
    bottom: 3px; }
  html.mobile-platform .ctn-mp-menu {
    min-height: calc(100% - 93px); }
  html.mobile-platform .ft-mp-menu {
    padding: 0 0 30px 0; }
  html.mobile-platform .relative-ft-menu .ft-mp-menu {
    padding-bottom: 30px; }
  html.mobile-platform .inner-ft-mp-menu {
    padding: 44px 0 0 0; }
  html.mobile-platform .icons-ft-mp-links a {
    opacity: .5; }
  html.mobile-platform .title-ft-mp-links {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5); }
  html.mobile-platform .right-ft-mp-links .title-ft-mp-links {
    margin-right: -2px; }
  html.mobile-platform .social-pg-ft-links a.vk-sc-ft, html.mobile-platform .sc-ic-ft a.vk-sc {
    width: 22px;
    height: 13px; }
  html.mobile-platform .social-pg-ft-links a.tw-sc-ft, html.mobile-platform .sc-ic-ft a.tw-sc {
    width: 22px;
    height: 15px; }
  html.mobile-platform .social-pg-ft-links a.fb-sc-ft, html.mobile-platform .sc-ic-ft a.fb-sc {
    width: 9px;
    height: 20px; }
  html.mobile-platform .social-pg-ft-links a.in-sc-ft, html.mobile-platform .sc-ic-ft a.in-sc {
    width: 17px;
    height: 17px; }
  html.mobile-platform .music-pg-ft-links a.sf-vd-ft, html.mobile-platform .vd-ic-ft a.sf-vd {
    width: 18px;
    height: 18px; }
  html.mobile-platform .music-pg-ft-links a.yt-vd-ft, html.mobile-platform .vd-ic-ft a.yt-vd {
    width: 20px;
    height: 14px; }
  html.mobile-platform .music-pg-ft-links a.sn-vd-ft, html.mobile-platform .vd-ic-ft a.sn-vd {
    width: 27px;
    height: 15px; }
  html.mobile-platform .music-pg-ft-links a.al-vd-ft, html.mobile-platform .vd-ic-ft a.al-vd {
    width: 17px;
    height: 20px; }
  html.mobile-platform #footer {
    padding: 0 20px; }
  html.mobile-platform .dp-footer {
    padding: 40px 0 36px 0; }
  html.mobile-platform .sc-ic-ft, html.mobile-platform .vd-ic-ft {
    margin-top: 15px; }
  html.mobile-platform .tx-share-ft, html.mobile-platform .listen-ft-mn, html.mobile-platform .tx-listen-ft {
    font-size: 12px; }
  html.mobile-platform .listen-ft-mn .hv-share-ft {
    margin-right: -2px; }
  html.mobile-platform .copyright-dp-dt {
    width: 270px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: .07em;
    margin: 36px auto 0 auto; } }

@media only screen and (max-width: 425px) {
  html.mobile-platform #header {
    height: 75px;
    padding: 0 20px; }
  html.mobile-platform .mp-logo {
    width: 106px;
    height: 54px; }
  html.mobile-platform .open-mp-menu, html.mobile-platform .close-mp-menu {
    width: 21px;
    height: 21px; }
  html.mobile-platform .line-mp-menu {
    height: 3px; }
  html.mobile-platform .line-mp-menu2 {
    width: 16px; }
  html.mobile-platform .line-mp-menu3 {
    width: 19px; }
  html.mobile-platform #ms-hd-mp {
    width: 23px;
    height: 18px; }
  html.mobile-platform #ms-hd-mp .ln-ms-hd {
    width: 3px;
    height: 8px; }
  html.mobile-platform #ms-hd-mp .ln-ms-hd2 {
    left: 5px; }
  html.mobile-platform #ms-hd-mp .ln-ms-hd3 {
    left: 10px; }
  html.mobile-platform #ms-hd-mp .ln-ms-hd4 {
    left: 15px; }
  html.mobile-platform #ms-hd-mp .ln-ms-hd5 {
    left: 20px; }
  @keyframes audioMpA {
    0% {
      height: 8px; }
    100% {
      height: 18px; } }
  html.mobile-platform #nav-loader {
    padding: 75px 0 0 0; }
  html.mobile-platform .process-loading-shell {
    top: -40px; }
  html.mobile-platform .one-word-gb-l {
    font-size: 21px; }
  html.mobile-platform .set-gb-loader {
    width: 229px;
    height: 39px; }
  html.mobile-platform .inner-mp-menu-b {
    padding: 60px 20px 30px 20px; }
  html.mobile-platform .ctn-mp-menu {
    min-height: calc(100% - 176px); }
  html.mobile-platform .list-mp-menu {
    padding: 51px 0 0 0; }
  html.mobile-platform .list-mp-menu li {
    font-size: 20px;
    margin-bottom: 35px; }
  html.mobile-platform .list-mp-menu li a:after {
    height: 6px;
    bottom: 3px; }
  html.mobile-platform .ft-mp-menu {
    padding: 0 0 30px 0; }
  html.mobile-platform .relative-ft-menu .ft-mp-menu {
    padding-bottom: 30px; }
  html.mobile-platform .inner-ft-mp-menu {
    -ms-flex-direction: column;
    flex-direction: column; }
  html.mobile-platform .ft-mp-links {
    width: 100%;
    -ms-flex-align: center;
    align-items: center; }
  html.mobile-platform .inner-ft-mp-menu {
    padding: 44px 0 0 0; }
  html.mobile-platform .icons-ft-mp-links, html.mobile-platform .sc-ic-ft, html.mobile-platform .vd-ic-ft {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  html.mobile-platform .icons-ft-mp-links {
    width: 176px; }
  html.mobile-platform .icons-ft-mp-links a {
    opacity: .5; }
  html.mobile-platform .right-ft-mp-links {
    padding-top: 34px; }
  html.mobile-platform .title-ft-mp-links {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    margin-left: .35em; }
  html.mobile-platform .right-ft-mp-links .title-ft-mp-links {
    margin: 0; }
  html.mobile-platform .social-pg-ft-links a, html.mobile-platform .sc-ic-ft a {
    margin-right: 0; }
  html.mobile-platform .music-pg-ft-links a, html.mobile-platform .vd-ic-ft a {
    margin-left: 0; }
  html.mobile-platform .social-pg-ft-links a.vk-sc-ft, html.mobile-platform .sc-ic-ft a.vk-sc {
    width: 22px;
    height: 13px; }
  html.mobile-platform .social-pg-ft-links a.tw-sc-ft, html.mobile-platform .sc-ic-ft a.tw-sc {
    width: 22px;
    height: 15px; }
  html.mobile-platform .social-pg-ft-links a.fb-sc-ft, html.mobile-platform .sc-ic-ft a.fb-sc {
    width: 9px;
    height: 20px; }
  html.mobile-platform .social-pg-ft-links a.in-sc-ft, html.mobile-platform .sc-ic-ft a.in-sc {
    width: 17px;
    height: 17px; }
  html.mobile-platform .music-pg-ft-links a.sf-vd-ft, html.mobile-platform .vd-ic-ft a.sf-vd {
    width: 18px;
    height: 18px; }
  html.mobile-platform .music-pg-ft-links a.yt-vd-ft, html.mobile-platform .vd-ic-ft a.yt-vd {
    width: 20px;
    height: 14px; }
  html.mobile-platform .music-pg-ft-links a.sn-vd-ft, html.mobile-platform .vd-ic-ft a.sn-vd {
    width: 27px;
    height: 15px; }
  html.mobile-platform .music-pg-ft-links a.al-vd-ft, html.mobile-platform .vd-ic-ft a.al-vd {
    width: 17px;
    height: 20px; }
  html.mobile-platform .opened .ft-mp-links.left-ft-mp-links,
  html.mobile-platform .opened .ft-mp-links.right-ft-mp-links {
    transform: translateY(-20px); }
  html.mobile-platform .opened .ft-mp-links.right-ft-mp-links {
    animation-delay: 800ms; }
  html.mobile-platform .closed .ft-mp-links.left-ft-mp-links {
    animation-delay: 100ms; }
  html.mobile-platform .closed .inner-ft-mp-menu:before, html.mobile-platform .closed .inner-ft-mp-menu:after {
    animation-delay: 200ms; }
  @keyframes linksLeftCloseMenu {
    to {
      opacity: 0;
      transform: translateY(-20px); } }
  @keyframes linksRightCloseMenu {
    to {
      opacity: 0;
      transform: translateY(-20px); } }
  html.mobile-platform #footer {
    padding: 0 20px; }
  html.mobile-platform .dp-footer {
    padding: 40px 0 38px 0; }
  html.mobile-platform .share-ft-mn, html.mobile-platform .listen-ft-mn {
    width: 100%;
    -ms-flex-align: center;
    align-items: center; }
  html.mobile-platform .listen-ft-mn {
    margin: 40px 0 0 0; }
  html.mobile-platform .listen-ft-mn .hv-share-ft {
    margin: 0;
    text-align: center; }
  html.mobile-platform .sc-ic-ft, html.mobile-platform .vd-ic-ft {
    width: 176px;
    margin-top: 15px; }
  html.mobile-platform .tx-share-ft, html.mobile-platform .tx-listen-ft {
    font-size: 12px;
    width: 100%;
    margin-left: .35em; }
  html.mobile-platform .copyright-dp-dt {
    width: 270px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: .07em;
    margin: 40px auto 0 auto; } }

@media only screen and (max-width: 320px) {
  html.mobile-platform .copyright-dp-dt {
    width: 100%; } }

/* GALLERY */
.lg-close {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 1080;
  cursor: pointer;
  transform: translateY(-100px);
  transition: transform 600ms cubic-bezier(0.4, 0, 0, 1); }

.lg-visible .lg-close {
  transform: none; }

.lg-close:before, .lg-close:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform-origin: 50% 50%;
  transition: all 200ms ease-out;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0, 1); }

.lg-close:after {
  opacity: 0;
  -webkit-transform: scale(0.5);
  -webkit-filter: blur(5px); }

.lg-close:before {
  background: url("imgs/svg/close-gv.svg") no-repeat center;
  background-size: contain; }

.lg-close:after {
  background: url("imgs/svg/close-gv-hv.svg") no-repeat center;
  background-size: contain; }

html:not(.mobile-platform) .lg-close:hover:before {
  opacity: 0;
  -webkit-transform: scale(1.8);
  -webkit-filter: blur(5px); }

html:not(.mobile-platform) .lg-close:hover:after {
  opacity: 1;
  -webkit-transform: none;
  -webkit-filter: none; }

.lg-prev, .lg-next {
  width: 144px;
  height: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  z-index: 1080;
  transition: transform 600ms cubic-bezier(0.4, 0, 0, 1); }

.lg-prev {
  left: 0;
  transform: translateX(-100%); }

.lg-next {
  right: 0;
  transform: translateX(100%); }

.lg-visible .lg-prev {
  transform: none; }

.lg-visible .lg-next {
  transform: none; }

.lg-prev:before, .lg-next:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto 0;
  background-color: white;
  transition: all 300ms cubic-bezier(0.4, 0, 0, 1); }

html:not(.mobile-platform) .lg-prev:hover:before,
html:not(.mobile-platform) .lg-next:hover:before {
  background: #FD1821; }

.lg-prev:before {
  transform: translateX(-72px); }

.lg-next:before {
  transform: translateX(72px); }

html:not(.mobile-platform) .lg-prev:hover:before,
html:not(.mobile-platform) .lg-next:hover:before {
  transform: none; }

#lg-counter, .lg-sub-html {
  display: none; }

.lg-inner {
  transform: translateY(100vh);
  -ms-transform: translateY(-100vh);
  transition: transform 600ms cubic-bezier(0.4, 0, 0, 1) !important; }

.lg-visible .lg-inner {
  transform: none; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  transform: translateY(100vh);
  transition: transform 300ms cubic-bezier(0.4, 0, 0, 1) 0s; }

.lg-outer .lg-video-cont {
  display: block;
  width: 68% !important;
  max-width: 68% !important;
  max-height: 100%;
  position: absolute;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object,
.lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0; }

.lg-outer.lg-visible {
  opacity: 1; }

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  transition-duration: 0s !important;
  opacity: 1; }

.lg-outer.lg-grab img.lg-object {
  cursor: grab;
  cursor: -o-grab; }

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -o-grabbing; }

.lg-outer .lg {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%; }

.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap; }

.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important; }

.lg-outer.lg-css .lg-current {
  display: inline-block !important; }

.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%; }

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

.lg-outer .lg-item.lg-current {
  z-index: 1060; }

.lg-outer .lg-image {
  display: block;
  max-width: 76%;
  max-height: 79.3%;
  width: auto !important;
  height: auto !important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  position: absolute; }

.lg-outer.lg-show-after-load .lg-item .lg-object,
.lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important; }

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object,
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1; }

.lg-outer .lg-empty-html {
  display: none; }

.lg-outer.lg-hide-download #lg-download {
  display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.15s ease 0s; }

.lg-backdrop.in {
  opacity: 1; }

.lg-outer.lg-css3 .lg-item.lg-prev-slide,
.lg-outer.lg-css3 .lg-item.lg-next-slide,
.lg-outer.lg-css3 .lg-item.lg-current {
  transition-duration: inherit !important;
  transition-timing-function: inherit !important; }

.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
  transition: none 0s ease 0s !important; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1; }

.lg-css3.lg-fade .lg-item.lg-prev-slide,
.lg-css3.lg-fade .lg-item.lg-next-slide,
.lg-css3.lg-fade .lg-item.lg-current {
  transition: opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transform: translate3d(-100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  transform: translate3d(100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  transition: transform 600ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 100ms ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  transform: none; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  transform: translateX(-100%); }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  transform: translateX(100%); }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  transform: none;
  opacity: 1; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  transition: transform 600ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

[data-scrollbar], [scrollbar], scrollbar {
  display: block;
  position: relative; }

[data-scrollbar] .scroll-content, [scrollbar] .scroll-content, scrollbar .scroll-content {
  transform: translateZ(0); }

[data-scrollbar].sticky .scrollbar-track, [scrollbar].sticky .scrollbar-track, scrollbar.sticky .scrollbar-track {
  background: rgba(222, 222, 222, 0.75); }

[data-scrollbar] .scrollbar-track, [scrollbar] .scrollbar-track, scrollbar .scrollbar-track {
  position: absolute;
  opacity: 0;
  z-index: 1;
  transition: opacity .5s ease-out,background .5s ease-out;
  background: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

[data-scrollbar] .scrollbar-track.show, [data-scrollbar] .scrollbar-track:hover, [scrollbar] .scrollbar-track.show, [scrollbar] .scrollbar-track:hover, scrollbar .scrollbar-track.show, scrollbar .scrollbar-track:hover {
  opacity: 1; }

[data-scrollbar] .scrollbar-track:hover, [scrollbar] .scrollbar-track:hover, scrollbar .scrollbar-track:hover {
  background: rgba(222, 222, 222, 0.75); }

[data-scrollbar] .scrollbar-track-x, [scrollbar] .scrollbar-track-x, scrollbar .scrollbar-track-x {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px; }

[data-scrollbar] .scrollbar-track-y, [scrollbar] .scrollbar-track-y, scrollbar .scrollbar-track-y {
  top: 0;
  right: 0;
  width: 8px;
  height: 100%; }

[data-scrollbar] .scrollbar-thumb, [scrollbar] .scrollbar-thumb, scrollbar .scrollbar-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px; }

[data-scrollbar] .overscroll-glow, [scrollbar] .overscroll-glow, scrollbar .overscroll-glow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.mejs__offscreen {
  display: none; }

.mejs__container {
  position: relative;
  text-align: left;
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  height: 40px !important; }

.mejs__fill-container, .mejs__fill-container .mejs__container {
  height: 100%;
  width: 100%; }

.mejs__fill-container {
  background: transparent;
  margin: 0 auto;
  overflow: hidden;
  position: relative; }

.mejs__container:focus {
  outline: none; }

.mejs__iframe-overlay {
  height: 100%;
  position: absolute;
  width: 100%; }

.mejs__embed,
.mejs__embed body {
  background: #000;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.mejs__fullscreen {
  overflow: hidden !important; }

.mejs__container-fullscreen {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000; }

.mejs__container-fullscreen .mejs__mediaelement,
.mejs__container-fullscreen video {
  height: 100% !important;
  width: 100% !important; }

.mejs__background {
  left: 0;
  position: absolute;
  top: 0; }

.mejs__mediaelement {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0; }

.mejs__poster {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1; }

:root .mejs__poster-img {
  display: none; }

.mejs__poster-img {
  border: 0;
  padding: 0; }

.mejs__overlay {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0; }

.mejs__layer {
  z-index: 1; }

.mejs__overlay-play {
  cursor: pointer; }

.mejs__overlay-button {
  height: 80px;
  width: 80px; }

.mejs__overlay-loading {
  height: 80px;
  width: 80px; }

.mejs__overlay-loading-bg-img {
  animation: mejs__loading-spinner 1s linear infinite;
  display: block;
  height: 80px;
  width: 80px;
  z-index: 1; }

@keyframes mejs__loading-spinner {
  100% {
    transform: rotate(360deg); } }

.mejs__controls {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 3; }

.mejs__button.mejs__playpause-button {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 1; }

.mejs__time,
.mejs__time-rail {
  font-size: 10px;
  height: 40px;
  line-height: 10px;
  margin: 0;
  width: 32px; }

.mejs__button > button {
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 40px;
  line-height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-decoration: none;
  width: 35px;
  background: none; }

.mejs__container-keyboard-inactive a,
.mejs__container-keyboard-inactive a:focus,
.mejs__container-keyboard-inactive button,
.mejs__container-keyboard-inactive button:focus,
.mejs__container-keyboard-inactive [role=slider],
.mejs__container-keyboard-inactive [role=slider]:focus {
  outline: 0; }

.mejs__time {
  color: #fff;
  font-size: 16px;
  height: 40px;
  overflow: hidden;
  padding: 0 15px 0 0;
  line-height: 40px;
  text-align: center;
  width: auto;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  pointer-events: none; }

.mejs__button.mejs__play ~ .mejs__time.mejs__duration-container,
.mejs__button.mejs__pause ~ .mejs__time.mejs__currenttime-container,
.mejs__button.mejs__replay ~ .mejs__time.mejs__duration-container {
  opacity: 1; }

.mejs__button.mejs__play ~ .mejs__time.mejs__currenttime-container,
.mejs__button.mejs__pause ~ .mejs__time.mejs__duration-container,
.mejs__button.mejs__replay ~ .mejs__time.mejs__currenttime-container {
  opacity: 0; }

.mejs__play > button:before,
.mejs__pause > button:before,
.mejs__replay > button:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto; }

.mejs__play > button:before, .mejs__pause > button:before {
  width: 6px;
  height: 9px; }

.mejs__replay > button:before {
  width: 10px;
  height: 10px; }

.mejs__play > button:before {
  background: url("imgs/svg/play-lb.svg") no-repeat center;
  background-size: 100% 100%; }

.mejs__pause > button:before {
  background: url("imgs/svg/pause-lb.svg") no-repeat center;
  background-size: 100% 100%; }

.mejs__replay > button:before {
  background: url("imgs/svg/replay-lb.svg") no-repeat center;
  background-size: 100% 100%; }

.mejs__time-rail {
  padding: 0;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  margin: 0;
  z-index: 2;
  opacity: 0; }

.audio-list.clicked .mejs__time-rail {
  opacity: 1; }

.mejs__time-total,
.mejs__time-buffering,
.mejs__time-current,
.mejs__time-hovered,
.mejs__time-float-current,
.mejs__time-float-corner,
.mejs__time-marker {
  cursor: pointer;
  display: block;
  height: 100%;
  position: absolute; }

.mejs__button.mejs__volume-button,
.mejs__horizontal-volume-slider {
  display: none; }

.mejs__time-total {
  margin: 0;
  width: 100%;
  overflow: hidden; }

.audio-list.not-active .mejs__time-total {
  background: rgba(255, 255, 255, 0.06); }

.mejs__time-total,
.mejs__time-current,
.mejs__time-handle-content {
  transition: background 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.audio-list.active .mejs__time-total,
html:not(.mobile-platform) .audio-list:hover .mejs__time-total {
  background: rgba(255, 255, 255, 0.5) !important; }

.mejs__time-total:active {
  outline: none !important; }

.mejs__time-buffering {
  animation: buffering-stripes 2s linear infinite;
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
  background-size: 15px 15px;
  width: 100%; }

@keyframes buffering-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.mejs__time-loaded {
  display: none; }

.audio-list.active .mejs__time-current,
.audio-list.active .mejs__time-handle-content,
html:not(.mobile-platform) .audio-list:hover .mejs__time-current,
html:not(.mobile-platform) .audio-list:hover .mejs__time-handle-content {
  background: white; }

.audio-list.not-active .mejs__time-current,
.audio-list.not-active .mejs__time-handle-content {
  background: rgba(255, 255, 255, 0.2); }

.mejs__time-hovered {
  display: none; }

.mejs__time-current,
.mejs__time-buffering,
.mejs__time-hovered {
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0.15s ease-in all;
  width: 100%; }

.mejs__time-buffering {
  transform: scaleX(1); }

.mejs__time-hovered {
  transition: height 0.1s cubic-bezier(0.44, 0, 1, 1); }

.mejs__time-hovered.no-hover {
  transform: scaleX(0) !important; }

.mejs__time-handle, .mejs__time-handle-content {
  display: none; }

.mejs__time-rail:hover .mejs__time-handle-content,
.mejs__time-rail .mejs__time-handle-content:focus,
.mejs__time-rail .mejs__time-handle-content:active {
  transform: scale(1); }

.mejs__time-float {
  display: none !important; }

.mejs__time-float-current {
  display: block;
  left: 0;
  margin: 2px;
  text-align: center;
  width: 30px; }

.mejs__time-float-corner {
  border: solid 5px #eee;
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  line-height: 0;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 0; }

.mejs__broadcast {
  color: #fff;
  height: 10px;
  position: absolute;
  top: 15px;
  width: 100%; }

.mejs__captions-button, .mejs__chapters-button {
  position: relative; }

.mejs__captions-button > .mejs__captions-selector,
.mejs__chapters-button > .mejs__chapters-selector {
  background: rgba(50, 50, 50, 0.7);
  border: solid 1px transparent;
  border-radius: 0;
  bottom: 100%;
  margin-right: -43px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 50%;
  visibility: visible;
  width: 86px; }

.mejs__chapters-button > .mejs__chapters-selector {
  margin-right: -55px;
  width: 110px; }

.mejs__captions-selector-list,
.mejs__chapters-selector-list {
  list-style-type: none !important;
  margin: 0;
  overflow: hidden;
  padding: 0; }

.mejs__captions-selector-list-item,
.mejs__chapters-selector-list-item {
  color: #fff;
  cursor: pointer;
  display: block;
  list-style-type: none !important;
  margin: 0 0 6px;
  overflow: hidden;
  padding: 0 10px; }

.mejs__captions-selector-list-item:hover,
.mejs__chapters-selector-list-item:hover {
  background-color: #c8c8c8 !important;
  background-color: rgba(255, 255, 255, 0.4) !important; }

.mejs__captions-selector-input,
.mejs__chapters-selector-input {
  clear: both;
  float: left;
  left: -1000px;
  margin: 3px 3px 0 5px;
  position: absolute; }

.mejs__captions-selector-label,
.mejs__chapters-selector-label {
  cursor: pointer;
  float: left;
  font-size: 10px;
  line-height: 15px;
  padding: 4px 0 0; }

.mejs__captions-selected, .mejs__chapters-selected {
  color: #21f8f8; }

.mejs__captions-translations {
  font-size: 10px;
  margin: 0 0 5px; }

.mejs__captions-layer {
  bottom: 0;
  color: #fff;
  font-size: 16px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center; }

.mejs__captions-layer a {
  color: #fff;
  text-decoration: underline; }

.mejs__captions-layer[lang=ar] {
  font-size: 20px;
  font-weight: normal; }

.mejs__captions-position {
  bottom: 15px;
  left: 0;
  position: absolute;
  width: 100%; }

.mejs__captions-position-hover {
  bottom: 35px; }

.mejs__captions-text,
.mejs__captions-text * {
  background: rgba(20, 20, 20, 0.5);
  box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
  padding: 0;
  white-space: pre-wrap; }

.mejs__cannotplay, .mejs__cannotplay a {
  color: #fff;
  font-size: 0.8em; }

.mejs__cannotplay p, .mejs__cannotplay a {
  display: inline-block;
  padding: 0 15px;
  width: 100%; }

.list-aud-lb audio {
  display: none; }

.audio-list {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  transition: background 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.audio-list:last-child {
  margin-bottom: 0 !important; }

.audio-list.not-active.clicked {
  background: rgba(255, 255, 255, 0.05); }

html:not(.mobile-platform) .audio-list:hover,
.audio-list.active.clicked {
  background: #FD1821; }

.audio-list-play {
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative; }

.audio-list-play:before {
  content: '';
  position: absolute;
  width: 6px;
  height: 9px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: url(imgs/svg/play-lb.svg) center no-repeat;
  background-size: 100% 100%; }

.audio-list-title {
  width: 100%;
  color: white;
  font: 16px 'SourceSansProRegular', sans-serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 40px;
  letter-spacing: .05em;
  padding: 0 62px 0 35px;
  z-index: 2; }

.audio-list-audio {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0; }

/* RESET */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

template, [hidden] {
  display: none; }

p {
  margin: 0; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:active, a:hover {
  outline-width: 0; }

b, strong {
  font-weight: bolder; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

small {
  font-size: 80%; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

button, input, select, textarea {
  font: inherit;
  margin: 0; }

optgroup {
  font-weight: bold; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

/* FONTS */
@font-face {
  font-family: 'ACBoucle';
  src: url("fonts/ACBoucle/ACBoucle.eot?#iefix") format("embedded-opentype"), url("fonts/ACBoucle/ACBoucle.woff") format("woff"), url("fonts/ACBoucle/ACBoucle.ttf") format("truetype"), url("fonts/ACBoucle/ACBoucle.svg#ACBoucle") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SourceSansProRegular';
  src: url("fonts/SourceSansProRegular/SourceSansProRegular.eot");
  src: url("fonts/SourceSansProRegular/SourceSansProRegular.eot?#iefix") format("embedded-opentype"), url("fonts/SourceSansProRegular/SourceSansProRegular.woff") format("woff"), url("fonts/SourceSansProRegular/SourceSansProRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/* SETTINGS */
* {
  box-sizing: border-box; }

::-moz-selection {
  background: white;
  color: black; }

::selection {
  background: white;
  color: black; }

::-moz-selection {
  background: white;
  color: black; }

html, body {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0; }

body {
  overflow: hidden;
  background: black;
  -webkit-overflow-scrolling: touch;
  font-family: 'SourceSansProRegular', sans-serif; }

body.locked {
  overflow: hidden !important; }

button:focus, button:active,
input:focus, input:active,
textarea:focus, textarea:active {
  outline: none; }

textarea,
input[type="submit"],
input[type="text"],
input[type="number"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0 !important;
  box-shadow: none !important; }

h1, h1 strong,
h2, h2 strong,
h3, h3 strong,
h4, h4 strong,
h5, h5 strong,
h6, h6 strong {
  font-weight: normal; }

mark {
  background: white;
  color: black;
  padding: 0 3px; }

/* ABOUT */
.about-ctn {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative; }

.about-ctn .scrollbar-track {
  background: none !important; }

.about-ctn .scrollbar-thumb {
  background: rgba(255, 255, 255, 0.27); }

.about-ctn .scrollbar-track, .about-ctn .scrollbar-thumb {
  width: 6px; }

.about-ctn:before,
.about-ctn:after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  pointer-events: none; }

.about-ctn:before {
  top: 0;
  height: 240px;
  background: linear-gradient(to bottom, black 0%, transparent 100%); }

.about-ctn:after {
  bottom: 0;
  height: 170px;
  background: linear-gradient(to bottom, transparent 0%, black 100%); }

.pd-about-ctn {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 218px 0 85px 0;
  z-index: 1; }

.about-img-ap {
  width: 23vw;
  height: 100%;
  position: absolute;
  left: -2.5% !important;
  top: 0;
  background: url(imgs/about-ap.png) no-repeat;
  background-size: 100% auto;
  background-position-x: 0;
  background-position-y: 160px; }

.scene-a-v, .img-a-v {
  position: absolute; }

.scene-a-v {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 0; }

.inner-about-ctn {
  width: 1024px;
  margin: 0 auto;
  padding: 0 0 0 76px; }

.title-about-pg {
  width: 100%; }

.title-about-pg h1 {
  width: 100%;
  color: white;
  font: 70px 'ACBoucle', sans-serif;
  letter-spacing: .25em;
  text-transform: uppercase;
  margin: 0; }

.content-about-pg {
  width: 100%;
  margin: 45px 0 0; }

.content-about-pg * {
  color: #fff; }

.content-about-pg p,
.content-about-pg h1,
.content-about-pg h2,
.content-about-pg h3,
.content-about-pg h4,
.content-about-pg h5,
.content-about-pg h6,
.content-about-pg ul,
.content-about-pg ol,
.content-about-pg blockquote {
  margin-top: 44px;
  margin-bottom: 44px; }

.content-about-pg p,
.content-about-pg h1,
.content-about-pg h2,
.content-about-pg h3,
.content-about-pg h4,
.content-about-pg h5,
.content-about-pg h6,
.content-about-pg ul,
.content-about-pg ol {
  width: 100%; }

.content-about-pg h1,
.content-about-pg h2,
.content-about-pg h3,
.content-about-pg h4,
.content-about-pg h5,
.content-about-pg h6 {
  letter-spacing: .05em;
  line-height: 1.3; }

.content-about-pg ul {
  padding: 0 0 0 18px; }

.content-about-pg ol {
  padding: 0 0 0 20px; }

.content-about-pg a {
  color: #7b6c88;
  text-decoration: none;
  transition: color 200ms ease-out; }

html:not(.mobile-platform) .content-about-pg a:hover {
  color: #FD1821; }

.content-about-pg a[href^='tel'], .content-about-pg a[href^='mailto'] {
  white-space: nowrap; }

.content-about-pg mark {
  background: white;
  color: black;
  padding: 0 5px; }

.content-about-pg p,
.content-about-pg ul li,
.content-about-pg ol li {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: .025em; }

.content-about-pg h1 {
  font-size: 28px; }

.content-about-pg h2 {
  font-size: 26px; }

.content-about-pg h3 {
  font-size: 24px; }

.content-about-pg h4 {
  font-size: 22px; }

.content-about-pg h5 {
  font-size: 20px; }

.content-about-pg h6 {
  font-size: 18px; }

.content-about-pg blockquote {
  position: relative;
  font: 30px ACBoucle,sans-serif;
  letter-spacing: .15em;
  line-height: 33px;
  text-transform: uppercase;
  display: block; }

.content-about-pg blockquote,
.iframe-wrapper iframe {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%; }

.content-about-pg blockquote:before,
.content-about-pg blockquote:after {
  content: '';
  width: 19px;
  height: 19px;
  display: inline-block;
  position: relative; }

.content-about-pg blockquote:before {
  bottom: -1px;
  margin: 0 7px 0 0;
  background: url("imgs/svg/quote-start.svg") no-repeat; }

.content-about-pg blockquote:after {
  top: -2px;
  margin: 0 0 0 7px;
  background: url("imgs/svg/quote-end.svg") no-repeat; }

.iframe-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  margin: 66px 0; }

.iframe-wrapper iframe {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

/* ANIMATION ABOUT */
html:not(.mobile-platform) .title-about-pg.elem-anim,
html:not(.mobile-platform) .content-about-pg.elem-anim,
html:not(.mobile-platform) .about-img-ap.elem-anim {
  opacity: 0;
  transform: translateY(50vh);
  transition: all 800ms cubic-bezier(0.4, 0, 0.2, 1); }

html:not(.mobile-platform) .content-about-pg.elem-anim {
  transition-delay: 300ms; }

html:not(.mobile-platform) .about-img-ap.elem-anim {
  transition-delay: 600ms; }

html:not(.mobile-platform) .about-ctn .scrollbar-track {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 900ms; }

html:not(.mobile-platform) .title-about-pg.elem-anim.done,
html:not(.mobile-platform) .content-about-pg.elem-anim.done,
html:not(.mobile-platform) .about-img-ap.elem-anim.done {
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0); }

html:not(.mobile-platform) .about-ctn.done .scrollbar-track {
  opacity: 1; }

/* RESPONSIVE ABOUT: DESCTOP */
@media only screen and (max-width: 1680px) {
  .about-img-ap {
    width: 20vw;
    background-position-y: 110px; }
  .inner-about-ctn {
    width: 924px; }
  .pd-about-ctn {
    padding-top: 188px; }
  .title-about-pg h1 {
    font-size: 50px; }
  .content-about-pg {
    margin: 44px 0 0 0; }
  .content-about-pg p,
  .content-about-pg h1,
  .content-about-pg h2,
  .content-about-pg h3,
  .content-about-pg h4,
  .content-about-pg h5,
  .content-about-pg h6,
  .content-about-pg ul,
  .content-about-pg ol,
  .content-about-pg blockquote {
    margin-top: 36px;
    margin-bottom: 36px; }
  .iframe-wrapper {
    margin: 56px 0; }
  .content-about-pg p,
  .content-about-pg ul li,
  .content-about-pg ol li {
    font-size: 15px; }
  .content-about-pg ul {
    padding: 0 0 0 17px; }
  .content-about-pg ol {
    padding: 0 0 0 19px; }
  .content-about-pg h1 {
    font-size: 26px; }
  .content-about-pg h2 {
    font-size: 24px; }
  .content-about-pg h3 {
    font-size: 22px; }
  .content-about-pg h4 {
    font-size: 20px; }
  .content-about-pg h5 {
    font-size: 18px; }
  .content-about-pg h6 {
    font-size: 16px; }
  .content-about-pg blockquote {
    font-size: 26px;
    line-height: 29px; }
  .content-about-pg blockquote:after,
  .content-about-pg blockquote:before {
    width: 16px;
    height: 16px; }
  .content-about-pg blockquote:after {
    top: -3px; } }

@media only screen and (max-width: 1440px) {
  .about-ctn:before {
    height: 180px; }
  .about-ctn:after {
    height: 140px; }
  .inner-about-ctn {
    width: 800px;
    padding: 0 0 0 50px; }
  .pd-about-ctn {
    padding: 138px 0 70px 0; }
  .title-about-pg h1 {
    font-size: 44px; }
  .content-about-pg {
    margin: 32px 0 0 0; }
  .content-about-pg p,
  .content-about-pg h1,
  .content-about-pg h2,
  .content-about-pg h3,
  .content-about-pg h4,
  .content-about-pg h5,
  .content-about-pg h6,
  .content-about-pg ul,
  .content-about-pg ol,
  .content-about-pg blockquote {
    margin-top: 24px;
    margin-bottom: 24px; }
  .iframe-wrapper {
    margin: 36px 0; }
  .content-about-pg p,
  .content-about-pg ul li,
  .content-about-pg ol li {
    font-size: 14px;
    line-height: 21px; }
  .content-about-pg ul {
    padding: 0 0 0 16px; }
  .content-about-pg ol {
    padding: 0 0 0 17px; }
  .content-about-pg h1 {
    font-size: 24px; }
  .content-about-pg h2 {
    font-size: 22px; }
  .content-about-pg h3 {
    font-size: 20px; }
  .content-about-pg h4 {
    font-size: 18px; }
  .content-about-pg h5 {
    font-size: 16px; }
  .content-about-pg h6 {
    font-size: 14px; }
  .content-about-pg blockquote {
    font-size: 22px;
    line-height: 25px; }
  .content-about-pg blockquote:after,
  .content-about-pg blockquote:before {
    width: 14px;
    height: 14px; }
  .content-about-pg blockquote:after {
    top: -1px; }
  .content-about-pg mark {
    padding: 0 3px; } }

@media only screen and (max-width: 1280px) {
  .about-img-ap {
    width: 19vw; } }

/* RESPONSIVE ABOUT: TABLET */
@media only screen and (max-device-width: 1366px) {
  html.mobile-platform body[data-page=about] {
    height: auto;
    overflow-y: auto; }
  html.mobile-platform .about-ctn:after,
  html.mobile-platform .about-ctn:before {
    display: none; }
  html.mobile-platform .scene-a-v {
    display: none; }
  html.mobile-platform .pd-about-ctn {
    padding: 116px 30px 30px; }
  html.mobile-platform .inner-about-ctn {
    width: 800px;
    padding: 0; }
  html.mobile-platform .title-about-pg h1 {
    font-size: 11.71vw;
    line-height: 11.71vw;
    margin: 35px -.12em 33px 0;
    text-align: center;
    color: rgba(255, 255, 255, 0.1); }
  html.mobile-platform .content-about-pg p,
  html.mobile-platform .content-about-pg h1,
  html.mobile-platform .content-about-pg h2,
  html.mobile-platform .content-about-pg h3,
  html.mobile-platform .content-about-pg h4,
  html.mobile-platform .content-about-pg h5,
  html.mobile-platform .content-about-pg h6,
  html.mobile-platform .content-about-pg ul,
  html.mobile-platform .content-about-pg ol,
  html.mobile-platform .content-about-pg blockquote {
    margin-top: 40px;
    margin-bottom: 40px; }
  html.mobile-platform .iframe-wrapper {
    margin: 50px 0; }
  html.mobile-platform .content-about-pg p,
  html.mobile-platform .content-about-pg ul li,
  html.mobile-platform .content-about-pg ol li {
    font-size: 18px;
    line-height: 28px; }
  html.mobile-platform .content-about-pg ul {
    padding: 0 0 0 18px; }
  html.mobile-platform .content-about-pg ol {
    padding: 0 0 0 16px; }
  html.mobile-platform .content-about-pg h1 {
    font-size: 28px; }
  html.mobile-platform .content-about-pg h2 {
    font-size: 26px; }
  html.mobile-platform .content-about-pg h3 {
    font-size: 24px; }
  html.mobile-platform .content-about-pg h4 {
    font-size: 22px; }
  html.mobile-platform .content-about-pg h5 {
    font-size: 20px; }
  html.mobile-platform .content-about-pg h6 {
    font-size: 18px; }
  html.mobile-platform .content-about-pg blockquote {
    font-size: 30px;
    line-height: 33px; }
  html.mobile-platform .content-about-pg blockquote:after,
  html.mobile-platform .content-about-pg blockquote:before {
    width: 16px;
    height: 16px; }
  html.mobile-platform .content-about-pg blockquote:after {
    top: -5px; }
  html.mobile-platform .content-about-pg blockquote,
  html.mobile-platform .iframe-wrapper iframe {
    width: 100%;
    margin-right: 0;
    margin-left: 0; } }

@media only screen and (max-device-width: 800px) {
  html.mobile-platform .inner-about-ctn {
    width: 100%; }
  html.mobile-platform .title-about-pg h1 {
    font-size: 13vw;
    line-height: 13vw; } }

@media only screen and (max-device-width: 640px) {
  html.mobile-platform .title-about-pg h1 {
    margin: 10px -.12em 23px 0; }
  html.mobile-platform .content-about-pg p,
  html.mobile-platform .content-about-pg ul li,
  html.mobile-platform .content-about-pg ol li {
    font-size: 16px;
    line-height: 26px; }
  html.mobile-platform .content-about-pg ul {
    padding: 0 0 0 17px; }
  html.mobile-platform .content-about-pg ol {
    padding: 0 0 0 15px; }
  html.mobile-platform .content-about-pg h1 {
    font-size: 22px; }
  html.mobile-platform .content-about-pg h2 {
    font-size: 21px; }
  html.mobile-platform .content-about-pg h3 {
    font-size: 20px; }
  html.mobile-platform .content-about-pg h4 {
    font-size: 19px; }
  html.mobile-platform .content-about-pg h5 {
    font-size: 18px; }
  html.mobile-platform .content-about-pg h6 {
    font-size: 16px; } }

/* RESPONSIVE ABOUT: MOBILE */
@media only screen and (max-width: 740px) and (max-height: 425px) and (orientation: landscape) {
  html.mobile-platform .pd-about-ctn {
    padding: 0 20px 10px; }
  html.mobile-platform .inner-about-ctn {
    width: 450px; }
  html.mobile-platform .title-about-pg {
    margin: 13px 0 23px 0; }
  html.mobile-platform .title-about-pg h1 {
    font-size: 11vw;
    line-height: 11vw;
    margin: 0 -.12em 23px 0; }
  html.mobile-platform .content-about-pg {
    margin: 30px 0 0; }
  html.mobile-platform .content-about-pg p,
  html.mobile-platform .content-about-pg h1,
  html.mobile-platform .content-about-pg h2,
  html.mobile-platform .content-about-pg h3,
  html.mobile-platform .content-about-pg h4,
  html.mobile-platform .content-about-pg h5,
  html.mobile-platform .content-about-pg h6,
  html.mobile-platform .content-about-pg ul,
  html.mobile-platform .content-about-pg ol,
  html.mobile-platform .content-about-pg blockquote {
    margin-top: 30px;
    margin-bottom: 30px; }
  html.mobile-platform .iframe-wrapper {
    margin: 40px 0; }
  html.mobile-platform .content-about-pg p,
  html.mobile-platform .content-about-pg ul li,
  html.mobile-platform .content-about-pg ol li {
    font-size: 16px;
    line-height: 26px; }
  html.mobile-platform .content-about-pg ul {
    padding: 0 0 0 16px; }
  html.mobile-platform .content-about-pg ol {
    padding: 0 0 0 15px; }
  html.mobile-platform .content-about-pg h1 {
    font-size: 21px; }
  html.mobile-platform .content-about-pg h2 {
    font-size: 20px; }
  html.mobile-platform .content-about-pg h3 {
    font-size: 19px; }
  html.mobile-platform .content-about-pg h4 {
    font-size: 18px; }
  html.mobile-platform .content-about-pg h5 {
    font-size: 17px; }
  html.mobile-platform .content-about-pg h6 {
    font-size: 16px; }
  html.mobile-platform .content-about-pg blockquote {
    font-size: 28px;
    line-height: 31px; }
  html.mobile-platform .content-about-pg blockquote:after,
  html.mobile-platform .content-about-pg blockquote:before {
    width: 14px;
    height: 14px; }
  html.mobile-platform .content-about-pg p,
  html.mobile-platform .content-about-pg ul li,
  html.mobile-platform .content-about-pg ol li {
    font-size: 16px;
    line-height: 26px; }
  html.mobile-platform .content-about-pg blockquote:after {
    top: -6px; } }

@media only screen and (max-width: 425px) {
  html.mobile-platform .pd-about-ctn {
    padding: 75px 20px 20px; }
  html.mobile-platform .title-about-pg {
    margin: 13px 0 23px 0; }
  html.mobile-platform .title-about-pg h1 {
    margin: 0 -.12em 23px 0; }
  html.mobile-platform .content-about-pg {
    margin: 30px 0 0; }
  html.mobile-platform .content-about-pg p,
  html.mobile-platform .content-about-pg h1,
  html.mobile-platform .content-about-pg h2,
  html.mobile-platform .content-about-pg h3,
  html.mobile-platform .content-about-pg h4,
  html.mobile-platform .content-about-pg h5,
  html.mobile-platform .content-about-pg h6,
  html.mobile-platform .content-about-pg ul,
  html.mobile-platform .content-about-pg ol,
  html.mobile-platform .content-about-pg blockquote {
    margin-top: 30px;
    margin-bottom: 30px; }
  html.mobile-platform .iframe-wrapper {
    margin: 40px 0; }
  html.mobile-platform .content-about-pg p,
  html.mobile-platform .content-about-pg ul li,
  html.mobile-platform .content-about-pg ol li {
    font-size: 16px;
    line-height: 26px; }
  html.mobile-platform .content-about-pg ul {
    padding: 0 0 0 16px; }
  html.mobile-platform .content-about-pg ol {
    padding: 0 0 0 15px; }
  html.mobile-platform .content-about-pg h1 {
    font-size: 21px; }
  html.mobile-platform .content-about-pg h2 {
    font-size: 20px; }
  html.mobile-platform .content-about-pg h3 {
    font-size: 19px; }
  html.mobile-platform .content-about-pg h4 {
    font-size: 18px; }
  html.mobile-platform .content-about-pg h5 {
    font-size: 17px; }
  html.mobile-platform .content-about-pg h6 {
    font-size: 16px; }
  html.mobile-platform .content-about-pg blockquote {
    font-size: 28px;
    line-height: 31px; }
  html.mobile-platform .content-about-pg blockquote:after,
  html.mobile-platform .content-about-pg blockquote:before {
    width: 14px;
    height: 14px; }
  html.mobile-platform .content-about-pg blockquote:after {
    top: -6px; } }

@media only screen and (max-width: 480px) and (max-height: 320px) and (orientation: landscape) {
  html.mobile-platform .inner-about-ctn {
    width: 350px; } }

@media only screen and (max-width: 320px) {
  html.mobile-platform .title-about-pg h1 {
    font-size: 15vw;
    line-height: 15vw; } }

/* ALBUMS */
.albums-cnt {
  z-index: 0;
  overflow: hidden;
  background: url(imgs/albums-hm.png);
  background-size: cover; }

.scn-albums-ds {
  width: 100%;
  height: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center; }

.title-scn-albums {
  width: 100%;
  padding: 0 200px; }

.title-scn-albums h1 {
  font: 70px 'ACBoucle', sans-serif;
  color: white;
  text-transform: uppercase;
  letter-spacing: .25em;
  margin-right: -.25em;
  cursor: default; }

.list-wr-albums {
  width: 100%;
  height: 530px;
  position: relative;
  overflow: hidden; }

.list-wr-albums .scroll-content {
  height: 100%; }

.list-wr-albums .scrollbar-track {
  display: none !important; }

.inner-albums-ds {
  width: auto;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  white-space: nowrap;
  z-index: 1; }

.link-album {
  width: 437px;
  text-decoration: none;
  color: white;
  margin-right: 50px;
  display: inline-block; }

.link-album:first-child {
  margin-left: 204px; }

.link-album:last-child {
  margin-right: 204px; }

.img-album {
  width: 100%;
  height: 437px;
  overflow: hidden;
  position: relative;
  transform-origin: 50% 50%;
  transition: transform 300ms cubic-bezier(0.4, 0, 0, 1); }

html:not(.mobile-platform):not(.ie) .link-album:hover .img-album {
  transform: scale(0.96); }

.img-album img {
  width: 100%;
  height: 100%;
  display: block; }

.desc-album {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 28px; }

.title-album {
  width: 100%;
  overflow: hidden;
  font: 32px 'ACBoucle', sans-serif;
  line-height: 32px;
  color: white;
  letter-spacing: .3em;
  margin-right: -.3em;
  text-transform: uppercase; }

.title-album p {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative; }

.title-album b, .title-album strong {
  font-weight: normal; }

.title-album p:after {
  content: '';
  position: absolute;
  width: 100%;
  z-index: -1;
  height: 12px;
  margin-left: -.25em;
  background: #FD1821;
  left: 0;
  bottom: 4px;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1); }

html:not(.mobile-platform) .link-album:hover .title-album p:after {
  transform: none;
  transform-origin: left center; }

.release-date-album {
  width: 100%;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: .1em;
  margin-right: -.1em; }

.release-date-album p {
  display: block;
  width: 100%; }

.release-date-album b, .release-date-album strong {
  font-weight: normal; }

.release-date-album, .release-date-album p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

/* ANIMATION ALBUMS */
html:not(.mobile-platform) .title-scn-albums.elem-anim,
html:not(.mobile-platform) .link-album {
  opacity: 0;
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1); }

html:not(.mobile-platform) .title-scn-albums.elem-anim,
html:not(.mobile-platform) .link-album {
  transform-style: preserve-3d; }

html:not(.mobile-platform) .title-scn-albums.elem-anim {
  transform: translateX(15vw) rotate3d(-0.3, 1, 0, 20deg);
  transition-delay: 200ms; }

html:not(.mobile-platform) .link-album {
  transform: translateX(40vw) rotate3d(-0.3, 1, 0, 30deg); }

html:not(.mobile-platform) .title-scn-albums.elem-anim.done,
html:not(.mobile-platform) .link-album.done {
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0); }

/* RESPONSIVE ALBUMS: DESCTOP */
@media only screen and (max-width: 1680px) {
  .list-wr-albums:before, .list-wr-albums:after {
    width: 100px; }
  .title-scn-albums {
    padding: 0 120px; }
  .title-scn-albums h1 {
    font-size: 50px;
    margin: 20px 0; }
  .list-wr-albums {
    height: 482px; }
  .link-album {
    width: 407px;
    margin-right: 40px; }
  .link-album:first-child {
    margin-left: 123px; }
  .link-album:last-child {
    margin-right: 123px; }
  .img-album {
    height: 407px; }
  .desc-album {
    margin-top: 21px; }
  .title-album {
    font-size: 28px;
    line-height: 28px; }
  .title-album p:after {
    margin-left: -0.28em;
    height: 10px;
    bottom: 2px; } }

@media only screen and (max-width: 1600px) {
  .list-wr-albums {
    height: 432px; }
  .link-album {
    width: 357px;
    margin-right: 30px; }
  .img-album {
    height: 357px; }
  .title-album {
    font-size: 25px;
    line-height: 25px;
    letter-spacing: .25em;
    margin-right: -.25em; } }

@media only screen and (max-width: 1440px) {
  .list-wr-albums:before, .list-wr-albums:after {
    width: 60px; }
  .title-scn-albums {
    padding: 0 80px; }
  .title-scn-albums h1 {
    font-size: 44px;
    margin: 15px 0; }
  .list-wr-albums {
    height: 378px; }
  .link-album {
    width: 317px;
    margin-right: 24px; }
  .link-album:first-child {
    margin-left: 83px; }
  .link-album:last-child {
    margin-right: 83px; }
  .img-album {
    height: 317px; }
  .desc-album {
    margin-top: 15px; }
  .title-album {
    font-size: 23px;
    line-height: 23px; }
  .release-date-album {
    font-size: 12px; } }

@media only screen and (max-width: 1366px) {
  .list-wr-albums {
    height: 316px; }
  .link-album {
    width: 257px; }
  .img-album {
    height: 257px; }
  .title-album {
    font-size: 21px;
    line-height: 21px;
    letter-spacing: .2em;
    margin-right: -.2em; } }

/* RESPONSIVE ALBUMS: TABLET */
@media only screen and (max-device-width: 1366px) {
  html.mobile-platform body[data-page=albums] {
    height: auto;
    overflow-y: auto; }
  html.mobile-platform .albums-cnt {
    z-index: 1;
    background: none;
    padding: 116px 30px 0 30px;
    min-height: 100vh; }
  html.mobile-platform .list-wr-albums:before, html.mobile-platform .list-wr-albums:after {
    display: none; }
  html.mobile-platform .scn-albums-ds {
    height: auto; }
  html.mobile-platform .title-scn-albums {
    padding: 0;
    margin: 23px 0 -4.5vw 0;
    white-space: nowrap; }
  html.mobile-platform .title-scn-albums h1 {
    width: 100%;
    margin: 0 -.25em 0 0;
    color: rgba(255, 255, 255, 0.1);
    font-size: 11.71vw;
    text-align: center; }
  html.mobile-platform .list-wr-albums {
    height: auto; }
  html.mobile-platform .inner-albums-ds {
    width: 100%;
    position: relative;
    left: inherit;
    top: inherit;
    height: inherit;
    white-space: inherit;
    overflow: inherit;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: center;
    justify-content: center; }
  html.mobile-platform .link-album {
    width: 38%;
    margin: 0 3.5% 81px 3.5%; }
  html.mobile-platform .link-album:first-child {
    margin-left: 3.5%; }
  html.mobile-platform .link-album:last-child {
    margin-right: 3.5%; }
  html.mobile-platform .img-album {
    height: 0;
    padding-bottom: 100%; }
  html.mobile-platform .img-album img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto; }
  html.mobile-platform .desc-album {
    margin-top: 24px; }
  html.mobile-platform .title-album {
    font-size: 32px;
    line-height: 37px;
    letter-spacing: .3em;
    margin-right: -.3em;
    overflow: hidden; }
  html.mobile-platform .title-album p {
    overflow: inherit;
    white-space: normal; }
  html.mobile-platform .release-date-album {
    font-size: 17px;
    margin-top: -3px;
    color: rgba(255, 255, 255, 0.25); } }

@media only screen and (max-device-width: 1280px) {
  html.mobile-platform .link-album {
    width: 35%;
    margin-bottom: 61px; }
  html.mobile-platform .title-album {
    font-size: 30px;
    line-height: 35px; }
  html.mobile-platform .release-date-album {
    font-size: 16px; } }

@media only screen and (max-device-width: 1024px) {
  html.mobile-platform .link-album {
    width: 40%;
    margin-bottom: 41px; }
  html.mobile-platform .title-album {
    font-size: 28px;
    line-height: 34px; }
  html.mobile-platform .release-date-album {
    font-size: 16px;
    margin-top: -5px; } }

@media only screen and (max-device-width: 800px) {
  html.mobile-platform .link-album {
    width: 100%;
    margin: 0 0 81px 0; }
  html.mobile-platform .link-album:first-child {
    margin-left: 0; }
  html.mobile-platform .link-album:last-child {
    margin-right: 0; }
  html.mobile-platform .title-scn-albums {
    margin: 23px 0 -3vw 0; }
  html.mobile-platform .title-scn-albums h1 {
    font-size: 13vw;
    line-height: 13vw; }
  html.mobile-platform .title-album {
    font-size: 46px;
    line-height: 52px; }
  html.mobile-platform .release-date-album {
    font-size: 22px; } }

@media only screen and (max-device-width: 640px) {
  html.mobile-platform .title-scn-albums {
    margin: 10px 0 -3vw 0; }
  html.mobile-platform .link-album {
    margin: 0 0 61px 0; }
  html.mobile-platform .title-album {
    font-size: 35px;
    line-height: 39px; }
  html.mobile-platform .release-date-album {
    font-size: 17px; } }

/* RESPONSIVE ALBUMS: MOBILE */
@media only screen and (max-width: 740px) and (max-height: 425px) and (orientation: landscape) {
  html.mobile-platform .albums-cnt {
    padding: 0 20px; }
  html.mobile-platform .title-scn-albums {
    margin: 13px 0 -3vw 0; }
  html.mobile-platform .title-scn-albums h1 {
    font-size: 11vw;
    line-height: 11vw; }
  html.mobile-platform .inner-albums-ds {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  html.mobile-platform .link-album {
    width: 40%;
    margin: 0 0 31px 0; }
  html.mobile-platform .link-album:first-child {
    margin-left: 0; }
  html.mobile-platform .link-album:last-child {
    margin-right: 0; }
  html.mobile-platform .desc-album {
    margin-top: 10px;
    padding: 0; }
  html.mobile-platform .title-album {
    font-size: 21px;
    line-height: 24px; }
  html.mobile-platform .release-date-album {
    font-size: 12px; } }

@media only screen and (max-width: 425px) {
  html.mobile-platform .albums-cnt {
    padding: 75px 20px 0 20px; }
  html.mobile-platform .title-scn-albums {
    margin: 13px 0 -3vw 0; }
  html.mobile-platform .link-album {
    margin: 0 0 35px 0; }
  html.mobile-platform .link-album:last-child {
    margin-bottom: 41px; }
  html.mobile-platform .desc-album {
    margin-top: 10px;
    padding: 0; }
  html.mobile-platform .title-album {
    font-size: 25px;
    line-height: 28px; }
  html.mobile-platform .release-date-album {
    font-size: 14px; } }

@media only screen and (max-width: 320px) {
  html.mobile-platform .title-album {
    font-size: 22px;
    line-height: 24px; }
  html.mobile-platform .release-date-album {
    font-size: 13px; }
  html.mobile-platform .title-scn-albums {
    margin: 13px 0 -2.25vw 0; }
  html.mobile-platform .title-scn-albums h1 {
    font-size: 14vw;
    line-height: 14vw; } }

@media only screen and (max-device-width: 570px) and (max-device-height: 320px) and (orientation: landscape) {
  html.mobile-platform .link-album {
    width: 42%; }
  html.mobile-platform .title-album {
    font-size: 18px;
    line-height: 22px; }
  html.mobile-platform .release-date-album {
    font-size: 10px; } }

/* CONCERTS */
.concerts-ctn .scrollbar-track {
  background: none !important; }

.concerts-ctn .scrollbar-thumb {
  background: rgba(255, 255, 255, 0.23); }

.concerts-ctn .scrollbar-track, .concerts-ctn .scrollbar-thumb {
  width: 6px; }

.concerts-ctn {
  z-index: 1; }

.scene-sc-v, .img-sc-v {
  position: absolute; }

.concerts-ctn:before,
.concerts-ctn:after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  pointer-events: none; }

.concerts-ctn:before {
  top: 0;
  height: 240px;
  background: linear-gradient(to bottom, black 0%, transparent 100%); }

.concerts-ctn:after {
  bottom: 0;
  height: 170px;
  background: linear-gradient(to bottom, transparent 0%, black 100%); }

.scene-sc-v {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 0; }

.sc-mn-photo {
  width: 120% !important;
  height: 120% !important;
  left: -10% !important;
  top: -10% !important;
  background: url("imgs/concerts-hm.jpg") no-repeat center;
  background-size: cover; }

.sc-all-if,
.ind-sc-alif {
  width: 100%;
  position: relative;
  overflow: hidden; }

.sc-all-if {
  height: 100%; }

.inner-abs-sc-if {
  width: 100%;
  position: relative;
  padding: 23vw 0 85px 0; }

.inner-sc-bk {
  width: 100%;
  height: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  overflow: hidden; }

.cend-sc-bk {
  width: 956px; }

.all-concerts {
  width: 100%; }

.inner-concerts {
  width: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center; }

.inner-concerts h1,
.inner-concerts h2 {
  font-family: 'ACBoucle', sans-serif;
  color: white;
  text-transform: uppercase;
  letter-spacing: .265em;
  margin: 0 -.265em 0 0;
  white-space: nowrap;
  cursor: default;
  text-align: center; }

.inner-concerts h1 {
  font-size: 73px;
  line-height: 73px; }

.inner-concerts h2 {
  font-size: 50px;
  line-height: 50px; }

.sc-tbl-fl {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center; }

.future-concerts .sc-tbl-fl {
  margin: 36px 0; }

.former-concerts .sc-tbl-fl {
  margin-top: 12px; }

.inner-concerts:nth-child(2) .sc-tbl-fl {
  margin-bottom: 0; }

.one-sc-cc {
  width: 100%;
  color: white;
  min-height: 80px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 24px 33px;
  margin-bottom: 6px;
  position: relative;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.66);
  transition: background 0.2s cubic-bezier(0.22, 0.61, 0.36, 1); }

.one-sc-cc b, .one-sc-cc strong {
  font-weight: normal; }

.lft-one-sc-cc,
.rgt-one-sc-cc {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.lft-one-sc-cc {
  width: 550px; }

.rgt-one-sc-cc {
  width: 230px;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.lft-one-sc-cc p {
  width: auto;
  display: inline; }

html:not(.mobile-platform) .one-sc-cc:not(.not-sale):hover {
  background: #FD1821; }

.date-concert {
  width: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-family: 'ACBoucle', sans-serif; }

.day-concert {
  font-size: 40px;
  line-height: 40px;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: .12em;
  margin-right: 7px; }

.month-year-concert {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-top: 3px; }

.month-concert {
  font-size: 17px;
  line-height: 17px; }

.year-concert {
  font-size: 15px;
  line-height: 15px; }

.month-concert, .year-concert {
  text-transform: uppercase;
  letter-spacing: .32em; }

.default-link-sc {
  font: 15px 'ACBoucle', sans-serif;
  text-transform: uppercase;
  letter-spacing: .32em;
  margin-right: -.32em;
  position: relative; }

.default-link-sc a {
  position: relative;
  color: white;
  text-decoration: none; }

.default-link-sc a:before,
.default-link-sc a:after {
  content: '';
  position: absolute;
  height: 1px;
  background: white;
  bottom: -1px; }

.default-link-sc a:before {
  right: 0;
  width: 100%;
  margin-right: .16em; }

.default-link-sc a:after {
  left: 0;
  width: 0;
  margin-left: -.16em; }

html:not(.mobile-platform) .default-link-sc a:hover:before,
html:not(.mobile-platform) .default-link-sc a:hover:after {
  transition: width 300ms cubic-bezier(0.4, 0, 0, 1); }

html:not(.mobile-platform) .default-link-sc a:hover:before {
  width: 0; }

html:not(.mobile-platform) .default-link-sc a:hover:after {
  width: 100%;
  transition-delay: 200ms; }

.location-concert {
  display: -ms-flexbox;
  display: flex;
  width: 350px;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  text-transform: uppercase;
  overflow: hidden; }

.clubname-cct {
  width: 100%;
  white-space: inherit;
  overflow: inherit;
  margin-right: -.32em;
  letter-spacing: .32em;
  text-overflow: ellipsis;
  font: 18px 'ACBoucle', sans-serif; }

.cityname-cct {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.47);
  letter-spacing: .15em;
  margin-right: -.15em;
  transition: color 0.2s cubic-bezier(0.22, 0.61, 0.36, 1); }

html:not(.mobile-platform) .one-sc-cc:not(.not-sale):hover .cityname-cct {
  color: rgba(255, 255, 255, 0.67); }

.band-icon-cct,
.icon-hb {
  width: 33px;
  height: 33px;
  background: url("imgs/svg/band-icon.svg") no-repeat center;
  background-size: 100% 100%; }

.band-icon-cct {
  display: none; }

.one-sc-cc.with-band .band-icon-cct {
  display: -ms-flexbox;
  display: flex; }

.hint-band-cct {
  width: 100%;
  min-height: 80px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  padding: 24px 33px; }

.hint-band-cct b, .hint-band-cct strong {
  font-weight: normal; }

.desc-hint-band {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  letter-spacing: .04em; }

.icon-hb {
  opacity: .4; }

.desc-hint-band span {
  margin: 0 15px; }

.desc-hint-band p {
  display: inline;
  width: auto; }

/* ANIMATION SINGLE CONCERTS */
html:not(.mobile-platform) .cend-sc-bk.elem-anim h1,
html:not(.mobile-platform) .cend-sc-bk.elem-anim h2,
html:not(.mobile-platform) .cend-sc-bk.elem-anim .inner-concerts .sc-tbl-fl,
html:not(.mobile-platform) .hint-band-cct.elem-anim {
  opacity: 0;
  transform: translateY(30vh);
  transition: all 800ms cubic-bezier(0.4, 0, 0.2, 1); }

html:not(.mobile-platform) .concerts-ctn .scrollbar-track {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

html:not(.mobile-platform) .cend-sc-bk.elem-anim .future-concerts .sc-tbl-fl {
  transition-delay: 200ms; }

html:not(.mobile-platform) .cend-sc-bk.elem-anim h2 {
  transition-delay: 400ms; }

html:not(.mobile-platform) .cend-sc-bk.elem-anim .former-concerts .sc-tbl-fl {
  transition-delay: 600ms; }

html:not(.mobile-platform) .hint-band-cct.elem-anim.done {
  transition-delay: 800ms; }

html:not(.mobile-platform) .concerts-ctn .scrollbar-track {
  transition-delay: 1000ms; }

html:not(.mobile-platform) .cend-sc-bk.elem-anim.done h1,
html:not(.mobile-platform) .cend-sc-bk.elem-anim.done h2,
html:not(.mobile-platform) .cend-sc-bk.elem-anim.done .inner-concerts .sc-tbl-fl,
html:not(.mobile-platform) .hint-band-cct.elem-anim.done {
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0); }

html:not(.mobile-platform) .concerts-ctn.done .scrollbar-track {
  opacity: 1; }

/* RESPONSIVE CONCERTS: DESCTOP */
@media only screen and (max-width: 1680px) {
  .inner-abs-sc-if {
    padding: 26vw 0 85px; }
  .cend-sc-bk {
    width: 864px; }
  .inner-concerts h1 {
    font-size: 66px;
    line-height: 66px; }
  .inner-concerts h2 {
    font-size: 45px;
    line-height: 45px; }
  .one-sc-cc, .hint-band-cct {
    min-height: 76px;
    padding: 20px 26px; }
  .rgt-one-sc-cc {
    width: 180px; }
  .day-concert {
    font-size: 36px;
    line-height: 36px;
    margin-right: 3px; }
  .month-concert {
    font-size: 15px;
    line-height: 15px; }
  .year-concert {
    font-size: 14px;
    line-height: 14px; }
  .clubname-cct {
    font-size: 16px;
    line-height: 18px; }
  .cityname-cct {
    font-size: 11px; }
  .band-icon-cct, .icon-hb {
    width: 26px;
    height: 26px; }
  .default-link-sc {
    font-size: 13px; }
  .desc-hint-band {
    font-size: 13px; } }

@media only screen and (max-width: 1600px) {
  .inner-abs-sc-if {
    padding: 22vw 0 85px; } }

@media only screen and (max-width: 1440px) {
  .lft-one-sc-cc {
    width: 500px; }
  .location-concert {
    width: 310px; }
  .concerts-ctn:before {
    height: 180px; }
  .concerts-ctn:after {
    height: 140px; }
  .inner-abs-sc-if {
    padding: 25vw 0 70px; }
  .cend-sc-bk {
    width: 786px; }
  .inner-concerts h1 {
    font-size: 60px;
    line-height: 60px; }
  .inner-concerts h2 {
    font-size: 40px;
    line-height: 40px; }
  .future-concerts .sc-tbl-fl {
    margin: 25px 0; }
  .former-concerts .sc-tbl-fl {
    margin-top: 10px; }
  .one-sc-cc, .hint-band-cct {
    min-height: 70px;
    padding: 18px 24px; }
  .day-concert {
    font-size: 34px;
    line-height: 34px; }
  .month-year-concert {
    padding-top: 2px; }
  .month-concert {
    font-size: 14px;
    line-height: 14px; }
  .year-concert {
    font-size: 13px;
    line-height: 14px; }
  .clubname-cct {
    font-size: 15px;
    margin-bottom: 2px; }
  .cityname-cct {
    font-size: 10px;
    line-height: 12px; }
  .band-icon-cct, .icon-hb {
    width: 24px;
    height: 24px; }
  .default-link-sc {
    font-size: 12px; }
  .desc-hint-band span {
    margin: 0 10px; } }

@media only screen and (max-width: 1366px) {
  .inner-abs-sc-if {
    padding: 21vw 0 70px; } }

@media only screen and (max-width: 1280px) and (min-height: 890px) {
  .inner-abs-sc-if {
    padding: 32vw 0 70px; } }

/* RESPONSIVE CONCERTS: TABLET */
@media only screen and (max-device-width: 1366px) {
  html.mobile-platform body[data-page=schedule-concerts] {
    height: auto;
    overflow-y: auto; }
  html.mobile-platform .concerts-ctn {
    padding: 116px 30px 0 30px;
    min-height: 100vh; }
  html.mobile-platform .sc-mn-photo {
    background: url("imgs/concerts-hm-mp.jpg") no-repeat center;
    background-size: 100%;
    background-position-y: 105px; }
  html.mobile-platform .concerts-ctn:after,
  html.mobile-platform .concerts-ctn:before {
    display: none; }
  html.mobile-platform .inner-concerts:first-child .sc-tbl-fl {
    margin: 0 0 67px 0;
    padding-top: calc(38% + 60px); }
  html.mobile-platform .inner-concerts:last-child .sc-tbl-fl {
    margin-bottom: 0 !important; }
  html.mobile-platform .concerts-ctn:before,
  html.mobile-platform .sc-mn-photo:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 17%;
    left: 0; }
  html.mobile-platform .concerts-ctn:before {
    top: 116px;
    background: linear-gradient(to bottom, black 0%, transparent 100%); }
  html.mobile-platform .sc-mn-photo:after {
    bottom: -1px;
    background: linear-gradient(to bottom, transparent 0%, black 99%, black 100%); }
  html.mobile-platform .scene-sc-v {
    margin: 0;
    height: 125vw; }
  html.mobile-platform .sc-mn-photo {
    width: 124% !important;
    height: 100vw !important;
    left: -12% !important;
    top: 0 !important; }
  html.mobile-platform .inner-abs-sc-if {
    height: auto;
    position: relative;
    left: inherit;
    right: inherit;
    bottom: inherit;
    margin: 0;
    padding: 0; }
  html.mobile-platform .sc-all-if {
    z-index: 3; }
  html.mobile-platform .cend-sc-bk {
    width: 100%;
    margin-bottom: 44px; }
  html.mobile-platform .sc-all-if,
  html.mobile-platform .ind-sc-alif,
  html.mobile-platform .inner-sc-bk {
    overflow: inherit; }
  html.mobile-platform .inner-concerts h1,
  html.mobile-platform .inner-concerts h2 {
    text-align: center;
    color: rgba(255, 255, 255, 0.1);
    white-space: normal;
    line-height: .863;
    letter-spacing: .25em;
    margin-right: -.25em; }
  html.mobile-platform .former-concerts .sc-tbl-fl {
    margin-top: 30px; }
  html.mobile-platform .inner-concerts:first-child h1,
  html.mobile-platform .inner-concerts:first-child h2 {
    margin-top: 37px;
    position: absolute;
    left: 0;
    top: 0; }
  html.mobile-platform .inner-concerts:nth-child(2) h1,
  html.mobile-platform .inner-concerts:nth-child(2) h2 {
    margin-top: 20px; }
  html.mobile-platform .inner-concerts h1,
  html.mobile-platform .inner-concerts h2 {
    font-size: 11.71vw; }
  html.mobile-platform .inner-concerts h2 {
    width: 100%; }
  html.mobile-platform .inner-concerts h1 span {
    width: 100%;
    display: inline-block; }
  html.mobile-platform .inner-concerts h1 span:last-child {
    padding-left: 0; }
  html.mobile-platform .one-sc-cc {
    min-height: 129px;
    margin-bottom: 22px;
    background: rgba(13, 15, 17, 0.8); }
  html.mobile-platform .former-concerts .one-sc-cc,
  html.mobile-platform .cityname-cct {
    color: rgba(136, 136, 136, 0.8); }
  html.mobile-platform .one-sc-cc,
  html.mobile-platform .hint-band-cct {
    padding: 37px 48px; }
  html.mobile-platform .lft-one-sc-cc {
    width: calc(100% - 88px);
    -ms-flex-pack: start;
    justify-content: flex-start; }
  html.mobile-platform .location-concert {
    width: calc(100% - 225px);
    margin: -3px 0 0 0; }
  html.mobile-platform .rgt-one-sc-cc {
    width: 44px; }
  html.mobile-platform .date-concert {
    width: 225px; }
  html.mobile-platform .day-concert {
    font-size: 49px;
    line-height: 49px;
    margin-right: 16px; }
  html.mobile-platform .month-concert {
    font-size: 20px;
    line-height: 23px; }
  html.mobile-platform .year-concert {
    font-size: 18px;
    line-height: 19px; }
  html.mobile-platform .month-concert,
  html.mobile-platform .year-concert {
    letter-spacing: .35em; }
  html.mobile-platform .clubname-cct {
    font-size: 35px;
    letter-spacing: .25em;
    line-height: 38px;
    margin-bottom: 3px; }
  html.mobile-platform .cityname-cct {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: .05em; }
  html.mobile-platform .default-link-sc {
    font-size: 0 !important; }
  html.mobile-platform .default-link-sc a:before,
  html.mobile-platform .default-link-sc a:after {
    display: none; }
  html.mobile-platform .default-link-sc,
  html.mobile-platform .default-link-sc a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }
  html.mobile-platform .band-icon-cct {
    width: 44px;
    height: 45px; }
  html.mobile-platform .icon-hb {
    width: 56px;
    height: 57px;
    opacity: .25; }
  html.mobile-platform .desc-hint-band {
    font-size: 22px;
    letter-spacing: .05em;
    color: rgba(255, 255, 255, 0.25); }
  html.mobile-platform .desc-hint-band span {
    margin: 0 9px 0 17px; } }

@media only screen and (max-device-width: 1024px) {
  html.mobile-platform .inner-concerts:first-child .sc-tbl-fl {
    padding-top: calc(37% + 60px); }
  html.mobile-platform .one-sc-cc {
    min-height: 192px; }
  html.mobile-platform .lft-one-sc-cc {
    width: calc(100% - 88px);
    -ms-flex-direction: column;
    flex-direction: column; }
  html.mobile-platform .location-concert {
    width: 100%;
    margin: 8px 0 0 0; }
  html.mobile-platform .date-concert {
    width: auto; }
  html.mobile-platform .clubname-cct {
    font-size: 30px;
    line-height: 33px;
    letter-spacing: .25em;
    margin-bottom: 8px; } }

@media only screen and (max-device-width: 800px) {
  html.mobile-platform .inner-concerts h1 {
    margin: 37px -.25em 0 0; }
  html.mobile-platform .former-concerts .sc-tbl-fl {
    margin-top: 20px; }
  html.mobile-platform .inner-concerts h1,
  html.mobile-platform .inner-concerts h2 {
    font-size: 13vw;
    text-align: center; }
  html.mobile-platform .sc-mn-photo {
    background-size: 140%;
    background-position-y: 135px; }
  html.mobile-platform .inner-concerts:first-child .sc-tbl-fl {
    padding-top: calc(57% + 60px); } }

@media only screen and (max-device-width: 640px) {
  html.mobile-platform .cend-sc-bk {
    margin-bottom: 14px; }
  html.mobile-platform .inner-concerts:first-child .sc-tbl-fl {
    margin: 0 0 47px 0; }
  html.mobile-platform .inner-concerts:first-child h1,
  html.mobile-platform .inner-concerts:first-child h2 {
    margin-top: 31px; }
  html.mobile-platform .one-sc-cc,
  html.mobile-platform .hint-band-cct {
    padding: 20px 26px; }
  html.mobile-platform .one-sc-cc {
    min-height: 137px;
    margin-bottom: 14px; }
  html.mobile-platform .lft-one-sc-cc {
    width: calc(100% - 68px); }
  html.mobile-platform .day-concert {
    font-size: 41px;
    line-height: 41px;
    margin-right: 10px; }
  html.mobile-platform .month-concert {
    font-size: 18px;
    line-height: 18px; }
  html.mobile-platform .year-concert {
    font-size: 15px;
    line-height: 17px; }
  html.mobile-platform .clubname-cct {
    font-size: 24px;
    line-height: 28px; }
  html.mobile-platform .cityname-cct {
    font-size: 13px;
    line-height: 13px; }
  html.mobile-platform .rgt-one-sc-cc {
    width: 34px; }
  html.mobile-platform .band-icon-cct {
    width: 34px;
    height: 35px; }
  html.mobile-platform .icon-hb {
    width: 46px;
    height: 47px; }
  html.mobile-platform .desc-hint-band {
    font-size: 18px; }
  html.mobile-platform .desc-hint-band span {
    margin: 0 6px 0 14px; } }

/* RESPONSIVE CONCERTS: MOBILE */
@media only screen and (max-width: 740px) and (max-height: 425px) and (orientation: landscape) {
  html.mobile-platform .concerts-ctn {
    padding: 0 20px 0 20px;
    min-height: calc(100vh - 75px); }
  html.mobile-platform .concerts-ctn:before {
    top: 0; }
  html.mobile-platform .former-concerts .sc-tbl-fl {
    margin-top: 10px; }
  html.mobile-platform .inner-concerts h1 {
    line-height: .93;
    margin-left: 0;
    margin-right: -.25em;
    text-align: center; }
  html.mobile-platform .inner-concerts h1,
  html.mobile-platform .inner-concerts h2 {
    font-size: 11vw; }
  html.mobile-platform .inner-concerts:first-child h1,
  html.mobile-platform .inner-concerts:first-child h2 {
    margin-top: 26px; }
  html.mobile-platform .inner-concerts h1 span:last-child {
    padding-left: 0; }
  html.mobile-platform .inner-concerts:first-child .sc-tbl-fl {
    margin: 0 0 37px 0;
    padding-top: calc(40.5% + 40px); }
  html.mobile-platform .sc-mn-photo {
    background-size: 105%;
    background-position-y: 0;
    left: -14% !important; }
  html.mobile-platform .cend-sc-bk {
    margin-bottom: 7px; }
  html.mobile-platform .one-sc-cc {
    padding: 20px 20px; }
  html.mobile-platform .hint-band-cct {
    padding: 20px 10px; }
  html.mobile-platform .one-sc-cc {
    min-height: 71px;
    margin-bottom: 7px; }
  html.mobile-platform .lft-one-sc-cc {
    -ms-flex-direction: row;
    flex-direction: row;
    width: calc(100% - 40px); }
  html.mobile-platform .date-concert {
    width: 160px; }
  html.mobile-platform .day-concert {
    font-size: 31px;
    line-height: 31px;
    margin-right: 8px; }
  html.mobile-platform .month-concert {
    font-size: 13px;
    line-height: 13px; }
  html.mobile-platform .year-concert {
    font-size: 11px;
    line-height: 13px; }
  html.mobile-platform .location-concert {
    margin: 0;
    width: calc(100% - 160px); }
  html.mobile-platform .clubname-cct {
    font-size: 17px;
    line-height: 19px;
    margin: 0 0 2px 0; }
  html.mobile-platform .cityname-cct {
    font-size: 10px;
    line-height: 10px; }
  html.mobile-platform .rgt-one-sc-cc {
    width: 20px; }
  html.mobile-platform .band-icon-cct {
    width: 20px;
    height: 21px; }
  html.mobile-platform .icon-hb {
    width: 26px;
    height: 27px; }
  html.mobile-platform .desc-hint-band span {
    margin: 0 5px 0 10px; }
  html.mobile-platform .desc-hint-band {
    font-size: 12px; } }

@media only screen and (max-width: 425px) {
  html.mobile-platform .concerts-ctn {
    padding: 75px 20px 0 20px; }
  html.mobile-platform .concerts-ctn:before {
    top: 75px; }
  html.mobile-platform .inner-concerts h1 {
    line-height: .93; }
  html.mobile-platform .inner-concerts h1,
  html.mobile-platform .inner-concerts h2 {
    font-size: 12vw; }
  html.mobile-platform .inner-concerts:first-child h1,
  html.mobile-platform .inner-concerts:first-child h2 {
    margin-top: 22px; }
  html.mobile-platform .former-concerts .sc-tbl-fl {
    margin-top: 10px; }
  html.mobile-platform .sc-mn-photo {
    height: 125vw !important;
    background-size: 180%;
    background-position-y: 68px;
    left: -18% !important; }
  html.mobile-platform .inner-concerts:first-child .sc-tbl-fl {
    padding-top: calc(70% + 40px);
    margin: 0 0 37px 0; }
  html.mobile-platform .cend-sc-bk {
    margin-bottom: 7px; }
  html.mobile-platform .one-sc-cc {
    padding: 20px 20px; }
  html.mobile-platform .hint-band-cct {
    padding: 20px 0; }
  html.mobile-platform .one-sc-cc {
    min-height: 107px;
    margin-bottom: 7px; }
  html.mobile-platform .lft-one-sc-cc {
    width: calc(100% - 40px); }
  html.mobile-platform .day-concert {
    font-size: 31px;
    line-height: 31px;
    margin-right: 8px; }
  html.mobile-platform .month-concert {
    font-size: 13px;
    line-height: 13px; }
  html.mobile-platform .year-concert {
    font-size: 11px;
    line-height: 13px; }
  html.mobile-platform .location-concert {
    margin: 6px 0 0 0; }
  html.mobile-platform .clubname-cct {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 6px; }
  html.mobile-platform .cityname-cct {
    font-size: 9px;
    line-height: 10px; }
  html.mobile-platform .rgt-one-sc-cc {
    width: 20px; }
  html.mobile-platform .band-icon-cct {
    width: 20px;
    height: 21px; }
  html.mobile-platform .icon-hb {
    width: 26px;
    height: 27px; }
  html.mobile-platform .desc-hint-band span {
    margin: 0 8px; }
  html.mobile-platform .desc-hint-band {
    font-size: 12px; } }

@media only screen and (max-width: 570px) and (max-height: 320px) and (orientation: landscape) {
  html.mobile-platform .date-concert {
    width: 140px; }
  html.mobile-platform .location-concert {
    width: calc(100% - 140px); } }

/* CONTACTS */
.contacts-ctn {
  z-index: 1; }

.contacts-ctn:before,
.contacts-ctn:after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  pointer-events: none; }

.contacts-ctn:before {
  top: 0;
  height: 240px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, transparent 100%); }

.contacts-ctn:after {
  bottom: 0;
  height: 170px;
  background: linear-gradient(to bottom, transparent 0%, black 100%); }

.aft-cmn:after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: none;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, transparent 100%); }

.scene-c-v, .img-c-v {
  position: absolute; }

.scene-c-v {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 0; }

.c-mn-photo {
  width: 120% !important;
  height: 120% !important;
  left: -10% !important;
  top: -10% !important;
  background: url("imgs/contacts-hm.png") no-repeat center;
  background-size: cover; }

.ind-cns-ctn {
  width: 100%;
  height: 100%;
  padding: 180px 0 85px 180px;
  position: relative;
  overflow: hidden; }

.inner-cns-ctn {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative; }

.contacts-ctn .scrollbar-track {
  background: none !important; }

.contacts-ctn .scrollbar-thumb {
  background: rgba(255, 255, 255, 0.31); }

.contacts-ctn .scrollbar-track, .contacts-ctn .scrollbar-thumb {
  width: 6px; }

.insd-cns-sl {
  width: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start; }

.inf-cns-ctn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 30px; }

.inf-cns-ctn h1 {
  width: 100%;
  font: 70px 'ACBoucle', sans-serif;
  line-height: 70px;
  color: white;
  letter-spacing: .25em;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  padding: 0;
  cursor: default; }

.inf-cns-ctn p,
.inf-cns-ctn ul li,
.inf-cns-ctn ol li {
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: .05em;
  color: white;
  cursor: text; }

.inf-cns-ctn p, .inf-cns-ctn ul, .inf-cns-ctn ol {
  margin: 10px 0; }

.inf-cns-ctn a {
  color: #7b6c88;
  text-decoration: none;
  transition: color 400ms cubic-bezier(0.22, 0.61, 0.36, 1); }

.inf-cns-ctn a:hover {
  color: #FD1821; }

.inf-cns-ctn a[href^='tel'], .inf-cns-ctn a[href^='mailto'] {
  white-space: nowrap; }

.inf-cns-ctn ul, .inf-cns-ctn ol {
  width: 100%; }

.inf-cns-ctn ul {
  padding: 0 0 0 17px; }

.inf-cns-ctn ol {
  padding: 0 0 0 19px; }

.inf-cns-ctn ul li {
  width: 100%; }

.inf-cns-ctn, .links-cns-ctn {
  width: 700px; }

.one-cns-lnk {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 40px;
  overflow: hidden; }

.inner-ocl {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.one-cns-lnk h2 {
  width: 100%;
  font: 16px 'ACBoucle', sans-serif;
  text-transform: uppercase;
  color: #7b6c88;
  letter-spacing: .25em;
  margin: 0 0 20px 0;
  line-height: 1.4;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.one-cns-lnk p {
  margin: 0; }

.one-cns-lnk a {
  width: auto;
  max-width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  position: relative;
  transition: all 0.4s cubic-bezier(0.22, 0.61, 0.36, 1); }

.one-cns-lnk b, .one-cns-lnk strong {
  font-weight: normal; }

.one-cns-lnk p:last-child {
  margin-top: 2px; }

html:not(.mobile-platform) .one-cns-lnk a:hover {
  color: #FD1821 !important; }

.one-cns-lnk a[href^='tel'] {
  color: white;
  font: 30px 'ACBoucle', sans-serif;
  letter-spacing: .1em;
  line-height: 31px; }

.one-cns-lnk a:not([href^='tel']) {
  font-size: 16px;
  color: #7b6c88;
  letter-spacing: .05em;
  line-height: 26px; }

/* ANIMATION CONTACTS */
html:not(.mobile-platform) .elem-anim.inf-cns-ctn h1,
html:not(.mobile-platform) .links-cns-ctn {
  opacity: 0;
  transform: translateY(30vh);
  transition: all 800ms cubic-bezier(0.4, 0, 0.2, 1); }

html:not(.mobile-platform) .contacts-ctn .scrollbar-track {
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

html:not(.mobile-platform) .links-cns-ctn.elem-anim {
  transition-delay: 300ms; }

html:not(.mobile-platform) .contacts-ctn .scrollbar-track {
  transition-delay: 500ms; }

html:not(.mobile-platform) .elem-anim.inf-cns-ctn.done h1,
html:not(.mobile-platform) .links-cns-ctn.elem-anim.done {
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0); }

html:not(.mobile-platform) .contacts-ctn.done .scrollbar-track {
  opacity: 1;
  visibility: visible; }

/* RESPONSIVE CONTACTS: DESCTOP */
@media only screen and (max-width: 1680px) {
  .ind-cns-ctn {
    padding: 185px 0 85px 120px; }
  .inf-cns-ctn h1 {
    font-size: 50px;
    line-height: 50px;
    margin: 0 0 15px 0; }
  .inf-cns-ctn, .links-cns-ctn {
    width: 600px; }
  .one-cns-lnk h2 {
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 1.35; }
  .one-cns-lnk a[href^='tel'] {
    font-size: 26px;
    line-height: 27px; }
  .one-cns-lnk a:not([href^='tel']) {
    font-size: 15px;
    line-height: 24px; }
  .one-cns-lnk {
    margin-bottom: 35px; }
  .inf-cns-ctn ol {
    padding: 0 0 0 18px; }
  .inf-cns-ctn ul {
    padding: 0 0 0 16px; }
  .inf-cns-ctn p, .inf-cns-ctn ul, .inf-cns-ctn ol {
    margin: 5px 0; }
  .inf-cns-ctn p, .inf-cns-ctn ul li, .inf-cns-ctn ol li {
    font-size: 15px;
    line-height: 24px; } }

@media only screen and (max-width: 1600px) and (min-height: 1050px) {
  .c-mn-photo {
    left: -15% !important; } }

@media only screen and (max-width: 1440px) {
  .contacts-ctn:before {
    height: 180px; }
  .contacts-ctn:after {
    height: 140px; }
  .ind-cns-ctn {
    padding: 140px 0 70px 80px; }
  .inf-cns-ctn, .links-cns-ctn {
    width: 550px; }
  .inf-cns-ctn h1 {
    font-size: 44px;
    line-height: 44px;
    margin: 0 0 10px 0; }
  .one-cns-lnk {
    margin-bottom: 30px; }
  .one-cns-lnk h2 {
    font-size: 13px;
    margin-bottom: 13px; }
  .one-cns-lnk a[href^='tel'] {
    font-size: 21px;
    line-height: 22px; }
  .one-cns-lnk a:not([href^='tel']) {
    font-size: 13px;
    line-height: 16px; }
  .inf-cns-ctn ol {
    padding: 0 0 0 16px; }
  .inf-cns-ctn ul {
    padding: 0 0 0 15px; }
  .inf-cns-ctn p, .inf-cns-ctn ul li, .inf-cns-ctn ol li {
    font-size: 13px;
    line-height: 21px; } }

@media only screen and (max-width: 1280px) and (min-height: 890px) {
  .c-mn-photo {
    left: -15% !important; } }

/* RESPONSIVE CONTACTS: TABLET */
@media only screen and (max-device-width: 1366px) {
  html.mobile-platform body[data-page=contacts] {
    height: auto;
    overflow-y: auto; }
  html.mobile-platform .contacts-ctn:before,
  html.mobile-platform .contacts-ctn:after {
    display: none; }
  html.mobile-platform .contacts-ctn {
    min-height: 100vh; }
  html.mobile-platform .scene-c-v {
    display: none; }
  html.mobile-platform .ind-cns-ctn, html.mobile-platform .inner-cns-ctn {
    overflow: inherit; }
  html.mobile-platform .inf-cns-ctn,
  html.mobile-platform .links-cns-ctn {
    width: 100%; }
  html.mobile-platform .ind-cns-ctn {
    background: linear-gradient(to bottom, black 0%, rgba(54, 0, 47, 0.74) 60%, black 100%);
    padding: 116px 30px 0 30px; }
  html.mobile-platform .inf-cns-ctn h1 {
    font-size: 11.71vw;
    line-height: 11.71vw;
    margin: 35px -.12em 33px 0;
    text-align: center;
    color: rgba(255, 255, 255, 0.1); }
  html.mobile-platform .inf-cns-ctn ol {
    padding: 0 0 0 25px; }
  html.mobile-platform .inf-cns-ctn ul {
    padding: 0 0 0 22px; }
  html.mobile-platform .inf-cns-ctn p, html.mobile-platform .inf-cns-ctn ol, html.mobile-platform .inf-cns-ctn ul {
    margin: 14px 0; }
  html.mobile-platform .inf-cns-ctn p,
  html.mobile-platform .inf-cns-ctn ul li,
  html.mobile-platform .inf-cns-ctn ol li {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: .025em;
    color: rgba(255, 255, 255, 0.78); }
  html.mobile-platform .links-cns-ctn {
    margin: 37px 0 19px 0; }
  html.mobile-platform .one-cns-lnk {
    margin-bottom: 76px; }
  html.mobile-platform .one-cns-lnk h2 {
    font-size: 25px;
    margin-bottom: 28px; }
  html.mobile-platform .one-cns-lnk a[href^='tel'] {
    font-size: 35px;
    line-height: 36px; }
  html.mobile-platform .one-cns-lnk a:not([href^='tel']) {
    font-size: 20px;
    color: #7b6c88;
    line-height: 26px; } }

@media only screen and (max-device-width: 1024px) {
  html.mobile-platform .links-cns-ctn {
    margin: 46px 0 0 0; } }

@media only screen and (max-device-width: 800px) {
  html.mobile-platform .inf-cns-ctn h1 {
    font-size: 13vw;
    line-height: 13vw; }
  html.mobile-platform .one-cns-lnk h2 {
    font-size: 23px; } }

@media only screen and (max-device-width: 640px) {
  html.mobile-platform .inf-cns-ctn h1 {
    margin: 10px -.12em 23px 0; }
  html.mobile-platform .inf-cns-ctn ol {
    padding: 0 0 0 21px; }
  html.mobile-platform .inf-cns-ctn ul {
    padding: 0 0 0 19px; }
  html.mobile-platform .inf-cns-ctn p,
  html.mobile-platform .inf-cns-ctn ol,
  html.mobile-platform .inf-cns-ctn ul {
    margin: 14px 0; }
  html.mobile-platform .inf-cns-ctn p,
  html.mobile-platform .inf-cns-ctn ul li,
  html.mobile-platform .inf-cns-ctn ol li {
    font-size: 18px;
    line-height: 26px; }
  html.mobile-platform .links-cns-ctn {
    margin: 26px 0 0 0; }
  html.mobile-platform .one-cns-lnk {
    margin-bottom: 56px; }
  html.mobile-platform .one-cns-lnk h2 {
    font-size: 18px;
    margin-bottom: 21px; }
  html.mobile-platform .one-cns-lnk a[href^='tel'] {
    font-size: 28px;
    line-height: 30px; }
  html.mobile-platform .one-cns-lnk a:not([href^='tel']) {
    font-size: 16px;
    line-height: 22px; } }

/* RESPONSIVE CONTACTS: MOBILE */
@media only screen and (max-width: 740px) and (max-height: 425px) and (orientation: landscape) {
  html.mobile-platform .ind-cns-ctn {
    padding: 0 20px; }
  html.mobile-platform .inf-cns-ctn {
    margin: 13px 0 23px 0; }
  html.mobile-platform .inf-cns-ctn h1 {
    font-size: 11vw;
    line-height: 11vw;
    margin: 0 -.12em 23px 0; }
  html.mobile-platform .inf-cns-ctn ol,
  html.mobile-platform .inf-cns-ctn ul {
    padding: 0 0 0 16px; }
  html.mobile-platform .inf-cns-ctn p,
  html.mobile-platform .inf-cns-ctn ol,
  html.mobile-platform .inf-cns-ctn ul {
    margin: 8px 0; }
  html.mobile-platform .inf-cns-ctn p,
  html.mobile-platform .inf-cns-ctn ul li,
  html.mobile-platform .inf-cns-ctn ol li {
    font-size: 14px;
    line-height: 22px; }
  html.mobile-platform .links-cns-ctn {
    margin: 12px 0 0 0; }
  html.mobile-platform .one-cns-lnk {
    margin-bottom: 33px; }
  html.mobile-platform .one-cns-lnk h2 {
    font-size: 16px;
    margin-bottom: 18px;
    white-space: normal;
    overflow: auto; }
  html.mobile-platform .one-cns-lnk a[href^='tel'] {
    font-size: 24px;
    line-height: 25px; }
  html.mobile-platform .one-cns-lnk a:not([href^='tel']) {
    font-size: 14px; } }

@media only screen and (max-width: 425px) {
  html.mobile-platform .ind-cns-ctn {
    padding: 75px 20px 0 20px; }
  html.mobile-platform .inf-cns-ctn {
    margin: 13px 0 23px 0; }
  html.mobile-platform .inf-cns-ctn h1 {
    margin: 0 -.12em 23px 0; }
  html.mobile-platform .inf-cns-ctn ol,
  html.mobile-platform .inf-cns-ctn ul {
    padding: 0 0 0 16px; }
  html.mobile-platform .inf-cns-ctn p,
  html.mobile-platform .inf-cns-ctn ol,
  html.mobile-platform .inf-cns-ctn ul {
    margin: 8px 0; }
  html.mobile-platform .inf-cns-ctn p,
  html.mobile-platform .inf-cns-ctn ul li,
  html.mobile-platform .inf-cns-ctn ol li {
    font-size: 14px;
    line-height: 22px; }
  html.mobile-platform .links-cns-ctn {
    margin: 12px 0 16px 0; }
  html.mobile-platform .one-cns-lnk {
    margin-bottom: 33px; }
  html.mobile-platform .one-cns-lnk h2 {
    font-size: 16px;
    margin-bottom: 18px;
    white-space: normal;
    overflow: auto; }
  html.mobile-platform .one-cns-lnk a[href^='tel'] {
    font-size: 24px;
    line-height: 25px; }
  html.mobile-platform .one-cns-lnk a:not([href^='tel']) {
    font-size: 14px; } }

@media only screen and (max-width: 320px) {
  html.mobile-platform .inf-cns-ctn h1 {
    font-size: 14vw;
    line-height: 14vw; } }

/* HOME */
.scene-ctn {
  width: 100%;
  height: 100%;
  position: relative; }

.cover-hm {
  width: 120% !important;
  height: 120% !important;
  z-index: 0;
  left: -10% !important;
  top: -10% !important;
  position: absolute;
  pointer-events: none;
  background: url("imgs/home-hm.jpg") no-repeat center;
  background-size: cover; }

html:not(.ie) .cover-hm {
  animation: homeHmA 2.5s alternate infinite; }

@keyframes homeHmA {
  0% {
    -webkit-filter: brightness(1); }
  100% {
    -webkit-filter: brightness(2); } }

.home-ctn {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 0; }

.home-ctn:before,
.home-ctn:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 30vh;
  z-index: 5;
  left: 0;
  right: 0;
  margin: 0 auto;
  pointer-events: none; }

.home-ctn:before {
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, transparent 100%); }

.home-ctn:after {
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, transparent 100%); }

.cmm-hm {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; }

.img-hm {
  position: absolute;
  font-size: 0 !important; }

.layer {
  width: 100%;
  height: 100%; }

.circle-hm {
  width: 54.688vw;
  height: 54.688vw;
  top: 45% !important;
  left: 31% !important;
  background: url(imgs/circle-hm.png);
  z-index: 1;
  border-radius: 100%; }

.photo-hm {
  width: 51.9799vw;
  height: 41.667vw;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 4; }

.photo-hm:before,
.photo-hm:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto; }

.photo-hm:before {
  opacity: 0;
  background: url(imgs/pivovarov-glitch-hm.gif) no-repeat bottom;
  background-size: 100% 100%; }

.photo-hm:after {
  background: url(imgs/pivovarov-hm.png) no-repeat bottom;
  background-size: 100% 100%; }

.photo-hm.stop-glitch:before {
  opacity: 1; }

.photo-hm.stop-glitch:after {
  opacity: 0; }

.title-hm {
  width: 75%;
  height: 73.615%;
  left: 0;
  top: 1%;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 3;
  background: url(imgs/svg/title-hm.svg) no-repeat center;
  background-size: contain; }

.ball {
  position: absolute; }

.ball1 {
  width: 13.542vw;
  height: 14.792vw;
  left: 12%;
  top: 17%;
  background: url(imgs/ball1.png) no-repeat center;
  background-size: 100% 100%;
  animation: ballAnim1 2.5s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.ball2 {
  width: 16.459vw;
  height: 16.667vw;
  left: 45%;
  background: url(imgs/ball2.png) no-repeat center;
  background-size: 100% 100%;
  animation: ballAnim2 2.5s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.ball2, .ball3 {
  top: 0; }

.ball3 {
  width: 15.678vw;
  height: 18.855vw;
  right: 4%;
  background: url(imgs/ball3.png) no-repeat center;
  background-size: 100% 100%;
  animation: ballAnim3 2.5s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955); }

@keyframes ballAnim1 {
  0% {
    transform: translateX(10px) rotateZ(-2.5deg); }
  100% {
    transform: translateX(-10px) rotateZ(2.5deg); } }

@keyframes ballAnim2 {
  0% {
    transform: translateX(-7px) rotateZ(2deg); }
  100% {
    transform: translateX(7px) rotateZ(-2deg); } }

@keyframes ballAnim3 {
  0% {
    transform: translateX(5px) rotateZ(-1.25deg); }
  100% {
    transform: translateX(-5px) rotateZ(1.25deg); } }

/* RESPONSIVE HOME: DESCTOP */
@media only screen and (min-width: 2560px) and (min-height: 1050px) {
  .photo-hm {
    width: 57.292vw;
    height: 45.938vw; } }

@media only screen and (max-width: 1680px) and (min-height: 900px) {
  .photo-hm {
    width: 57.292vw;
    height: 45.938vw; } }

@media only screen and (max-width: 1600px) and (min-height: 1050px) {
  .photo-hm {
    width: 68.75vw;
    height: 55.125vw; } }

@media only screen and (max-width: 1440px) {
  .photo-hm {
    width: 57.292vw;
    height: 45.938vw; }
  .title-hm {
    width: 71%;
    height: 65.615%; } }

@media only screen and (max-width: 1366px) {
  .photo-hm {
    width: 47.585vw;
    height: 38.141vw; }
  .circle-hm {
    width: 51.688vw;
    height: 51.688vw;
    top: 47% !important;
    left: 32% !important; } }

@media only screen and (max-width: 1280px) and (min-height: 890px) {
  .title-hm {
    width: 88%;
    height: 63.615%; }
  .photo-hm {
    width: 68.75vw;
    height: 55.125vw; }
  .circle-hm {
    width: 60.688vw;
    height: 60.688vw;
    top: 51% !important;
    left: 26% !important; } }

/* RESPONSIVE HOME: TABLET */
@media only screen and (max-device-width: 1366px) {
  html.mobile-platform .photo-hm:before,
  html.mobile-platform .cover-hm,
  html.mobile-platform .circle-hm {
    display: none; }
  html.mobile-platform .home-ctn {
    background: url("imgs/home-hm-mp.jpg") no-repeat center;
    background-size: cover; }
  html.mobile-platform .cmm-hm {
    overflow: hidden; }
  html.mobile-platform .home-ctn:before {
    display: none; }
  html.mobile-platform .home-ctn:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 45%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    background: linear-gradient(to bottom, transparent 0%, black 100%); }
  html.mobile-platform .photo-hm {
    width: 120%;
    left: -10%; }
  html.mobile-platform .photo-hm:after {
    background: url(imgs/pivovarov-hm-mp.png) no-repeat bottom;
    background-size: contain; }
  html.mobile-platform .title-hm {
    width: 100%;
    z-index: 5;
    top: 21%; }
  html.mobile-platform .circle-hm {
    display: none; }
  html.mobile-platform .circle-mp {
    display: block; } }

@media only screen and (max-device-width: 1366px) and (max-device-height: 1024px) and (orientation: landscape) {
  html.mobile-platform .title-hm {
    height: 46%; }
  html.mobile-platform .photo-hm {
    height: 90vh; }
  html.mobile-platform .circle-mp {
    width: 60vw;
    height: 60vw;
    left: 7%;
    bottom: -18%; } }

@media only screen and (max-device-width: 1280px) and (max-device-height: 800px) and (orientation: landscape) {
  html.mobile-platform .circle-mp {
    width: 50vw;
    height: 50vw;
    bottom: -17%; } }

@media only screen and (max-device-width: 1024px) and (max-device-height: 1366px) and (orientation: portrait) {
  html.mobile-platform .photo-hm {
    height: 85vh; }
  html.mobile-platform .title-hm {
    height: 39%; } }

@media only screen and (max-device-width: 1024px) and (max-device-height: 768px) and (orientation: landscape) {
  html.mobile-platform .circle-mp {
    width: 60vw;
    height: 60vw;
    left: 7%;
    bottom: -18%; } }

@media only screen and (max-device-width: 1024px) and (max-device-height: 640px) and (orientation: landscape) {
  html.mobile-platform .circle-mp {
    width: 50vw;
    height: 50vw;
    bottom: -17%; } }

@media only screen and (max-device-width: 800px) and (max-device-height: 1280px) and (orientation: portrait) {
  html.mobile-platform .circle-mp {
    width: 120vw;
    height: 120vw;
    bottom: -16%;
    left: 0; } }

@media only screen and (max-device-width: 768px) and (max-device-height: 1024px) and (orientation: portrait) {
  html.mobile-platform .circle-mp {
    width: 100vw;
    height: 100vw;
    bottom: -15%;
    left: 7%; } }

@media only screen and (max-device-width: 640px) and (max-device-height: 1024px) and (orientation: portrait) {
  html.mobile-platform .circle-mp {
    width: 120vw;
    height: 120vw;
    bottom: -16%;
    left: 0; } }

/* RESPONSIVE HOME: MOBILE */
@media only screen and (max-width: 740px) and (max-height: 425px) and (orientation: landscape) {
  html.mobile-platform .circle-mp {
    width: 50vw;
    height: 50vw;
    bottom: -25%;
    left: 10.5%; } }

@media only screen and (max-width: 425px) {
  html.mobile-platform .circle-mp {
    width: 120vw;
    height: 120vw;
    bottom: -9%;
    left: -5%; } }

@media only screen and (max-device-width: 480px) and (max-device-height: 320px) and (orientation: landscape) {
  html.mobile-platform .circle-mp {
    width: 60vw;
    height: 60vw;
    left: 13.5%; } }

@media only screen and (max-device-width: 320px) and (max-device-height: 480px) and (orientation: portrait) {
  html.mobile-platform .circle-mp {
    width: 120vw;
    height: 120vw;
    left: 0;
    bottom: -20%; } }

/* PHOTOGALLERY */
.photogallery-ctn {
  z-index: 0; }

.photogallery-ctn:after {
  height: 30%; }

.pddg-ctn-pg {
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 180px 0 85px 0; }

.pg-ctn-inner {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden; }

.pg-ctn-inner .scroll-content {
  height: 100%; }

.pg-ctn-inner .scrollbar-track {
  display: none !important; }

.list-wr-albums:before,
.list-wr-albums:after,
.pg-ctn-inner:before,
.pg-ctn-inner:after {
  content: '';
  position: absolute;
  width: 180px;
  height: 100%;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 2;
  pointer-events: none; }

.list-wr-albums:before,
.pg-ctn-inner:before {
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, transparent 100%); }

.list-wr-albums:after,
.pg-ctn-inner:after {
  right: 0;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.7) 0%, transparent 100%); }

.ins-pg-ph {
  width: auto;
  height: 100%;
  position: relative; }

.pg-list-grid {
  width: auto;
  position: absolute;
  height: 100%;
  max-height: 709px;
  white-space: nowrap;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto 0; }

.grid-item {
  width: 500px;
  height: 100%;
  display: inline-block;
  padding: 0 30px;
  position: relative;
  vertical-align: top; }

.inner-grid-item {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.pg-link-item {
  width: 100%;
  height: calc(50% - 30px);
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden; }

.inner-pg-link-item {
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

html:not(.mobile-platform) .pg-link-item {
  opacity: 0;
  transform-style: preserve-3d;
  transform: translateX(200%) rotate3d(-0.3, 1, 0, 30deg);
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1); }

html:not(.mobile-platform) .pg-link-item.done {
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0); }

.grid-item:first-child .inner-pg-link-item {
  transform-origin: 0 50%; }

.grid-item:last-child .inner-pg-link-item {
  transform-origin: 100% 50%; }

html.ie:not(.mobile-platform) .inner-pg-link-item:hover {
  opacity: .8; }

html:not(.mobile-platform):not(.ie) .inner-pg-link-item:hover {
  transform: scale(0.96); }

.grid-item:first-child {
  padding-left: 0; }

.grid-item:last-child {
  padding-right: 0; }

.grid-item:nth-child(4n+1) {
  margin-right: 120px; }

.grid-item:nth-child(4n+1) .pg-link-item:nth-child(2) {
  margin-left: 120px; }

.grid-item:nth-child(4n+3) {
  margin-right: 120px; }

.grid-item:nth-child(4n+3) .pg-link-item:first-child {
  margin-left: 120px; }

.grid-item:nth-child(4n+4) .inner-grid-item {
  -ms-flex-pack: end;
  justify-content: flex-end; }

.grid-item:nth-child(2n+2) .pg-link-item {
  height: calc(100% - 60px);
  -ms-flex-align: start;
  align-items: flex-start; }

.grid-item:nth-child(4n+4) .pg-link-item {
  -ms-flex-align: end;
  align-items: flex-end; }

.grid-item:last-child .pg-link-item img {
  left: inherit;
  right: 0;
  transform: translate(0, -50%); }

.grid-item:nth-child(2n+1) .pg-link-item img {
  max-width: 100%;
  min-height: inherit; }

.grid-item:nth-child(2n+2) .pg-link-item img {
  max-height: 100%;
  min-width: inherit; }

.pg-link-item img {
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

/* RESPONSIVE PHOTOGALLERY: DESCTOP */
@media only screen and (min-width: 2560px) and (min-height: 1050px) {
  .lg-outer .lg-image {
    max-width: 82%;
    max-height: 82%;
    width: auto !important;
    height: auto !important; } }

@media only screen and (max-width: 1680px) {
  .pg-list-grid {
    max-height: 679px; }
  .grid-item {
    width: 480px;
    padding: 0 25px; }
  .pg-link-item {
    height: calc(50% - 25px); }
  .pg-ctn-inner:before, .pg-ctn-inner:after {
    width: 160px; }
  .grid-item:nth-child(4n+1) {
    margin-right: 100px; }
  .grid-item:nth-child(4n+1) .pg-link-item:nth-child(2) {
    margin-left: 100px; }
  .grid-item:nth-child(4n+3) {
    margin-right: 100px; }
  .grid-item:nth-child(4n+3) .pg-link-item:first-child {
    margin-left: 100px; }
  .grid-item:nth-child(2n+2) .pg-link-item {
    height: calc(100% - 50px); }
  .lg-outer .lg-image {
    max-width: 75%;
    width: auto !important; } }

@media only screen and (max-width: 1600px) {
  .pddg-ctn-pg {
    padding: 140px 0 85px 0; }
  .pg-list-grid {
    max-height: 569px; }
  .grid-item {
    width: 400px; }
  .pg-ctn-inner:before, .pg-ctn-inner:after {
    width: 135px; }
  .grid-item:nth-child(4n+1) {
    margin-right: 75px; }
  .grid-item:nth-child(4n+1) .pg-link-item:nth-child(2) {
    margin-left: 75px; }
  .grid-item:nth-child(4n+3) {
    margin-right: 75px; }
  .grid-item:nth-child(4n+3) .pg-link-item:first-child {
    margin-left: 75px; } }

@media only screen and (max-width: 1440px) {
  .pddg-ctn-pg {
    padding-bottom: 70px; }
  .pg-list-grid {
    max-height: 584px; }
  .grid-item {
    width: 420px;
    padding: 0 20px; }
  .pg-ctn-inner:before, .pg-ctn-inner:after {
    width: 130px; }
  .pg-link-item {
    height: calc(50% - 20px); }
  .grid-item:nth-child(2n+2) .pg-link-item {
    height: calc(100% - 40px); }
  .grid-item:nth-child(4n+1) {
    margin-right: 70px; }
  .grid-item:nth-child(4n+1) .pg-link-item:nth-child(2) {
    margin-left: 70px; }
  .grid-item:nth-child(4n+3) {
    margin-right: 70px; }
  .grid-item:nth-child(4n+3) .pg-link-item:first-child {
    margin-left: 70px; }
  .lg-outer .lg-image {
    max-width: 77.5%;
    max-height: 81%;
    width: auto !important;
    height: auto !important; } }

@media only screen and (max-width: 1366px) {
  .pddg-ctn-pg {
    padding-top: 120px; }
  .pg-list-grid {
    max-height: 472px; }
  .grid-item {
    width: 330px; }
  .pg-ctn-inner:before, .pg-ctn-inner:after {
    width: 120px; }
  .grid-item:nth-child(4n+1) {
    margin-right: 60px; }
  .grid-item:nth-child(4n+1) .pg-link-item:nth-child(2) {
    margin-left: 60px; }
  .grid-item:nth-child(4n+3) {
    margin-right: 60px; }
  .grid-item:nth-child(4n+3) .pg-link-item:first-child {
    margin-left: 60px; } }

@media only screen and (max-width: 1280px) {
  .lg-outer .lg-image {
    max-width: 75%;
    width: auto !important; } }

@media only screen and (max-width: 1280px) and (min-height: 890px) {
  .lg-outer .lg-image {
    max-height: 85%;
    height: auto !important; } }

/* RESPONSIVE PHOTOGALLERY: TABLET */
@media only screen and (max-device-width: 1366px) {
  html.mobile-platform body[data-page=photo-gallery] #footer {
    display: none; }
  html.mobile-platform .pddg-ctn-pg {
    padding: 138px 0 0 0; }
  html.mobile-platform .pg-list-grid:before {
    display: block; }
  html.mobile-platform .pg-ctn-inner {
    overflow-x: auto; }
  html.mobile-platform .pg-ctn-inner:before,
  html.mobile-platform .pg-ctn-inner:after,
  html.mobile-platform .photogallery-ctn:after {
    display: none; }
  html.mobile-platform .pg-list-grid {
    overflow: visible; }
  html.mobile-platform .grid-item {
    padding: 0 15px; }
  html.mobile-platform .grid-item:first-child {
    padding-left: 0; }
  html.mobile-platform .grid-item:last-child {
    padding-right: 0; }
  html.mobile-platform .grid-item:nth-child(4n+1) {
    margin-right: 30px; }
  html.mobile-platform .grid-item:nth-child(2n+2) .pg-link-item {
    height: calc(100% - 30px); }
  html.mobile-platform .grid-item:nth-child(4n+3) {
    margin-right: 30px; }
  html.mobile-platform .grid-item:nth-child(4n+1) .pg-link-item:nth-child(2) {
    margin-left: 30px; }
  html.mobile-platform .grid-item:nth-child(4n+3) .pg-link-item:first-child {
    margin-left: 30px; }
  html.mobile-platform .pg-link-item {
    height: calc(50% - 15px); }
  html.mobile-platform .lg-outer .lg-image {
    max-width: calc(100% - 120px);
    max-height: calc(100% - 120px); } }

@media only screen and (max-device-width: 1366px) and (max-device-height: 1024px) and (orientation: landscape) {
  html.mobile-platform .pg-list-grid {
    max-height: 550px; }
  html.mobile-platform .grid-item {
    width: 400px; } }

@media only screen and (max-device-width: 1280px) and (max-device-height: 800px) and (orientation: landscape) {
  html.mobile-platform .pg-list-grid {
    max-height: 400px; }
  html.mobile-platform .grid-item {
    width: 300px; }
  html.mobile-platform .pg-list-grid:before {
    font-size: 11.21vw; } }

@media only screen and (max-device-width: 1024px) and (max-device-height: 1366px) and (orientation: portrait) {
  html.mobile-platform .pg-list-grid {
    max-height: 450px; }
  html.mobile-platform .grid-item {
    width: 330px; } }

@media only screen and (max-device-width: 1024px) and (max-device-height: 768px) and (orientation: landscape) {
  html.mobile-platform .pg-list-grid {
    max-height: 400px; }
  html.mobile-platform .grid-item {
    width: 300px; } }

@media only screen and (max-device-width: 1024px) and (max-device-height: 640px) and (orientation: landscape) {
  html.mobile-platform .pddg-ctn-pg {
    padding: 146px 0 0 0; }
  html.mobile-platform .pg-list-grid {
    max-height: 260px; }
  html.mobile-platform .pg-list-grid:before {
    font-size: 10.21vw; }
  html.mobile-platform .grid-item {
    width: 200px; } }

@media only screen and (max-device-width: 800px) {
  html.mobile-platform .pg-list-grid:before {
    font-size: 11vw; } }

@media only screen and (max-device-width: 800px) and (max-device-height: 1280px) and (orientation: portrait) {
  html.mobile-platform .pg-list-grid {
    max-height: 400px; }
  html.mobile-platform .grid-item {
    width: 300px; } }

/* RESPONSIVE PHOTOGALLERY: MOBILE */
@media only screen and (max-width: 740px) and (max-height: 425px) and (orientation: landscape) {
  html.mobile-platform body[data-page=photo-gallery] {
    height: auto;
    overflow-y: auto; }
  html.mobile-platform .pddg-ctn-pg {
    padding: 0; }
  html.mobile-platform .pg-ctn-inner {
    height: auto;
    overflow-x: hidden;
    overflow-y: auto; }
  html.mobile-platform .pg-list-grid {
    max-height: inherit;
    position: relative;
    white-space: normal;
    left: inherit;
    right: inherit;
    height: auto;
    bottom: inherit;
    padding: 75px 20px 35px 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: center;
    justify-content: center; }
  html.mobile-platform .pg-list-grid:before {
    font-size: 10vw;
    position: absolute; }
  html.mobile-platform .grid-item {
    width: calc(45% - 20px);
    padding: 0;
    height: 380px;
    margin: 0 10px 20px 10px; }
  html.mobile-platform .grid-item:nth-child(4n+1),
  html.mobile-platform .grid-item:nth-child(4n+3) {
    margin-right: 10px; }
  html.mobile-platform .grid-item:nth-child(2n+2) .pg-link-item {
    height: 100%; }
  html.mobile-platform .grid-item:nth-child(4n+1) .pg-link-item:nth-child(2) {
    margin-left: 0; }
  html.mobile-platform .grid-item:nth-child(4n+3) .pg-link-item:first-child {
    margin-left: 0; }
  html.mobile-platform .pg-link-item img {
    width: 100%;
    min-width: inherit;
    position: relative;
    left: inherit;
    top: inherit;
    transform: none;
    object-fit: cover; }
  html.mobile-platform .grid-item:nth-child(2n+1) .pg-link-item img,
  html.mobile-platform .grid-item:nth-child(2n+2) .pg-link-item img {
    min-height: 100%;
    max-width: inherit;
    max-height: inherit; }
  html.mobile-platform .grid-item:last-child .pg-link-item img {
    right: inherit;
    transform: none; }
  html.mobile-platform .pg-link-item {
    height: calc(50% - 10px); }
  html.mobile-platform .lg-outer .lg-image {
    max-width: calc(100% - 60px);
    max-height: calc(100% - 80px); } }

@media only screen and (max-width: 425px) {
  html.mobile-platform .pddg-ctn-pg {
    padding: 75px 0 0 0; }
  html.mobile-platform .pg-list-grid {
    max-height: 300px; }
  html.mobile-platform .grid-item {
    width: 225px;
    padding: 0 10px; }
  html.mobile-platform .pg-list-grid:before {
    font-size: 10vw;
    margin-top: -.81em; }
  html.mobile-platform .grid-item:nth-child(4n+1),
  html.mobile-platform .grid-item:nth-child(4n+3) {
    margin-right: 20px; }
  html.mobile-platform .grid-item:nth-child(2n+2) .pg-link-item {
    height: calc(100% - 20px); }
  html.mobile-platform .grid-item:nth-child(4n+1) .pg-link-item:nth-child(2) {
    margin-left: 20px; }
  html.mobile-platform .grid-item:nth-child(4n+3) .pg-link-item:first-child {
    margin-left: 20px; }
  html.mobile-platform .pg-link-item {
    height: calc(50% - 10px); }
  html.mobile-platform .lg-outer .lg-image {
    max-width: calc(100% - 80px);
    max-height: calc(100% - 80px); } }

@media only screen and (max-device-width: 670px) and (max-device-height: 375px) and (orientation: landscape) {
  html.mobile-platform .grid-item {
    width: calc(45% - 20px);
    height: 340px; } }

@media only screen and (max-device-width: 570px) and (max-device-height: 320px) and (orientation: landscape) {
  html.mobile-platform .pg-list-grid {
    padding: 60px 20px 25px 20px; }
  html.mobile-platform .grid-item {
    width: calc(45% - 20px);
    height: 280px; } }

@media only screen and (max-width: 320px) {
  html.mobile-platform .pg-list-grid:before {
    font-size: 12vw; } }

@media only screen and (max-device-width: 320px) and (max-device-height: 480px) and (orientation: portrait) {
  html.mobile-platform .pg-list-grid {
    max-height: 250px; }
  html.mobile-platform .grid-item {
    width: 185px; } }

/* SINGLE ALBUM */
.sg-al-ctn:after {
  height: 35%; }

.sg-al-ctn {
  z-index: 0;
  background: url(imgs/single-album-hm.jpg);
  background-size: cover; }

.sg-al-ctn:before,
.sg-al-ctn:after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  pointer-events: none; }

.sg-al-ctn:before {
  top: 0;
  height: 240px;
  background: linear-gradient(to bottom, black 0%, transparent 100%); }

.sg-al-ctn:after {
  bottom: 0;
  height: 170px;
  background: linear-gradient(to bottom, transparent 0%, black 100%); }

.cv-album-ctn {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

.alb-ctn-sd {
  width: 100%;
  height: 100%;
  padding: 0 85px 0 115px;
  position: relative; }

.inner-album-sd {
  width: 100%;
  height: auto;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 170px 0 105px 0; }

.lnk-to-albums {
  width: 100%;
  position: relative; }

.lnk-to-albums a {
  position: relative;
  color: white;
  text-transform: uppercase;
  font: 28px 'ACBoucle', sans-serif;
  text-decoration: none;
  padding: 0 0 0 110px;
  letter-spacing: .25em; }

html:not(.mobile-platform) .lnk-to-albums a:hover {
  color: #FD1821;
  transition: color 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.line-lnk-lb {
  width: 88px;
  height: 2px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  overflow: hidden; }

.line-lnk-lb:before, .line-lnk-lb:after {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  right: 100%; }

.line-lnk-lb:before {
  right: 0;
  width: 100%;
  background: white; }

.line-lnk-lb:after {
  left: 0;
  width: 0;
  background: #FD1821; }

html:not(.mobile-platform) .lnk-to-albums a:hover + .line-lnk-lb:before,
html:not(.mobile-platform) .lnk-to-albums a:hover + .line-lnk-lb:after {
  transition: width 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

html:not(.mobile-platform) .lnk-to-albums a:hover + .line-lnk-lb:before {
  width: 0; }

html:not(.mobile-platform) .lnk-to-albums a:hover + .line-lnk-lb:after {
  width: 100%;
  transition-delay: 200ms; }

.ths-lb-ctn {
  width: 100%;
  height: auto;
  position: relative;
  margin: 53px 0 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.cover-album {
  width: 550px;
  height: 550px;
  position: relative;
  overflow: hidden;
  transition: all 250ms cubic-bezier(0.39, 0.58, 0.57, 1);
  transform-origin: 50% 50%; }

html:not(.mobile-platform) .cover-album.playing {
  transform: scale(0.95) !important;
  transition-delay: 0s !important; }

.cover-album img {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.info-album-ctn {
  width: calc(100% - 620px);
  height: auto;
  overflow: hidden; }

.title-w-links-lb {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center; }

.ttl-lb-yr {
  width: calc(100% - 550px);
  padding: 0 0 0 13px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.ttl-lb-mn {
  width: 100%;
  text-transform: uppercase; }

.ttl-lb-mn h1 {
  width: 100%;
  margin: 0;
  color: white;
  cursor: default;
  letter-spacing: .35em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: 34px 'ACBoucle', sans-serif; }

.yr-lb-mn,
.yr-lb-mn * {
  width: 100%;
  font-size: 14px;
  letter-spacing: .1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(255, 255, 255, 0.25); }

.yr-lb-mn, .yr-lb-mn * {
  cursor: default; }

.yr-lb-mn b, .yr-lb-mn strong {
  font-weight: normal; }

.links-listen-ms {
  width: 550px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.links-listen-ms a {
  color: white;
  height: 53px;
  font-size: 13px;
  letter-spacing: .04em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  position: relative;
  background: #23272C;
  padding: 0 22px 0 62px;
  margin-left: 15px;
  border-radius: 8px;
  transition: background .2s ease-out;
  overflow: hidden; }

.links-listen-ms a b, .links-listen-ms a strong {
  font-weight: normal; }

.links-listen-ms a:first-child {
  margin-left: 0; }

.links-listen-ms a.google-play,
.links-listen-ms a.yandex-music {
  padding-right: 15px; }

html:not(.mobile-platform) .links-listen-ms a:hover {
  background: #FD1821; }

.links-listen-ms a:before {
  content: '';
  position: absolute;
  left: 21px;
  top: 0;
  bottom: 0;
  margin: auto 0; }

.links-listen-ms a.apple-music:before {
  width: 21px;
  height: 26px;
  background: url(./imgs/svg/apple-music.svg) no-repeat center;
  background-size: 100% 100%; }

.links-listen-ms a.google-play:before {
  width: 21px;
  height: 22px;
  background: url(./imgs/svg/google-play.svg) no-repeat center;
  background-size: 100% 100%; }

.links-listen-ms a.yandex-music:before {
  width: 22px;
  height: 28px;
  background: url(./imgs/svg/yandex-music.svg) no-repeat center;
  background-size: 100% 100%; }

.list-aud-lb {
  width: 100%;
  margin-top: 39px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  position: relative; }

.sg-al-ctn .scrollbar-track {
  background: none !important; }

.sg-al-ctn .scrollbar-thumb {
  background: rgba(255, 255, 255, 0.37); }

.sg-al-ctn .scrollbar-track, .sg-al-ctn .scrollbar-thumb {
  width: 6px; }

.inner-ad-list {
  width: 100%;
  height: auto;
  position: relative; }

.audio-one {
  width: 100%;
  position: relative;
  transition: all .2s ease-out;
  margin-bottom: 10px; }

.audio-one:last-child {
  margin-bottom: 0; }

.audiojs audio {
  position: absolute;
  left: -1px; }

.audio-one b, .audio-one strong {
  font-weight: normal; }

html:not(.mobile-platform) .audio-one:hover, .audio-one.active {
  background: #FD1821; }

.audiojs {
  width: 100%;
  height: 40px;
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.audio-title {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: none;
  font-size: 16px;
  letter-spacing: .05em;
  border: none;
  color: white;
  text-align: left;
  padding: 0 82px 0 40px;
  cursor: pointer;
  z-index: 2; }

.audio-title, .audio-title * {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.audio-one.error-prt {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
  text-size-adjust: none; }

.audiojs.error {
  z-index: 3; }

.audiojs .play-pause {
  width: 40px;
  height: 40px;
  position: relative; }

.audiojs p {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer; }

.audiojs .play {
  display: block;
  opacity: 0; }

html:not(.mobile-platform) .audio-one:hover .play {
  opacity: 1; }

.audiojs .pause:before,
.audiojs .play:before {
  content: '';
  position: absolute;
  width: 6px;
  height: 9px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto; }

.audio-one.active .audiojs .pause:before {
  background: url("imgs/svg/pause-lb.svg") no-repeat center;
  background-size: 100% 100%; }

.audiojs .play:before {
  background: url("imgs/svg/play-lb.svg") no-repeat center;
  background-size: 100% 100%; }

.audiojs .scrubber {
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: none; }

.audio-one.active .scrubber {
  display: block; }

.audiojs .progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: #F7979B;
  z-index: 1; }

.audiojs .loaded {
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 0; }

.audiojs .time {
  height: 40px;
  display: -ms-flexbox;
  display: flex;
  padding-right: 15px;
  -ms-flex-align: center;
  align-items: center;
  color: white;
  font-size: 0;
  letter-spacing: .05em;
  position: relative; }

.audiojs .time em {
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-style: normal;
  visibility: hidden;
  position: absolute;
  transform: translateY(-100%); }

.audiojs .time strong {
  font-weight: normal; }

.audiojs .time em, .audiojs .time strong {
  padding: 0;
  font-size: 14px;
  transition: all .2s ease-out; }

.audio-one.active .time strong {
  opacity: 0;
  visibility: hidden;
  transform: translateY(100%); }

.audio-one.active .time em {
  opacity: 1;
  visibility: visible;
  transform: none; }

.audiojs .error-message {
  display: none;
  margin: 0;
  max-width: 161px;
  font-size: 16px;
  overflow: hidden;
  padding: 0 15px 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
  letter-spacing: .05em;
  height: 40px;
  line-height: 40px; }

.audio-one.error-prt .audio-title {
  padding-right: 190px; }

.playing .play, .playing .loading, .playing .error {
  display: none; }

.playing .pause {
  display: block; }

.loading .play, .loading .pause, .loading .error {
  display: none; }

.loading .loading {
  display: block; }

.error .time, .error .play, .error .pause, .error .scrubber, .error .loading {
  display: none; }

.error .error {
  display: block; }

.error .play-pause p {
  cursor: auto; }

.error .error-message {
  display: block;
  color: #FD1821; }

.pg-ctn-inner.scrolling .grid-item,
.list-wr-albums.scrolling .link-album,
.info-album-ctn.scrolling .inner-ad-list,
.dw-dr-vds.scrolling .preview-vd,
.ind-sc-alif.scrolling .one-sc-cc,
.inner-cns-ctn.scrolling .insd-cns-sl {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
  text-size-adjust: none; }

/* ANIMATION SINGLE ALBUMS */
html:not(.mobile-platform) .lnk-to-albums.elem-anim,
html:not(.mobile-platform) .title-w-links-lb.elem-anim,
html:not(.mobile-platform) .list-aud-lb.elem-anim,
html:not(.mobile-platform) .cover-album.elem-anim {
  opacity: 0;
  transition: all 800ms cubic-bezier(0.4, 0, 0.2, 1); }

html:not(.mobile-platform) .title-w-links-lb.elem-anim,
html:not(.mobile-platform) .list-aud-lb.elem-anim {
  transform: translateY(50vh);
  transform-origin: center bottom; }

html:not(.mobile-platform) .cover-album.elem-anim {
  transform: scale(1.2); }

html:not(.mobile-platform) .sg-al-ctn .scrollbar-track {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

html:not(.mobile-platform) .cover-album.elem-anim {
  transition-delay: 200ms; }

html:not(.mobile-platform) .title-w-links-lb.elem-anim {
  transition-delay: 400ms; }

html:not(.mobile-platform) .list-aud-lb.elem-anim {
  transition-delay: 600ms; }

html:not(.mobile-platform) .sg-al-ctn .scrollbar-track {
  transition-delay: 800ms; }

html:not(.mobile-platform) .lnk-to-albums.elem-anim {
  transition-delay: 1s; }

html:not(.mobile-platform) .lnk-to-albums.elem-anim.done,
html:not(.mobile-platform) .cover-album.elem-anim.done,
html:not(.mobile-platform) .title-w-links-lb.elem-anim.done,
html:not(.mobile-platform) .list-aud-lb.elem-anim.done {
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0); }

html:not(.mobile-platform) .sg-al-ctn.done .scrollbar-track {
  opacity: 1; }

/* RESPONSIVE SINGLE ALBUM: DESCTOP */
@media only screen and (max-width: 1680px) {
  .inner-album-sd {
    padding-top: 220px; }
  .ths-lb-ctn {
    margin-top: 33px; }
  .audio-list {
    margin-bottom: 12px; }
  .lnk-to-albums a {
    font-size: 25px;
    padding: 0 0 0 88px; }
  .line-lnk-lb {
    width: 68px; }
  .cover-album {
    width: 446px;
    height: 446px; }
  .info-album-ctn {
    width: calc(100% - 500px); }
  .alb-ctn-sd {
    padding: 0 50px; }
  .list-aud-lb {
    margin-top: 30px; }
  .links-listen-ms {
    width: 472px; }
  .links-listen-ms a {
    height: 48px;
    font-size: 12px;
    padding: 0 18px 0 55px;
    margin-left: 12px; }
  .links-listen-ms a.apple-music:before {
    width: 18px;
    height: 21px; }
  .links-listen-ms a.google-play:before {
    width: 18px;
    height: 19px; }
  .links-listen-ms a.yandex-music:before {
    width: 18px;
    height: 24px; }
  .ttl-lb-yr {
    width: calc(100% - 472px);
    padding: 0 0 0 12px; }
  .ttl-lb-mn h1 {
    font-size: 32px; }
  .yr-lb-mn, .yr-lb-mn * {
    font-size: 13px; }
  .audio-one {
    margin-bottom: 13px; }
  .audiojs {
    height: 34px; }
  .audio-title {
    padding: 0 78px 0 35px; }
  .audiojs .play-pause {
    width: 34px;
    height: 34px; }
  .audio-one.error-prt .audio-title {
    padding-right: 185px; }
  .audiojs .error-message, .audio-title {
    font-size: 15px; }
  .audiojs .error-message {
    max-width: 155px;
    height: 34px;
    line-height: 34px; }
  .audiojs .time {
    height: 34px; }
  .audiojs .time em, .audiojs .time strong {
    font-size: 13px; } }

@media only screen and (max-width: 1600px) {
  .inner-album-sd {
    padding-top: 150px; }
  .ths-lb-ctn {
    margin-top: 24px; }
  .audio-list {
    margin-bottom: 9px; }
  .info-album-ctn {
    width: calc(100% - 470px); }
  .list-aud-lb {
    margin-top: 23px; }
  .audio-one {
    margin-bottom: 9px; }
  .cover-album {
    width: 420px;
    height: 420px; } }

@media only screen and (max-width: 1440px) {
  .inner-album-sd {
    padding-bottom: 80px; }
  .sg-al-ctn:before {
    height: 180px; }
  .sg-al-ctn:after {
    height: 140px; }
  .lnk-to-albums a {
    font-size: 21px;
    padding: 0 0 0 88px; }
  .audio-list-title, .mejs__time {
    font-size: 14px;
    line-height: 39px; }
  .audio-list {
    margin-bottom: 12px; }
  .line-lnk-lb {
    width: 68px; }
  .mejs__button > button {
    width: 33px; }
  .audio-list-title {
    padding: 0 60px 0 33px; }
  .cover-album {
    width: 400px;
    height: 400px; }
  .info-album-ctn {
    width: calc(100% - 450px); }
  .ths-lb-ctn {
    margin-top: 24px; }
  .alb-ctn-sd {
    padding: 0 30px; }
  .title-w-links-lb {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0 0 0 34px; }
  .ttl-lb-yr {
    width: 100%;
    padding: 0 0 20px 0; }
  .ttl-lb-mn h1 {
    font-size: 32px; }
  .links-listen-ms {
    width: 100%;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .title-w-links-lb {
    padding: 0 0 0 12px; }
  .audio-one {
    margin-bottom: 7px; }
  .audiojs {
    height: 30px; }
  .audio-title {
    padding: 1px 75px 0 30px; }
  .audiojs .play-pause {
    width: 30px;
    height: 30px; }
  .audio-one.error-prt .audio-title {
    padding-right: 175px; }
  .audiojs .error-message {
    max-width: 145px;
    height: 30px;
    line-height: 30px; }
  .audiojs .error-message, .audio-title {
    font-size: 14px; }
  .audiojs .time {
    height: 30px; }
  .audiojs .time em, .audiojs .time strong {
    font-size: 12px; } }

@media only screen and (max-width: 1366px) {
  .inner-album-sd {
    padding-top: 135px; }
  .ths-lb-ctn {
    margin-top: 20px; }
  .cover-album {
    width: 350px;
    height: 350px; }
  .info-album-ctn {
    width: calc(100% - 390px); }
  .audio-one {
    margin-bottom: 12px; } }

/* RESPONSIVE SINGLE ALBUM: TABLET */
@media only screen and (max-device-width: 1366px) {
  html.mobile-platform body[data-page=single-album] {
    height: auto;
    overflow: auto; }
  html.mobile-platform .sg-al-ctn {
    background: none;
    z-index: 10; }
  html.mobile-platform .sg-al-ctn:before,
  html.mobile-platform .sg-al-ctn:after {
    display: none; }
  html.mobile-platform .alb-ctn-sd {
    min-height: 100vh;
    padding: 116px 30px 0 30px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center; }
  html.mobile-platform .inner-album-sd {
    width: 496px;
    display: block;
    padding: 0; }
  html.mobile-platform .ths-lb-ctn {
    width: 100%;
    height: auto;
    display: block;
    margin: 0; }
  html.mobile-platform .lnk-to-albums {
    margin: 34px 0; }
  html.mobile-platform .lnk-to-albums a {
    font-size: 26px;
    padding: 0 0 0 90px; }
  html.mobile-platform .line-lnk-lb {
    width: 60px; }
  html.mobile-platform .mejs__container {
    height: 50px !important; }
  html.mobile-platform .audio-list {
    margin-bottom: 15px; }
  html.mobile-platform .audio-list,
  html.mobile-platform .audio-list-play,
  html.mobile-platform .mejs__time,
  html.mobile-platform .mejs__button > button {
    height: 50px; }
  html.mobile-platform .mejs__time,
  html.mobile-platform .audio-list-title {
    font-size: 16px;
    line-height: 47px; }
  html.mobile-platform .mejs__pause > button:before,
  html.mobile-platform .mejs__play > button:before {
    width: 8px;
    height: 11px;
    top: -4px; }
  html.mobile-platform .mejs__replay > button:before {
    width: 12px;
    height: 12px;
    top: -4px; }
  html.mobile-platform .mejs__button > button {
    width: 50px; }
  html.mobile-platform .mejs__time-rail {
    height: 7px; }
  html.mobile-platform .audio-list-title {
    padding: 0 65px 0 50px; }
  html.mobile-platform .play-pause .error:before {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: url("imgs/svg/close-gv-hv.svg") no-repeat center;
    background-size: 100% 100%; }
  html.mobile-platform .audiojs .error-message {
    padding: 0;
    font-size: 16px;
    height: 40px;
    line-height: 40px; }
  html.mobile-platform .audiojs.error .play-pause {
    border-color: #FD1821; }
  html.mobile-platform .cover-album {
    width: 100%;
    height: 0;
    padding-top: 100%;
    transition: none; }
  html.mobile-platform .info-album-ctn {
    width: 100%; }
  html.mobile-platform .inner-ad-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column; }
  html.mobile-platform .title-w-links-lb {
    padding: 0;
    display: block; }
  html.mobile-platform .ttl-lb-yr {
    padding: 0 20px;
    margin: 21px 0 31px 0; }
  html.mobile-platform .ttl-lb-mn {
    margin-bottom: 5px; }
  html.mobile-platform .links-listen-ms {
    display: none; }
  html.mobile-platform .ttl-lb-mn h1 {
    font-size: 30px; }
  html.mobile-platform .yr-lb-mn,
  html.mobile-platform .yr-lb-mn * {
    font-size: 15px; }
  html.mobile-platform .ll-ms-mp {
    display: block;
    margin: 51px 0 21px 0; }
  html.mobile-platform .list-aud-lb {
    margin-top: 13px; }
  html.mobile-platform .audio-one {
    margin-bottom: 20px;
    transition: none;
    display: block; }
  html.mobile-platform .audio-one.active {
    background: none; }
  html.mobile-platform .audio-title {
    font-size: 16px;
    padding: 0 74px 0 56px; }
  html.mobile-platform .audiojs {
    height: 40px; }
  html.mobile-platform .audiojs .time {
    height: 40px;
    padding: 0 3px 0 0; }
  html.mobile-platform .audiojs .time em,
  html.mobile-platform .audiojs .time strong {
    font-size: 16px;
    letter-spacing: .05em; }
  html.mobile-platform .audiojs .scrubber {
    width: calc(100% - 56px);
    left: 56px;
    bottom: 0;
    height: 3px;
    background: #2E0E0D; }
  html.mobile-platform .audiojs .progress {
    background: #ED1C24; }
  html.mobile-platform .audiojs .play-pause {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid white; }
  html.mobile-platform .audiojs .play {
    opacity: 1; }
  html.mobile-platform .audiojs .play:before {
    width: 11px;
    height: 13px;
    left: 3px; }
  html.mobile-platform .audiojs .pause:before {
    width: 11px;
    height: 12px; } }

@media only screen and (max-device-width: 1024px) {
  html.mobile-platform .ll-ms-mp {
    margin: 51px 0 41px 0; } }

@media only screen and (max-device-width: 800px) {
  html.mobile-platform .inner-album-sd {
    width: 100%; }
  html.mobile-platform .lnk-to-albums {
    margin: 44px 0; }
  html.mobile-platform .lnk-to-albums a {
    font-size: 34px; }
  html.mobile-platform .play-pause .error:before {
    width: 17px;
    height: 17px; }
  html.mobile-platform .audio-one.error-prt .audio-title {
    padding-right: 230px; }
  html.mobile-platform .audiojs .error-message {
    font-size: 22px;
    line-height: 50px;
    height: 50px;
    max-width: 200px; }
  html.mobile-platform .ttl-lb-yr {
    margin: 31px 0 51px 0; }
  html.mobile-platform .ttl-lb-mn {
    margin-bottom: 8px; }
  html.mobile-platform .ttl-lb-mn h1 {
    font-size: 44px; }
  html.mobile-platform .yr-lb-mn,
  html.mobile-platform .yr-lb-mn * {
    font-size: 20px; }
  html.mobile-platform .ll-ms-mp {
    margin: 71px 0 51px 0; }
  html.mobile-platform .list-aud-lb {
    margin-top: 23px; }
  html.mobile-platform .audio-one {
    margin-bottom: 25px; }
  html.mobile-platform .audiojs {
    height: 50px; }
  html.mobile-platform .audio-title {
    font-size: 22px;
    padding: 0 90px 0 66px; }
  html.mobile-platform .audiojs .time {
    height: 50px; }
  html.mobile-platform .audiojs .time em,
  html.mobile-platform .audiojs .time strong {
    font-size: 22px; }
  html.mobile-platform .audiojs .scrubber {
    width: calc(100% - 66px);
    left: 66px;
    bottom: 0; }
  html.mobile-platform .audiojs .play-pause {
    width: 50px;
    height: 50px; }
  html.mobile-platform .audiojs .play:before {
    width: 16px;
    height: 17px; }
  html.mobile-platform .audiojs .pause:before {
    width: 12px;
    height: 17px; }
  html.mobile-platform .ll-ms-mp a {
    height: 80px;
    font-size: 21px;
    margin: 0 0 20px 0; }
  html.mobile-platform .ll-ms-mp a span {
    padding-left: 56px; }
  html.mobile-platform .ll-ms-mp a.apple-music span:before {
    width: 30px;
    height: 34px; }
  html.mobile-platform .ll-ms-mp a.google-play span:before {
    width: 29px;
    height: 31px; }
  html.mobile-platform .ll-ms-mp a.yandex-music span:before {
    width: 29px;
    height: 39px; } }

@media only screen and (max-device-width: 640px) {
  html.mobile-platform .lnk-to-albums {
    margin: 30px 0; }
  html.mobile-platform .lnk-to-albums a {
    font-size: 26px; }
  html.mobile-platform .play-pause .error:before {
    width: 17px;
    height: 17px; }
  html.mobile-platform .audio-one.error-prt .audio-title {
    padding-right: 195px; }
  html.mobile-platform .audiojs .error-message {
    font-size: 18px;
    line-height: 40px;
    height: 40px;
    max-width: 165px; }
  html.mobile-platform .audiojs .time {
    height: 40px; }
  html.mobile-platform .ttl-lb-yr {
    margin: 24px 0 34px 0; }
  html.mobile-platform .ttl-lb-mn {
    margin-bottom: 4px; }
  html.mobile-platform .ttl-lb-mn h1 {
    font-size: 33px; }
  html.mobile-platform .yr-lb-mn,
  html.mobile-platform .yr-lb-mn * {
    font-size: 16px; }
  html.mobile-platform .ll-ms-mp {
    margin: 55px 0 40px 0; }
  html.mobile-platform .list-aud-lb {
    margin-top: 10px; }
  html.mobile-platform .audio-one {
    margin-bottom: 22px; }
  html.mobile-platform .audiojs {
    height: 40px; }
  html.mobile-platform .audio-title {
    font-size: 18px;
    padding: 0 80px 0 56px; }
  html.mobile-platform .audiojs .time em,
  html.mobile-platform .audiojs .time strong {
    font-size: 18px; }
  html.mobile-platform .audiojs .scrubber {
    width: calc(100% - 56px);
    left: 56px;
    bottom: 0; }
  html.mobile-platform .audiojs .play-pause {
    width: 40px;
    height: 40px; }
  html.mobile-platform .audiojs .play:before {
    width: 16px;
    height: 17px; }
  html.mobile-platform .audiojs .pause:before {
    width: 12px;
    height: 17px; }
  html.mobile-platform .ll-ms-mp a {
    height: 65px;
    font-size: 18px;
    margin: 0 0 14px 0; }
  html.mobile-platform .ll-ms-mp a span {
    padding-left: 46px; }
  html.mobile-platform .ll-ms-mp a.apple-music span:before {
    width: 24px;
    height: 28px; }
  html.mobile-platform .ll-ms-mp a.google-play span:before {
    width: 24px;
    height: 26px; }
  html.mobile-platform .ll-ms-mp a.yandex-music span:before {
    width: 24px;
    height: 32px; } }

/* RESPONSIVE SINGLE ALBUM: MOBILE */
@media only screen and (max-width: 740px) and (max-height: 425px) and (orientation: landscape) {
  html.mobile-platform .alb-ctn-sd {
    padding: 0 20px; }
  html.mobile-platform .inner-album-sd {
    width: 300px; }
  html.mobile-platform .lnk-to-albums {
    margin: 25px 0; }
  html.mobile-platform .line-lnk-lb {
    width: 40px; }
  html.mobile-platform .lnk-to-albums a {
    font-size: 18px;
    padding: 0 0 0 60px; }
  html.mobile-platform .play-pause .error:before {
    width: 10px;
    height: 10px; }
  html.mobile-platform .audio-one.error-prt .audio-title {
    padding-right: 145px; }
  html.mobile-platform .mejs__container {
    height: 45px !important; }
  html.mobile-platform .audio-list {
    margin-bottom: 10px; }
  html.mobile-platform .audio-list,
  html.mobile-platform .audio-list-play,
  html.mobile-platform .mejs__time,
  html.mobile-platform .mejs__button > button {
    height: 45px; }
  html.mobile-platform .mejs__time,
  html.mobile-platform .audio-list-title {
    font-size: 14px;
    line-height: 43px; }
  html.mobile-platform .mejs__pause > button:before,
  html.mobile-platform .mejs__play > button:before {
    width: 6px;
    height: 8px;
    top: -2px; }
  html.mobile-platform .mejs__replay > button:before {
    width: 9px;
    height: 9px;
    top: -1px; }
  html.mobile-platform .mejs__button > button {
    width: 45px; }
  html.mobile-platform .mejs__time-rail {
    height: 5px; }
  html.mobile-platform .audio-list-title {
    padding: 0 60px 0 45px; }
  html.mobile-platform .audiojs .error-message {
    font-size: 14px;
    line-height: 26px;
    height: 26px;
    letter-spacing: .025em;
    max-width: 125px; }
  html.mobile-platform .audiojs .time {
    height: 24px;
    letter-spacing: .025em; }
  html.mobile-platform .ttl-lb-yr {
    padding: 0;
    margin: 8px 0 13px 0; }
  html.mobile-platform .ttl-lb-mn {
    margin-bottom: 6px; }
  html.mobile-platform .ttl-lb-mn h1 {
    font-size: 21px;
    margin-top: 2px; }
  html.mobile-platform .yr-lb-mn,
  html.mobile-platform .yr-lb-mn * {
    font-size: 12px;
    margin-top: -3px; }
  html.mobile-platform .ll-ms-mp {
    margin: 40px 0 15px 0; }
  html.mobile-platform .list-aud-lb {
    margin-top: 20px; }
  html.mobile-platform .audio-one {
    margin-bottom: 22px; }
  html.mobile-platform .audiojs {
    height: 26px; }
  html.mobile-platform .audio-title {
    font-size: 14px;
    letter-spacing: .025em;
    padding: 0 60px 2px 33px; }
  html.mobile-platform .audiojs .time em,
  html.mobile-platform .audiojs .time strong {
    font-size: 14px; }
  html.mobile-platform .audiojs .scrubber {
    width: calc(100% - 33px);
    left: 33px;
    bottom: 0;
    height: 2px;
    display: none; }
  html.mobile-platform .audiojs.playing .scrubber {
    display: block; }
  html.mobile-platform .audiojs .play-pause {
    width: 26px;
    height: 26px; }
  html.mobile-platform .audiojs .play:before {
    width: 8px;
    height: 11px;
    left: 2px; }
  html.mobile-platform .audiojs .pause:before {
    width: 8px;
    height: 9px; }
  html.mobile-platform .ll-ms-mp a {
    height: 50px;
    font-size: 14px;
    margin: 0 0 11px 0;
    border-radius: 5px; }
  html.mobile-platform .ll-ms-mp a span {
    padding-left: 32px; }
  html.mobile-platform .ll-ms-mp a.apple-music span:before {
    width: 20px;
    height: 23px; }
  html.mobile-platform .ll-ms-mp a.google-play span:before {
    width: 19px;
    height: 21px; }
  html.mobile-platform .ll-ms-mp a.yandex-music span:before {
    width: 19px;
    height: 25px; } }

@media only screen and (max-width: 425px) {
  html.mobile-platform .alb-ctn-sd {
    padding: 75px 20px 0 20px; }
  html.mobile-platform .lnk-to-albums {
    margin: 25px 0; }
  html.mobile-platform .line-lnk-lb {
    width: 40px; }
  html.mobile-platform .lnk-to-albums a {
    font-size: 18px;
    padding: 0 0 0 60px; }
  html.mobile-platform .play-pause .error:before {
    width: 10px;
    height: 10px; }
  html.mobile-platform .audio-one.error-prt .audio-title {
    padding-right: 145px; }
  html.mobile-platform .mejs__container {
    height: 45px !important; }
  html.mobile-platform .audio-list {
    margin-bottom: 10px; }
  html.mobile-platform .audio-list,
  html.mobile-platform .audio-list-play,
  html.mobile-platform .mejs__time,
  html.mobile-platform .mejs__button > button {
    height: 45px; }
  html.mobile-platform .mejs__time,
  html.mobile-platform .audio-list-title {
    font-size: 14px;
    line-height: 43px; }
  html.mobile-platform .mejs__pause > button:before,
  html.mobile-platform .mejs__play > button:before {
    width: 6px;
    height: 8px;
    top: -2px; }
  html.mobile-platform .mejs__replay > button:before {
    width: 9px;
    height: 9px;
    top: -1px; }
  html.mobile-platform .mejs__button > button {
    width: 45px; }
  html.mobile-platform .mejs__time-rail {
    height: 5px; }
  html.mobile-platform .audio-list-title {
    padding: 0 60px 0 45px; }
  html.mobile-platform .audiojs .error-message {
    font-size: 14px;
    line-height: 26px;
    height: 26px;
    letter-spacing: .025em;
    max-width: 125px; }
  html.mobile-platform .audiojs .time {
    height: 24px;
    letter-spacing: .025em; }
  html.mobile-platform .ttl-lb-yr {
    padding: 0;
    margin: 8px 0 13px 0; }
  html.mobile-platform .ttl-lb-mn {
    margin-bottom: 6px; }
  html.mobile-platform .ttl-lb-mn h1 {
    font-size: 25px; }
  html.mobile-platform .yr-lb-mn,
  html.mobile-platform .yr-lb-mn * {
    font-size: 14px; }
  html.mobile-platform .ll-ms-mp {
    margin: 40px 0 30px 0; }
  html.mobile-platform .list-aud-lb {
    margin-top: 20px; }
  html.mobile-platform .audio-one {
    margin-bottom: 22px; }
  html.mobile-platform .audiojs {
    height: 26px; }
  html.mobile-platform .audio-title {
    font-size: 14px;
    letter-spacing: .025em;
    padding: 0 60px 2px 33px; }
  html.mobile-platform .audiojs .time em,
  html.mobile-platform .audiojs .time strong {
    font-size: 14px; }
  html.mobile-platform .audiojs .scrubber {
    width: calc(100% - 33px);
    left: 33px;
    bottom: 0;
    height: 2px;
    display: none; }
  html.mobile-platform .audiojs.playing .scrubber {
    display: block; }
  html.mobile-platform .audiojs .play-pause {
    width: 26px;
    height: 26px; }
  html.mobile-platform .audiojs .play:before {
    width: 8px;
    height: 11px;
    left: 2px; }
  html.mobile-platform .audiojs .pause:before {
    width: 8px;
    height: 9px; }
  html.mobile-platform .ll-ms-mp a {
    height: 50px;
    font-size: 14px;
    margin: 0 0 11px 0;
    border-radius: 5px; }
  html.mobile-platform .yr-lb-mn {
    margin-top: -5px; }
  html.mobile-platform .ll-ms-mp a span {
    padding-left: 32px; }
  html.mobile-platform .ll-ms-mp a.apple-music span:before {
    width: 20px;
    height: 23px; }
  html.mobile-platform .ll-ms-mp a.google-play span:before {
    width: 19px;
    height: 21px; }
  html.mobile-platform .ll-ms-mp a.yandex-music span:before {
    width: 19px;
    height: 25px; } }

@media only screen and (max-width: 425px) {
  html.mobile-platform .ttl-lb-mn h1 {
    font-size: 22px; }
  html.mobile-platform .yr-lb-mn,
  html.mobile-platform .yr-lb-mn * {
    font-size: 13px; } }

/* VIDEOS */
.vids-ctn-hm {
  z-index: 0; }

.inner-vids-ctn {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.inner-vids-ctn:before,
.inner-vids-ctn:after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  pointer-events: none; }

.inner-vids-ctn:before {
  top: 0;
  height: 240px;
  background: linear-gradient(to bottom, black 0%, transparent 100%); }

.inner-vids-ctn:after {
  bottom: 0;
  height: 170px;
  background: linear-gradient(to bottom, transparent 0%, black 100%); }

.dw-dr-vds {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 0 50px; }

.ctn-vd-cs {
  width: 100%;
  height: auto;
  padding: 192px 0 160px 0; }

.preview-vd {
  display: inline-block;
  width: 47.5%;
  position: relative;
  margin-bottom: 100px;
  vertical-align: top;
  text-align: left; }

.vids-ctn-hm .scrollbar-track {
  background: none !important; }

.vids-ctn-hm .scrollbar-thumb {
  background: rgba(255, 255, 255, 0.26); }

.vids-ctn-hm .scrollbar-track, .vids-ctn-hm .scrollbar-thumb {
  width: 6px; }

.preview-vd:last-child {
  margin-bottom: 0; }

.preview-vd:nth-child(2n+2) {
  margin-bottom: -120px; }

.preview-vd:last-child, .preview-vd:nth-child(2n+2) {
  margin-top: 120px;
  margin-left: calc(5% - 5px); }

.preview-vd:nth-last-child(1):nth-child(odd) {
  margin: 0; }

.preview-img-vd {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden; }

.img-video-sll {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

html:not(.ie) .img-video-sll {
  opacity: .9;
  transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

html:not(.mobile-platform) .preview-vd:hover .img-video-sll {
  opacity: 1; }

html:not(.mobile-platform):not(.ie) .preview-vd:hover .img-video-sll {
  transform: scale(1.025); }

.preview-img-vd img {
  width: 100%;
  height: auto;
  min-height: 100%;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.play-btn {
  width: 55px;
  height: 63px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1; }

.play-btn:before,
.play-btn:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform-origin: 50% 50%;
  transition: all .2s ease-out;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0, 1); }

.play-btn:after {
  opacity: 0; }

html:not(.ie) .play-btn:after {
  -webkit-transform: scale(0.5);
  -webkit-filter: blur(5px); }

.play-btn:before {
  background: url("imgs/svg/play.svg") no-repeat center;
  background-size: contain; }

.play-btn:after {
  background: url("imgs/svg/play-hv.svg") no-repeat center;
  background-size: contain; }

html:not(.mobile-platform) .preview-vd:hover .play-btn:before {
  opacity: 0; }

html:not(.mobile-platform) .preview-vd:hover .play-btn:after {
  opacity: 1; }

html:not(.mobile-platform):not(.ie) .preview-vd:hover .play-btn:before {
  -webkit-transform: scale(1.8);
  -webkit-filter: blur(5px); }

html:not(.mobile-platform):not(.ie) .preview-vd:hover .play-btn:after {
  -webkit-transform: none;
  -webkit-filter: none; }

.title-vd {
  width: 100%;
  position: absolute;
  padding: 0 30px;
  bottom: -41px; }

.title-vd p {
  width: auto;
  max-width: 100%;
  position: relative;
  font: 60px 'ACBoucle', sans-serif;
  letter-spacing: .2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: -.1em;
  color: white;
  z-index: 0;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.4s cubic-bezier(0.22, 0.61, 0.36, 1); }

.title-vd b, .title-vd strong {
  font-weight: normal; }

.title-vd p:after {
  content: '';
  position: absolute;
  width: 100%;
  z-index: -1;
  height: 13px;
  margin-left: -.15em;
  background: #FD1821;
  left: 0;
  bottom: 10px;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1); }

html:not(.mobile-platform) .preview-vd:hover .title-vd p:after {
  transform: none;
  transform-origin: left center; }

/* ANIMATION VIDEOS */
html:not(.mobile-platform) .preview-vd {
  opacity: 0;
  transform: translateY(50vh);
  transition: all 800ms cubic-bezier(0.4, 0, 0.2, 1); }

html:not(.mobile-platform) .preview-vd .play-btn,
html:not(.mobile-platform) .preview-vd .title-vd {
  opacity: 0;
  transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }

html:not(.mobile-platform) .preview-vd .play-btn {
  transform: scale(1.3);
  transition-delay: 700ms; }

html:not(.mobile-platform) .preview-vd .title-vd {
  transform: translateX(-30px);
  transition-delay: 700ms; }

html:not(.mobile-platform) .preview-vd.done,
html:not(.mobile-platform) .preview-vd.done .play-btn,
html:not(.mobile-platform) .preview-vd.done .title-vd {
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0); }

/* RESPONSIVE VIDEOS: DESCTOP */
@media only screen and (max-width: 1680px) {
  .ctn-vd-cs {
    padding: 175px 0 145px 0; }
  .preview-vd {
    margin-bottom: 80px; }
  .preview-vd:nth-child(2n+2) {
    margin-bottom: -100px; }
  .preview-vd:last-child, .preview-vd:nth-child(2n+2) {
    margin-top: 100px; }
  .play-btn {
    width: 45px;
    height: 52px; }
  .title-vd {
    bottom: -35px; }
  .title-vd p {
    font-size: 50px; }
  .title-vd p:after {
    bottom: 8px; }
  .lg-prev, .lg-next {
    width: 124px;
    height: 80px; }
  .lg-prev:before {
    transform: translateX(-62px); }
  .lg-next:before {
    transform: translateX(62px); } }

@media only screen and (max-width: 1600px) {
  .ctn-vd-cs {
    padding: 150px 0 135px 0; }
  .lg-outer .lg-video-cont {
    width: 62% !important;
    max-width: 62% !important; } }

@media only screen and (max-width: 1600px) and (min-height: 1050px) {
  .lg-outer .lg-video-cont {
    width: 70% !important;
    max-width: 70% !important; } }

@media only screen and (max-width: 1440px) {
  .inner-vids-ctn:before {
    height: 180px; }
  .inner-vids-ctn:after {
    height: 140px; }
  .dw-dr-vds {
    padding: 0 30px; }
  .ctn-vd-cs {
    padding: 110px 0; }
  .preview-vd {
    width: 47.9%;
    margin-bottom: 60px; }
  .preview-vd:nth-child(2n+2) {
    margin-bottom: -80px; }
  .preview-vd:last-child, .preview-vd:nth-child(2n+2) {
    margin-top: 80px;
    margin-left: calc(4.2% - 5px); }
  .title-vd {
    bottom: -29px;
    padding: 0 15px; }
  .title-vd p {
    font-size: 40px; }
  .title-vd p:after {
    height: 8px; }
  .play-btn {
    width: 35px;
    height: 40px; }
  .lg-prev, .lg-next {
    width: 100px; }
  .lg-prev:before {
    transform: translateX(-50px); }
  .lg-next:before {
    transform: translateX(50px); }
  .lg-close {
    width: 25px;
    height: 25px; } }

@media only screen and (max-width: 1280px) {
  .title-vd {
    bottom: -26px; }
  .title-vd p {
    font-size: 36px; } }

@media only screen and (max-width: 1280px) and (min-height: 890px) {
  .ctn-vd-cs {
    padding-top: 120px; }
  .preview-vd {
    width: 47.5%; }
  .preview-vd:last-child, .preview-vd:nth-child(2n+2) {
    margin-left: calc(5% - 5px); }
  .ctn-vd-cs {
    padding-bottom: 120px; }
  .lg-outer .lg-video-cont {
    width: 70% !important;
    max-width: 70% !important; } }

/* RESPONSIVE VIDEOS: TABLET */
@media only screen and (max-device-width: 1366px) {
  html.mobile-platform body[data-page=videos] {
    height: auto;
    overflow-y: auto; }
  html.mobile-platform .inner-vids-ctn:after,
  html.mobile-platform .inner-vids-ctn:before {
    display: none; }
  html.mobile-platform .inner-vids-ctn {
    padding: 116px 30px 0 30px;
    min-height: 100vh; }
  html.mobile-platform .dw-dr-vds {
    padding: 0; }
  html.mobile-platform .dw-dr-vds,
  html.mobile-platform .inner-vids-ctn {
    overflow: inherit; }
  html.mobile-platform .ctn-vd-cs {
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  html.mobile-platform .preview-vd,
  html.mobile-platform .preview-vd:last-child,
  html.mobile-platform .preview-vd:nth-child(2n+2) {
    width: 47.5%;
    margin: 0 0 94px 0; }
  html.mobile-platform .play-btn {
    width: 44px;
    height: 50px; }
  html.mobile-platform .title-vd {
    bottom: -34px;
    padding: 0 42px; }
  html.mobile-platform .title-vd p {
    font-size: 50px;
    letter-spacing: .15em;
    margin-left: -.075em; }
  html.mobile-platform .upload-videos,
  html.mobile-platform .title-videos-mp {
    display: block; }
  html.mobile-platform .upload-videos {
    text-align: center; }
  html.mobile-platform .upload-vs-btn {
    width: 440px;
    display: inline-block; }
  html.mobile-platform .lg-inner,
  html.mobile-platform .lg-prev,
  html.mobile-platform .lg-next,
  html.mobile-platform .lg-close {
    transform: none;
    transition: none; }
  html.mobile-platform .lg-outer .lg-video-cont {
    width: calc(100% - 120px) !important;
    max-width: calc(100% - 120px) !important; }
  html.mobile-platform .lg-close {
    top: 10px;
    right: 10px; }
  html.mobile-platform .lg-prev,
  html.mobile-platform .lg-next {
    width: 30px; }
  html.mobile-platform .lg-prev:before,
  html.mobile-platform .lg-next:before {
    transform: none; } }

@media only screen and (max-device-width: 1024px) {
  html.mobile-platform .play-btn {
    width: 40px;
    height: 46px; }
  html.mobile-platform .title-vd {
    bottom: -29px;
    padding: 0 32px; }
  html.mobile-platform .title-vd p {
    font-size: 40px; } }

@media only screen and (max-device-width: 800px) {
  html.mobile-platform .preview-vd,
  html.mobile-platform .preview-vd:last-child,
  html.mobile-platform .preview-vd:nth-child(2n+2) {
    width: 100%;
    margin: 0 0 94px 0; }
  html.mobile-platform .title-videos-mp {
    margin: 23px 0 -3vw 0; }
  html.mobile-platform .title-videos-mp h1 {
    font-size: 13vw;
    line-height: 13vw; }
  html.mobile-platform .play-btn {
    width: 44px;
    height: 50px; }
  html.mobile-platform .title-vd {
    bottom: -34px;
    padding: 0 42px; }
  html.mobile-platform .title-vd p {
    font-size: 50px;
    letter-spacing: .15em;
    margin-left: -.075em; }
  html.mobile-platform .upload-vs-btn {
    width: 100%;
    display: block;
    font-size: 24px; } }

@media only screen and (max-device-width: 640px) {
  html.mobile-platform .title-videos-mp {
    margin: 10px 0 -3vw 0; }
  html.mobile-platform .preview-vd,
  html.mobile-platform .preview-vd:last-child,
  html.mobile-platform .preview-vd:nth-child(2n+2) {
    margin: 0 0 74px 0; }
  html.mobile-platform .play-btn {
    width: 40px;
    height: 46px; }
  html.mobile-platform .title-vd {
    bottom: -29px;
    padding: 0 32px; }
  html.mobile-platform .title-vd p {
    font-size: 40px; }
  html.mobile-platform .upload-videos {
    padding: 13px 0 74px 0; }
  html.mobile-platform .upload-vs-btn {
    font-size: 21px;
    padding: 25px 0 23px 0; } }

/* RESPONSIVE VIDEOS: MOBILE */
@media only screen and (max-width: 740px) and (max-height: 425px) and (orientation: landscape) {
  html.mobile-platform .inner-vids-ctn {
    padding: 0 20px; }
  html.mobile-platform .ctn-vd-cs {
    padding-bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  html.mobile-platform .title-videos-mp {
    margin: 13px 0 -3vw 0; }
  html.mobile-platform .title-videos-mp h1 {
    font-size: 11vw;
    line-height: 11vw; }
  html.mobile-platform .preview-vd,
  html.mobile-platform .preview-vd:last-child,
  html.mobile-platform .preview-vd:nth-child(2n+2) {
    width: 47.5%;
    margin: 0 0 40px 0; }
  html.mobile-platform .play-btn {
    width: 20px;
    height: 23px; }
  html.mobile-platform .title-vd {
    bottom: -20px;
    padding: 0 12px; }
  html.mobile-platform .title-vd p {
    font-size: 26px; }
  html.mobile-platform .upload-videos {
    padding: 20px 0;
    text-align: center; }
  html.mobile-platform .upload-vs-btn {
    width: 270px;
    display: inline-block;
    font-size: 13px;
    padding: 16px 0 14px 0; }
  html.mobile-platform .lg-close {
    width: 20px;
    height: 20px; }
  html.mobile-platform body[data-page=videos] .lg-prev,
  html.mobile-platform body[data-page=videos] .lg-next {
    display: none; }
  html.mobile-platform .lg-prev,
  html.mobile-platform .lg-next {
    width: 20px; }
  html.mobile-platform .lg-outer .lg-video-cont {
    width: calc(100% - 80px) !important;
    max-width: calc(100% - 80px) !important; } }

@media only screen and (max-width: 425px) {
  html.mobile-platform .inner-vids-ctn {
    padding: 75px 20px 0 20px; }
  html.mobile-platform .title-videos-mp {
    margin: 13px 0 -3vw 0; }
  html.mobile-platform .preview-vd,
  html.mobile-platform .preview-vd:last-child,
  html.mobile-platform .preview-vd:nth-child(2n+2) {
    margin: 0 0 40px 0; }
  html.mobile-platform .play-btn {
    width: 20px;
    height: 23px; }
  html.mobile-platform .title-vd {
    bottom: -20px;
    padding: 0 12px; }
  html.mobile-platform .title-vd p {
    font-size: 26px; }
  html.mobile-platform .upload-videos {
    padding: 10px 0 40px 0; }
  html.mobile-platform .upload-vs-btn {
    font-size: 13px;
    padding: 16px 0 14px 0; }
  html.mobile-platform .lg-close {
    width: 20px;
    height: 20px; }
  html.mobile-platform body[data-page=videos] .lg-prev,
  html.mobile-platform body[data-page=videos] .lg-next {
    display: none; }
  html.mobile-platform .lg-prev,
  html.mobile-platform .lg-next {
    width: 20px; }
  html.mobile-platform .lg-outer .lg-video-cont {
    width: 100% !important;
    max-width: 100% !important; } }

@media only screen and (max-width: 320px) {
  html.mobile-platform .title-videos-mp h1 {
    font-size: 15vw;
    line-height: 15vw; } }

@media only screen and (max-width: 570px) and (max-height: 320px) and (orientation: landscape) {
  html.mobile-platform .title-vd {
    bottom: -18px; }
  html.mobile-platform .title-vd p {
    font-size: 21px; }
  html.mobile-platform .one-cns-lnk {
    width: 100%;
    padding-right: 0; }
  html.mobile-platform .one-cns-lnk:last-child {
    margin-bottom: 23px; } }

/*# sourceMappingURL=main.css.map */
