/* Base */

#awwwards{
	position: absolute;
	top: 150px;
	width: 90px;
	height: 135px;
	text-indent: -666em;
	overflow: hidden;
	z-index: 999;

	-webkit-transition: all 1s ease;
    transition: all 1s ease; 
}
#awwwards.top{
	top: 20px;
}
#awwwards.left{
	left: 0;
}
#awwwards.right{
	right: 0;
}

#awwwards a{
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 90px;
	height: 135px;
	background-repeat: no-repeat;
	background-size: 90px 135px;
}

/* SOTD */

#awwwards.sotd.black.left a{
	background-image: url(imgs/ribbons/awwwards_sotd_black_left.png);
}
#awwwards.sotd.black.right a{
	background-image: url(imgs/ribbons/awwwards_sotd_black_right.png);
}
#awwwards.sotd.silver.left a{
	background-image: url(imgs/ribbons/awwwards_sotd_silver_left.png);
}
#awwwards.sotd.silver.right a{
	background-image: url(imgs/ribbons/awwwards_sotd_silver_right.png);
}
#awwwards.sotd.white.left a{
	background-image: url(imgs/ribbons/awwwards_sotd_white_left.png);
}
#awwwards.sotd.white.right a{
	background-image: url(imgs/ribbons/awwwards_sotd_white_right.png);
}
#awwwards.sotd.green.left a{
	background-image: url(imgs/ribbons/awwwards_sotd_green_left.png);
}
#awwwards.sotd.green.right a{
	background-image: url(imgs/ribbons/awwwards_sotd_green_right.png);
}

@media only screen and (-Webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2),	only screen and (min-device-pixel-ratio: 1.5) {

	/* SOTD */

	#awwwards.sotd.black.left a{
		background-image: url(imgs/ribbons/awwwards_sotd_black_left@2x.png);
	}
	#awwwards.sotd.black.right a{
		background-image: url(imgs/ribbons/awwwards_sotd_black_right@2x.png);
	}
	#awwwards.sotd.silver.left a{
		background-image: url(imgs/ribbons/awwwards_sotd_silver_left@2x.png);
	}
	#awwwards.sotd.silver.right a{
		background-image: url(imgs/ribbons/awwwards_sotd_silver_right@2x.png);
	}
	#awwwards.sotd.white.left a{
		background-image: url(imgs/ribbons/awwwards_sotd_white_left@2x.png);
	}
	#awwwards.sotd.white.right a{
		background-image: url(imgs/ribbons/awwwards_sotd_white_right@2x.png);
	}
	#awwwards.sotd.green.left a{
		background-image: url(imgs/ribbons/awwwards_sotd_green_left@2x.png);
	}
	#awwwards.sotd.green.right a{
		background-image: url(imgs/ribbons/awwwards_sotd_green_right@2x.png);
	}

}
@media screen and (max-width: 1024px){
	#awwwards{ display: none;}
}